import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import * as ApolloCore from '@apollo/client/core';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date | number; output: Date; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type AggregateTask = {
  __typename?: 'AggregateTask';
  _count?: Maybe<TaskCountAggregate>;
  _max?: Maybe<TaskMaxAggregate>;
  _min?: Maybe<TaskMinAggregate>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BoolFilter>;
};

export type BytesFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<BytesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type EnumTaskAssigneeFilter = {
  equals?: InputMaybe<TaskAssignee>;
  in?: InputMaybe<Array<TaskAssignee>>;
  not?: InputMaybe<EnumTaskAssigneeFilter>;
  notIn?: InputMaybe<Array<TaskAssignee>>;
};

export type EnumTaskStatusFilter = {
  equals?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<TaskStatus>>;
  not?: InputMaybe<EnumTaskStatusFilter>;
  notIn?: InputMaybe<Array<TaskStatus>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type MessageCode = {
  __typename?: 'MessageCode';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  tenant: Tenant;
  tenantId: Scalars['String']['output'];
  token: Scalars['ID']['output'];
};

export type MessageCodeCreateManyTenantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MessageCodeCreateManyTenantInputEnvelope = {
  data: Array<MessageCodeCreateManyTenantInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MessageCodeCreateNestedManyWithoutTenantInput = {
  connect?: InputMaybe<Array<MessageCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCodeCreateOrConnectWithoutTenantInput>>;
  create?: InputMaybe<Array<MessageCodeCreateWithoutTenantInput>>;
  createMany?: InputMaybe<MessageCodeCreateManyTenantInputEnvelope>;
};

export type MessageCodeCreateOrConnectWithoutTenantInput = {
  create: MessageCodeCreateWithoutTenantInput;
  where: MessageCodeWhereUniqueInput;
};

export type MessageCodeCreateWithoutTenantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MessageCodeListRelationFilter = {
  every?: InputMaybe<MessageCodeWhereInput>;
  none?: InputMaybe<MessageCodeWhereInput>;
  some?: InputMaybe<MessageCodeWhereInput>;
};

export type MessageCodeScalarWhereInput = {
  AND?: InputMaybe<Array<MessageCodeScalarWhereInput>>;
  NOT?: InputMaybe<Array<MessageCodeScalarWhereInput>>;
  OR?: InputMaybe<Array<MessageCodeScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  tenantId?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
};

export type MessageCodeUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MessageCodeUpdateManyWithWhereWithoutTenantInput = {
  data: MessageCodeUpdateManyMutationInput;
  where: MessageCodeScalarWhereInput;
};

export type MessageCodeUpdateManyWithoutTenantNestedInput = {
  connect?: InputMaybe<Array<MessageCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCodeCreateOrConnectWithoutTenantInput>>;
  create?: InputMaybe<Array<MessageCodeCreateWithoutTenantInput>>;
  createMany?: InputMaybe<MessageCodeCreateManyTenantInputEnvelope>;
  delete?: InputMaybe<Array<MessageCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MessageCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MessageCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<MessageCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<MessageCodeUpdateWithWhereUniqueWithoutTenantInput>>;
  updateMany?: InputMaybe<Array<MessageCodeUpdateManyWithWhereWithoutTenantInput>>;
  upsert?: InputMaybe<Array<MessageCodeUpsertWithWhereUniqueWithoutTenantInput>>;
};

export type MessageCodeUpdateWithWhereUniqueWithoutTenantInput = {
  data: MessageCodeUpdateWithoutTenantInput;
  where: MessageCodeWhereUniqueInput;
};

export type MessageCodeUpdateWithoutTenantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MessageCodeUpsertWithWhereUniqueWithoutTenantInput = {
  create: MessageCodeCreateWithoutTenantInput;
  update: MessageCodeUpdateWithoutTenantInput;
  where: MessageCodeWhereUniqueInput;
};

export type MessageCodeWhereInput = {
  AND?: InputMaybe<Array<MessageCodeWhereInput>>;
  NOT?: InputMaybe<Array<MessageCodeWhereInput>>;
  OR?: InputMaybe<Array<MessageCodeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  tenant?: InputMaybe<TenantRelationFilter>;
  tenantId?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
};

export type MessageCodeWhereUniqueInput = {
  AND?: InputMaybe<Array<MessageCodeWhereInput>>;
  NOT?: InputMaybe<Array<MessageCodeWhereInput>>;
  OR?: InputMaybe<Array<MessageCodeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  tenant?: InputMaybe<TenantRelationFilter>;
  tenantId?: InputMaybe<StringFilter>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addPropertyUnitTenant: PropertyUnit;
  createOrganization: Organization;
  createProperty: Property;
  createPropertyUnit: PropertyUnit;
  createTaskSchedule: TaskSchedule;
  createTasks: Array<Task>;
  deleteProperty: Property;
  deleteTask: Task;
  deleteTaskSchedule: TaskSchedule;
  updateOrganization: Organization;
  updateProfile: User;
  updateProperty: Property;
  updatePropertyUnit: PropertyUnit;
  updateTask: Task;
  updateTaskSchedule: TaskSchedule;
};


export type MutationAddPropertyUnitTenantArgs = {
  data: TenantCreateInput;
};


export type MutationCreateOrganizationArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreatePropertyArgs = {
  data: PropertyCreateInput;
};


export type MutationCreatePropertyUnitArgs = {
  data: PropertyUnitCreateInput;
};


export type MutationCreateTaskScheduleArgs = {
  data: TaskScheduleCreateInput;
};


export type MutationCreateTasksArgs = {
  data: Array<TaskCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationDeletePropertyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTaskArgs = {
  where: TaskWhereUniqueInput;
};


export type MutationDeleteTaskScheduleArgs = {
  where: TaskScheduleWhereUniqueInput;
};


export type MutationUpdateOrganizationArgs = {
  data: OrganizationUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateProfileArgs = {
  name: Scalars['String']['input'];
};


export type MutationUpdatePropertyArgs = {
  data: PropertyUpdateInput;
  where: PropertyWhereUniqueInput;
};


export type MutationUpdatePropertyUnitArgs = {
  data: PropertyUnitUpdateInput;
  where: PropertyUnitWhereUniqueInput;
};


export type MutationUpdateTaskArgs = {
  data: TaskUpdateInput;
  where: TaskWhereUniqueInput;
};


export type MutationUpdateTaskScheduleArgs = {
  data: TaskScheduleUpdateInput;
  where: TaskScheduleWhereUniqueInput;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Organization = {
  __typename?: 'Organization';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  members?: Maybe<Array<UsersInOrganizations>>;
  name: Scalars['String']['output'];
  properties?: Maybe<Array<Property>>;
  taskSchedules?: Maybe<Array<TaskSchedule>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationCountAggregate = {
  __typename?: 'OrganizationCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type OrganizationCreateNestedOneWithoutMembersInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutMembersInput>;
  create?: InputMaybe<OrganizationCreateWithoutMembersInput>;
};

export type OrganizationCreateNestedOneWithoutPropertiesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutPropertiesInput>;
  create?: InputMaybe<OrganizationCreateWithoutPropertiesInput>;
};

export type OrganizationCreateNestedOneWithoutTaskSchedulesInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTaskSchedulesInput>;
  create?: InputMaybe<OrganizationCreateWithoutTaskSchedulesInput>;
};

export type OrganizationCreateOrConnectWithoutMembersInput = {
  create: OrganizationCreateWithoutMembersInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutPropertiesInput = {
  create: OrganizationCreateWithoutPropertiesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateOrConnectWithoutTaskSchedulesInput = {
  create: OrganizationCreateWithoutTaskSchedulesInput;
  where: OrganizationWhereUniqueInput;
};

export type OrganizationCreateWithoutMembersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  properties?: InputMaybe<PropertyCreateNestedManyWithoutOrganizationInput>;
  taskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationCreateWithoutPropertiesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<UsersInOrganizationsCreateNestedManyWithoutOrganizationInput>;
  name: Scalars['String']['input'];
  taskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationCreateWithoutTaskSchedulesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<UsersInOrganizationsCreateNestedManyWithoutOrganizationInput>;
  name: Scalars['String']['input'];
  properties?: InputMaybe<PropertyCreateNestedManyWithoutOrganizationInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationMaxAggregate = {
  __typename?: 'OrganizationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrganizationMinAggregate = {
  __typename?: 'OrganizationMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrganizationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  members?: InputMaybe<UsersInOrganizationsOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  properties?: InputMaybe<PropertyOrderByRelationAggregateInput>;
  taskSchedules?: InputMaybe<TaskScheduleOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrganizationRelationFilter = {
  is?: InputMaybe<OrganizationWhereInput>;
  isNot?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<UsersInOrganizationsUpdateManyWithoutOrganizationNestedInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<PropertyUpdateManyWithoutOrganizationNestedInput>;
  taskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutOrganizationNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationUpdateOneRequiredWithoutMembersNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutMembersInput>;
  create?: InputMaybe<OrganizationCreateWithoutMembersInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutMembersInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutMembersInput>;
};

export type OrganizationUpdateOneRequiredWithoutPropertiesNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutPropertiesInput>;
  create?: InputMaybe<OrganizationCreateWithoutPropertiesInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutPropertiesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutPropertiesInput>;
};

export type OrganizationUpdateOneRequiredWithoutTaskSchedulesNestedInput = {
  connect?: InputMaybe<OrganizationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrganizationCreateOrConnectWithoutTaskSchedulesInput>;
  create?: InputMaybe<OrganizationCreateWithoutTaskSchedulesInput>;
  update?: InputMaybe<OrganizationUpdateToOneWithWhereWithoutTaskSchedulesInput>;
  upsert?: InputMaybe<OrganizationUpsertWithoutTaskSchedulesInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutMembersInput = {
  data: OrganizationUpdateWithoutMembersInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutPropertiesInput = {
  data: OrganizationUpdateWithoutPropertiesInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateToOneWithWhereWithoutTaskSchedulesInput = {
  data: OrganizationUpdateWithoutTaskSchedulesInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpdateWithoutMembersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<PropertyUpdateManyWithoutOrganizationNestedInput>;
  taskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutOrganizationNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationUpdateWithoutPropertiesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<UsersInOrganizationsUpdateManyWithoutOrganizationNestedInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  taskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutOrganizationNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationUpdateWithoutTaskSchedulesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<UsersInOrganizationsUpdateManyWithoutOrganizationNestedInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  properties?: InputMaybe<PropertyUpdateManyWithoutOrganizationNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrganizationUpsertWithoutMembersInput = {
  create: OrganizationCreateWithoutMembersInput;
  update: OrganizationUpdateWithoutMembersInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpsertWithoutPropertiesInput = {
  create: OrganizationCreateWithoutPropertiesInput;
  update: OrganizationUpdateWithoutPropertiesInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationUpsertWithoutTaskSchedulesInput = {
  create: OrganizationCreateWithoutTaskSchedulesInput;
  update: OrganizationUpdateWithoutTaskSchedulesInput;
  where?: InputMaybe<OrganizationWhereInput>;
};

export type OrganizationWhereInput = {
  AND?: InputMaybe<Array<OrganizationWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationWhereInput>>;
  OR?: InputMaybe<Array<OrganizationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  members?: InputMaybe<UsersInOrganizationsListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  properties?: InputMaybe<PropertyListRelationFilter>;
  taskSchedules?: InputMaybe<TaskScheduleListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrganizationWhereUniqueInput = {
  AND?: InputMaybe<Array<OrganizationWhereInput>>;
  NOT?: InputMaybe<Array<OrganizationWhereInput>>;
  OR?: InputMaybe<Array<OrganizationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<UsersInOrganizationsListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  properties?: InputMaybe<PropertyListRelationFilter>;
  taskSchedules?: InputMaybe<TaskScheduleListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Property = {
  __typename?: 'Property';
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  organization: Organization;
  organizationId: Scalars['String']['output'];
  units?: Maybe<Array<PropertyUnit>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PropertyCountAggregate = {
  __typename?: 'PropertyCountAggregate';
  _all: Scalars['Int']['output'];
  address: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  organizationId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PropertyCreateInput = {
  address: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutPropertiesInput;
  units?: InputMaybe<PropertyUnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyCreateManyOrganizationInput = {
  address: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyCreateManyOrganizationInputEnvelope = {
  data: Array<PropertyCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PropertyCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<PropertyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PropertyCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<PropertyCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<PropertyCreateManyOrganizationInputEnvelope>;
};

export type PropertyCreateNestedOneWithoutUnitsInput = {
  connect?: InputMaybe<PropertyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PropertyCreateOrConnectWithoutUnitsInput>;
  create?: InputMaybe<PropertyCreateWithoutUnitsInput>;
};

export type PropertyCreateOrConnectWithoutOrganizationInput = {
  create: PropertyCreateWithoutOrganizationInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateOrConnectWithoutUnitsInput = {
  create: PropertyCreateWithoutUnitsInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyCreateWithoutOrganizationInput = {
  address: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<PropertyUnitCreateNestedManyWithoutPropertyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyCreateWithoutUnitsInput = {
  address: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutPropertiesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyListRelationFilter = {
  every?: InputMaybe<PropertyWhereInput>;
  none?: InputMaybe<PropertyWhereInput>;
  some?: InputMaybe<PropertyWhereInput>;
};

export type PropertyMaxAggregate = {
  __typename?: 'PropertyMaxAggregate';
  address?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PropertyMinAggregate = {
  __typename?: 'PropertyMinAggregate';
  address?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PropertyOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PropertyOrderByWithRelationInput = {
  address?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrder>;
  units?: InputMaybe<PropertyUnitOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PropertyRelationFilter = {
  is?: InputMaybe<PropertyWhereInput>;
  isNot?: InputMaybe<PropertyWhereInput>;
};

export type PropertyScalarWhereInput = {
  AND?: InputMaybe<Array<PropertyScalarWhereInput>>;
  NOT?: InputMaybe<Array<PropertyScalarWhereInput>>;
  OR?: InputMaybe<Array<PropertyScalarWhereInput>>;
  address?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PropertyUnit = {
  __typename?: 'PropertyUnit';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  property: Property;
  propertyId: Scalars['String']['output'];
  taskSchedules?: Maybe<Array<TaskSchedule>>;
  tasks?: Maybe<Array<Task>>;
  tenants?: Maybe<Array<Tenant>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PropertyUnitCountAggregate = {
  __typename?: 'PropertyUnitCountAggregate';
  _all: Scalars['Int']['output'];
  active: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  propertyId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PropertyUnitCreateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  property: PropertyCreateNestedOneWithoutUnitsInput;
  taskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutUnitsInput>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutUnitInput>;
  tenants?: InputMaybe<TenantCreateNestedManyWithoutPropertyUnitInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUnitCreateManyPropertyInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUnitCreateManyPropertyInputEnvelope = {
  data: Array<PropertyUnitCreateManyPropertyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PropertyUnitCreateNestedManyWithoutPropertyInput = {
  connect?: InputMaybe<Array<PropertyUnitWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PropertyUnitCreateOrConnectWithoutPropertyInput>>;
  create?: InputMaybe<Array<PropertyUnitCreateWithoutPropertyInput>>;
  createMany?: InputMaybe<PropertyUnitCreateManyPropertyInputEnvelope>;
};

export type PropertyUnitCreateNestedManyWithoutTaskSchedulesInput = {
  connect?: InputMaybe<Array<PropertyUnitWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PropertyUnitCreateOrConnectWithoutTaskSchedulesInput>>;
  create?: InputMaybe<Array<PropertyUnitCreateWithoutTaskSchedulesInput>>;
};

export type PropertyUnitCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<PropertyUnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PropertyUnitCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<PropertyUnitCreateWithoutTasksInput>;
};

export type PropertyUnitCreateNestedOneWithoutTenantsInput = {
  connect?: InputMaybe<PropertyUnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PropertyUnitCreateOrConnectWithoutTenantsInput>;
  create?: InputMaybe<PropertyUnitCreateWithoutTenantsInput>;
};

export type PropertyUnitCreateOrConnectWithoutPropertyInput = {
  create: PropertyUnitCreateWithoutPropertyInput;
  where: PropertyUnitWhereUniqueInput;
};

export type PropertyUnitCreateOrConnectWithoutTaskSchedulesInput = {
  create: PropertyUnitCreateWithoutTaskSchedulesInput;
  where: PropertyUnitWhereUniqueInput;
};

export type PropertyUnitCreateOrConnectWithoutTasksInput = {
  create: PropertyUnitCreateWithoutTasksInput;
  where: PropertyUnitWhereUniqueInput;
};

export type PropertyUnitCreateOrConnectWithoutTenantsInput = {
  create: PropertyUnitCreateWithoutTenantsInput;
  where: PropertyUnitWhereUniqueInput;
};

export type PropertyUnitCreateWithoutPropertyInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  taskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutUnitsInput>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutUnitInput>;
  tenants?: InputMaybe<TenantCreateNestedManyWithoutPropertyUnitInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUnitCreateWithoutTaskSchedulesInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  property: PropertyCreateNestedOneWithoutUnitsInput;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutUnitInput>;
  tenants?: InputMaybe<TenantCreateNestedManyWithoutPropertyUnitInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUnitCreateWithoutTasksInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  property: PropertyCreateNestedOneWithoutUnitsInput;
  taskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutUnitsInput>;
  tenants?: InputMaybe<TenantCreateNestedManyWithoutPropertyUnitInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUnitCreateWithoutTenantsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  property: PropertyCreateNestedOneWithoutUnitsInput;
  taskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutUnitsInput>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutUnitInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUnitListRelationFilter = {
  every?: InputMaybe<PropertyUnitWhereInput>;
  none?: InputMaybe<PropertyUnitWhereInput>;
  some?: InputMaybe<PropertyUnitWhereInput>;
};

export type PropertyUnitMaxAggregate = {
  __typename?: 'PropertyUnitMaxAggregate';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  propertyId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PropertyUnitMinAggregate = {
  __typename?: 'PropertyUnitMinAggregate';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  propertyId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PropertyUnitOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PropertyUnitOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  property?: InputMaybe<PropertyOrderByWithRelationInput>;
  propertyId?: InputMaybe<SortOrder>;
  taskSchedules?: InputMaybe<TaskScheduleOrderByRelationAggregateInput>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  tenants?: InputMaybe<TenantOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PropertyUnitRelationFilter = {
  is?: InputMaybe<PropertyUnitWhereInput>;
  isNot?: InputMaybe<PropertyUnitWhereInput>;
};

export enum PropertyUnitScalarFieldEnum {
  Active = 'active',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  PropertyId = 'propertyId',
  UpdatedAt = 'updatedAt'
}

export type PropertyUnitScalarWhereInput = {
  AND?: InputMaybe<Array<PropertyUnitScalarWhereInput>>;
  NOT?: InputMaybe<Array<PropertyUnitScalarWhereInput>>;
  OR?: InputMaybe<Array<PropertyUnitScalarWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  propertyId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PropertyUnitUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<PropertyUpdateOneRequiredWithoutUnitsNestedInput>;
  taskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutUnitsNestedInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutUnitNestedInput>;
  tenants?: InputMaybe<TenantUpdateManyWithoutPropertyUnitNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUnitUpdateManyMutationInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUnitUpdateManyWithWhereWithoutPropertyInput = {
  data: PropertyUnitUpdateManyMutationInput;
  where: PropertyUnitScalarWhereInput;
};

export type PropertyUnitUpdateManyWithWhereWithoutTaskSchedulesInput = {
  data: PropertyUnitUpdateManyMutationInput;
  where: PropertyUnitScalarWhereInput;
};

export type PropertyUnitUpdateManyWithoutPropertyNestedInput = {
  connect?: InputMaybe<Array<PropertyUnitWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PropertyUnitCreateOrConnectWithoutPropertyInput>>;
  create?: InputMaybe<Array<PropertyUnitCreateWithoutPropertyInput>>;
  createMany?: InputMaybe<PropertyUnitCreateManyPropertyInputEnvelope>;
  delete?: InputMaybe<Array<PropertyUnitWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PropertyUnitScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PropertyUnitWhereUniqueInput>>;
  set?: InputMaybe<Array<PropertyUnitWhereUniqueInput>>;
  update?: InputMaybe<Array<PropertyUnitUpdateWithWhereUniqueWithoutPropertyInput>>;
  updateMany?: InputMaybe<Array<PropertyUnitUpdateManyWithWhereWithoutPropertyInput>>;
  upsert?: InputMaybe<Array<PropertyUnitUpsertWithWhereUniqueWithoutPropertyInput>>;
};

export type PropertyUnitUpdateManyWithoutTaskSchedulesNestedInput = {
  connect?: InputMaybe<Array<PropertyUnitWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PropertyUnitCreateOrConnectWithoutTaskSchedulesInput>>;
  create?: InputMaybe<Array<PropertyUnitCreateWithoutTaskSchedulesInput>>;
  delete?: InputMaybe<Array<PropertyUnitWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PropertyUnitScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PropertyUnitWhereUniqueInput>>;
  set?: InputMaybe<Array<PropertyUnitWhereUniqueInput>>;
  update?: InputMaybe<Array<PropertyUnitUpdateWithWhereUniqueWithoutTaskSchedulesInput>>;
  updateMany?: InputMaybe<Array<PropertyUnitUpdateManyWithWhereWithoutTaskSchedulesInput>>;
  upsert?: InputMaybe<Array<PropertyUnitUpsertWithWhereUniqueWithoutTaskSchedulesInput>>;
};

export type PropertyUnitUpdateOneRequiredWithoutTasksNestedInput = {
  connect?: InputMaybe<PropertyUnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PropertyUnitCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<PropertyUnitCreateWithoutTasksInput>;
  update?: InputMaybe<PropertyUnitUpdateToOneWithWhereWithoutTasksInput>;
  upsert?: InputMaybe<PropertyUnitUpsertWithoutTasksInput>;
};

export type PropertyUnitUpdateToOneWithWhereWithoutTasksInput = {
  data: PropertyUnitUpdateWithoutTasksInput;
  where?: InputMaybe<PropertyUnitWhereInput>;
};

export type PropertyUnitUpdateWithWhereUniqueWithoutPropertyInput = {
  data: PropertyUnitUpdateWithoutPropertyInput;
  where: PropertyUnitWhereUniqueInput;
};

export type PropertyUnitUpdateWithWhereUniqueWithoutTaskSchedulesInput = {
  data: PropertyUnitUpdateWithoutTaskSchedulesInput;
  where: PropertyUnitWhereUniqueInput;
};

export type PropertyUnitUpdateWithoutPropertyInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  taskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutUnitsNestedInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutUnitNestedInput>;
  tenants?: InputMaybe<TenantUpdateManyWithoutPropertyUnitNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUnitUpdateWithoutTaskSchedulesInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<PropertyUpdateOneRequiredWithoutUnitsNestedInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutUnitNestedInput>;
  tenants?: InputMaybe<TenantUpdateManyWithoutPropertyUnitNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUnitUpdateWithoutTasksInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  property?: InputMaybe<PropertyUpdateOneRequiredWithoutUnitsNestedInput>;
  taskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutUnitsNestedInput>;
  tenants?: InputMaybe<TenantUpdateManyWithoutPropertyUnitNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUnitUpsertWithWhereUniqueWithoutPropertyInput = {
  create: PropertyUnitCreateWithoutPropertyInput;
  update: PropertyUnitUpdateWithoutPropertyInput;
  where: PropertyUnitWhereUniqueInput;
};

export type PropertyUnitUpsertWithWhereUniqueWithoutTaskSchedulesInput = {
  create: PropertyUnitCreateWithoutTaskSchedulesInput;
  update: PropertyUnitUpdateWithoutTaskSchedulesInput;
  where: PropertyUnitWhereUniqueInput;
};

export type PropertyUnitUpsertWithoutTasksInput = {
  create: PropertyUnitCreateWithoutTasksInput;
  update: PropertyUnitUpdateWithoutTasksInput;
  where?: InputMaybe<PropertyUnitWhereInput>;
};

export type PropertyUnitWhereInput = {
  AND?: InputMaybe<Array<PropertyUnitWhereInput>>;
  NOT?: InputMaybe<Array<PropertyUnitWhereInput>>;
  OR?: InputMaybe<Array<PropertyUnitWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  property?: InputMaybe<PropertyRelationFilter>;
  propertyId?: InputMaybe<StringFilter>;
  taskSchedules?: InputMaybe<TaskScheduleListRelationFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  tenants?: InputMaybe<TenantListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PropertyUnitWhereUniqueInput = {
  AND?: InputMaybe<Array<PropertyUnitWhereInput>>;
  NOT?: InputMaybe<Array<PropertyUnitWhereInput>>;
  OR?: InputMaybe<Array<PropertyUnitWhereInput>>;
  active?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  property?: InputMaybe<PropertyRelationFilter>;
  propertyId?: InputMaybe<StringFilter>;
  taskSchedules?: InputMaybe<TaskScheduleListRelationFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  tenants?: InputMaybe<TenantListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PropertyUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutPropertiesNestedInput>;
  units?: InputMaybe<PropertyUnitUpdateManyWithoutPropertyNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUpdateManyMutationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUpdateManyWithWhereWithoutOrganizationInput = {
  data: PropertyUpdateManyMutationInput;
  where: PropertyScalarWhereInput;
};

export type PropertyUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<PropertyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PropertyCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<PropertyCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<PropertyCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<PropertyWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PropertyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PropertyWhereUniqueInput>>;
  set?: InputMaybe<Array<PropertyWhereUniqueInput>>;
  update?: InputMaybe<Array<PropertyUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<PropertyUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<PropertyUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type PropertyUpdateOneRequiredWithoutUnitsNestedInput = {
  connect?: InputMaybe<PropertyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PropertyCreateOrConnectWithoutUnitsInput>;
  create?: InputMaybe<PropertyCreateWithoutUnitsInput>;
  update?: InputMaybe<PropertyUpdateToOneWithWhereWithoutUnitsInput>;
  upsert?: InputMaybe<PropertyUpsertWithoutUnitsInput>;
};

export type PropertyUpdateToOneWithWhereWithoutUnitsInput = {
  data: PropertyUpdateWithoutUnitsInput;
  where?: InputMaybe<PropertyWhereInput>;
};

export type PropertyUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: PropertyUpdateWithoutOrganizationInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyUpdateWithoutOrganizationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<PropertyUnitUpdateManyWithoutPropertyNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUpdateWithoutUnitsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutPropertiesNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PropertyUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: PropertyCreateWithoutOrganizationInput;
  update: PropertyUpdateWithoutOrganizationInput;
  where: PropertyWhereUniqueInput;
};

export type PropertyUpsertWithoutUnitsInput = {
  create: PropertyCreateWithoutUnitsInput;
  update: PropertyUpdateWithoutUnitsInput;
  where?: InputMaybe<PropertyWhereInput>;
};

export type PropertyWhereInput = {
  AND?: InputMaybe<Array<PropertyWhereInput>>;
  NOT?: InputMaybe<Array<PropertyWhereInput>>;
  OR?: InputMaybe<Array<PropertyWhereInput>>;
  address?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  units?: InputMaybe<PropertyUnitListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PropertyWhereUniqueInput = {
  AND?: InputMaybe<Array<PropertyWhereInput>>;
  NOT?: InputMaybe<Array<PropertyWhereInput>>;
  OR?: InputMaybe<Array<PropertyWhereInput>>;
  address?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  units?: InputMaybe<PropertyUnitListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Query = {
  __typename?: 'Query';
  aggregateTasks: AggregateTask;
  findManyTaskSchedules: Array<TaskSchedule>;
  findManyTasks: Array<Task>;
  findUniqueTaskSchedule: TaskSchedule;
  getProfile: User;
  getPropertyById: Property;
  getPropertyUnitById: PropertyUnit;
  getTaskById: Task;
  listOrganizations: Array<Organization>;
  listProperties: Array<Property>;
  listPropertyUnit: Array<PropertyUnit>;
};


export type QueryAggregateTasksArgs = {
  _count?: InputMaybe<TaskCountAggregateInput>;
  _max?: InputMaybe<TaskMaxAggregateInput>;
  _min?: InputMaybe<TaskMinAggregateInput>;
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};


export type QueryFindManyTaskSchedulesArgs = {
  cursor?: InputMaybe<TaskScheduleWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScheduleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskScheduleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskScheduleWhereInput>;
};


export type QueryFindManyTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TaskWhereInput>;
};


export type QueryFindUniqueTaskScheduleArgs = {
  where: TaskScheduleWhereUniqueInput;
};


export type QueryGetPropertyByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPropertyUnitByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetTaskByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryListPropertyUnitArgs = {
  cursor?: InputMaybe<PropertyUnitWhereUniqueInput>;
  distinct?: InputMaybe<Array<PropertyUnitScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PropertyUnitOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PropertyUnitWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<StringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Task = {
  __typename?: 'Task';
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approver?: Maybe<User>;
  approverId?: Maybe<Scalars['String']['output']>;
  assignee: TaskAssignee;
  categories?: Maybe<Array<Scalars['String']['output']>>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdById: Scalars['String']['output'];
  createdByTaskSchedule?: Maybe<TaskSchedule>;
  createdByTaskScheduleId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  details?: Maybe<Scalars['JSON']['output']>;
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  evidence?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  resources?: Maybe<Scalars['JSON']['output']>;
  status: TaskStatus;
  title: Scalars['String']['output'];
  unit: PropertyUnit;
  unitId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum TaskAssignee {
  Manager = 'Manager',
  Owner = 'Owner',
  Tenants = 'Tenants'
}

export type TaskCountAggregate = {
  __typename?: 'TaskCountAggregate';
  _all: Scalars['Int']['output'];
  approvedAt: Scalars['Int']['output'];
  approverId: Scalars['Int']['output'];
  assignee: Scalars['Int']['output'];
  categories: Scalars['Int']['output'];
  completedAt: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  createdById: Scalars['Int']['output'];
  createdByTaskScheduleId: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  details: Scalars['Int']['output'];
  dueAt: Scalars['Int']['output'];
  evidence: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  resources: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  unitId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TaskCountAggregateInput = {
  _all?: InputMaybe<Scalars['Boolean']['input']>;
  approvedAt?: InputMaybe<Scalars['Boolean']['input']>;
  approverId?: InputMaybe<Scalars['Boolean']['input']>;
  assignee?: InputMaybe<Scalars['Boolean']['input']>;
  categories?: InputMaybe<Scalars['Boolean']['input']>;
  completedAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdById?: InputMaybe<Scalars['Boolean']['input']>;
  createdByTaskScheduleId?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['Boolean']['input']>;
  details?: InputMaybe<Scalars['Boolean']['input']>;
  dueAt?: InputMaybe<Scalars['Boolean']['input']>;
  evidence?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  resources?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['Boolean']['input']>;
  unitId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskCreateManyApproverInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById: Scalars['String']['input'];
  createdByTaskScheduleId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskCreateManyApproverInputEnvelope = {
  data: Array<TaskCreateManyApproverInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskCreateManyCreatedByInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approverId?: InputMaybe<Scalars['String']['input']>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdByTaskScheduleId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskCreateManyCreatedByInputEnvelope = {
  data: Array<TaskCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskCreateManyCreatedByTaskScheduleInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approverId?: InputMaybe<Scalars['String']['input']>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById: Scalars['String']['input'];
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskCreateManyCreatedByTaskScheduleInputEnvelope = {
  data: Array<TaskCreateManyCreatedByTaskScheduleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskCreateManyInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approverId?: InputMaybe<Scalars['String']['input']>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById: Scalars['String']['input'];
  createdByTaskScheduleId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title: Scalars['String']['input'];
  unitId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskCreateManyUnitInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approverId?: InputMaybe<Scalars['String']['input']>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById: Scalars['String']['input'];
  createdByTaskScheduleId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskCreateManyUnitInputEnvelope = {
  data: Array<TaskCreateManyUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskCreateNestedManyWithoutApproverInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutApproverInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutApproverInput>>;
  createMany?: InputMaybe<TaskCreateManyApproverInputEnvelope>;
};

export type TaskCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<TaskCreateManyCreatedByInputEnvelope>;
};

export type TaskCreateNestedManyWithoutCreatedByTaskScheduleInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutCreatedByTaskScheduleInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutCreatedByTaskScheduleInput>>;
  createMany?: InputMaybe<TaskCreateManyCreatedByTaskScheduleInputEnvelope>;
};

export type TaskCreateNestedManyWithoutUnitInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutUnitInput>>;
  createMany?: InputMaybe<TaskCreateManyUnitInputEnvelope>;
};

export type TaskCreateOrConnectWithoutApproverInput = {
  create: TaskCreateWithoutApproverInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutCreatedByInput = {
  create: TaskCreateWithoutCreatedByInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutCreatedByTaskScheduleInput = {
  create: TaskCreateWithoutCreatedByTaskScheduleInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutUnitInput = {
  create: TaskCreateWithoutUnitInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateWithoutApproverInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutCreatedTasksInput;
  createdByTaskSchedule?: InputMaybe<TaskScheduleCreateNestedOneWithoutTasksInput>;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title: Scalars['String']['input'];
  unit: PropertyUnitCreateNestedOneWithoutTasksInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskCreateWithoutCreatedByInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approver?: InputMaybe<UserCreateNestedOneWithoutApproverForTasksInput>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdByTaskSchedule?: InputMaybe<TaskScheduleCreateNestedOneWithoutTasksInput>;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title: Scalars['String']['input'];
  unit: PropertyUnitCreateNestedOneWithoutTasksInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskCreateWithoutCreatedByTaskScheduleInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approver?: InputMaybe<UserCreateNestedOneWithoutApproverForTasksInput>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutCreatedTasksInput;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title: Scalars['String']['input'];
  unit: PropertyUnitCreateNestedOneWithoutTasksInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskCreateWithoutUnitInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approver?: InputMaybe<UserCreateNestedOneWithoutApproverForTasksInput>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutCreatedTasksInput;
  createdByTaskSchedule?: InputMaybe<TaskScheduleCreateNestedOneWithoutTasksInput>;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskListRelationFilter = {
  every?: InputMaybe<TaskWhereInput>;
  none?: InputMaybe<TaskWhereInput>;
  some?: InputMaybe<TaskWhereInput>;
};

export type TaskMaxAggregate = {
  __typename?: 'TaskMaxAggregate';
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approverId?: Maybe<Scalars['String']['output']>;
  assignee?: Maybe<TaskAssignee>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['String']['output']>;
  createdByTaskScheduleId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  evidence?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TaskStatus>;
  title?: Maybe<Scalars['String']['output']>;
  unitId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TaskMaxAggregateInput = {
  approvedAt?: InputMaybe<Scalars['Boolean']['input']>;
  approverId?: InputMaybe<Scalars['Boolean']['input']>;
  assignee?: InputMaybe<Scalars['Boolean']['input']>;
  completedAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdById?: InputMaybe<Scalars['Boolean']['input']>;
  createdByTaskScheduleId?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['Boolean']['input']>;
  dueAt?: InputMaybe<Scalars['Boolean']['input']>;
  evidence?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['Boolean']['input']>;
  unitId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskMinAggregate = {
  __typename?: 'TaskMinAggregate';
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approverId?: Maybe<Scalars['String']['output']>;
  assignee?: Maybe<TaskAssignee>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['String']['output']>;
  createdByTaskScheduleId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  evidence?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TaskStatus>;
  title?: Maybe<Scalars['String']['output']>;
  unitId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TaskMinAggregateInput = {
  approvedAt?: InputMaybe<Scalars['Boolean']['input']>;
  approverId?: InputMaybe<Scalars['Boolean']['input']>;
  assignee?: InputMaybe<Scalars['Boolean']['input']>;
  completedAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['Boolean']['input']>;
  createdById?: InputMaybe<Scalars['Boolean']['input']>;
  createdByTaskScheduleId?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['Boolean']['input']>;
  dueAt?: InputMaybe<Scalars['Boolean']['input']>;
  evidence?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['Boolean']['input']>;
  unitId?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TaskOrderByWithRelationInput = {
  approvedAt?: InputMaybe<SortOrderInput>;
  approver?: InputMaybe<UserOrderByWithRelationInput>;
  approverId?: InputMaybe<SortOrderInput>;
  assignee?: InputMaybe<SortOrder>;
  categories?: InputMaybe<SortOrder>;
  completedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByWithRelationInput>;
  createdById?: InputMaybe<SortOrder>;
  createdByTaskSchedule?: InputMaybe<TaskScheduleOrderByWithRelationInput>;
  createdByTaskScheduleId?: InputMaybe<SortOrderInput>;
  description?: InputMaybe<SortOrder>;
  details?: InputMaybe<SortOrderInput>;
  dueAt?: InputMaybe<SortOrderInput>;
  evidence?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  resources?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  unit?: InputMaybe<PropertyUnitOrderByWithRelationInput>;
  unitId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum TaskScalarFieldEnum {
  ApprovedAt = 'approvedAt',
  ApproverId = 'approverId',
  Assignee = 'assignee',
  Categories = 'categories',
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  CreatedByTaskScheduleId = 'createdByTaskScheduleId',
  Description = 'description',
  Details = 'details',
  DueAt = 'dueAt',
  Evidence = 'evidence',
  Id = 'id',
  Resources = 'resources',
  Status = 'status',
  Title = 'title',
  UnitId = 'unitId',
  UpdatedAt = 'updatedAt'
}

export type TaskScalarWhereInput = {
  AND?: InputMaybe<Array<TaskScalarWhereInput>>;
  NOT?: InputMaybe<Array<TaskScalarWhereInput>>;
  OR?: InputMaybe<Array<TaskScalarWhereInput>>;
  approvedAt?: InputMaybe<DateTimeFilter>;
  approverId?: InputMaybe<StringFilter>;
  assignee?: InputMaybe<EnumTaskAssigneeFilter>;
  categories?: InputMaybe<StringListFilter>;
  completedAt?: InputMaybe<DateTimeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringFilter>;
  createdByTaskScheduleId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  details?: InputMaybe<JsonFilter>;
  dueAt?: InputMaybe<DateTimeFilter>;
  evidence?: InputMaybe<BytesFilter>;
  id?: InputMaybe<StringFilter>;
  resources?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumTaskStatusFilter>;
  title?: InputMaybe<StringFilter>;
  unitId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TaskSchedule = {
  __typename?: 'TaskSchedule';
  approver?: Maybe<User>;
  approverId?: Maybe<Scalars['String']['output']>;
  assignee: TaskAssignee;
  categories?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdById: Scalars['String']['output'];
  description: Scalars['String']['output'];
  details?: Maybe<Scalars['JSON']['output']>;
  dueIn?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  organization: Organization;
  organizationId: Scalars['String']['output'];
  resources?: Maybe<Scalars['JSON']['output']>;
  rrule: Scalars['String']['output'];
  tasks?: Maybe<Array<Task>>;
  title: Scalars['String']['output'];
  units?: Maybe<Array<PropertyUnit>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TaskScheduleCountAggregate = {
  __typename?: 'TaskScheduleCountAggregate';
  _all: Scalars['Int']['output'];
  approverId: Scalars['Int']['output'];
  assignee: Scalars['Int']['output'];
  categories: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  createdById: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  details: Scalars['Int']['output'];
  dueIn: Scalars['Int']['output'];
  enabled: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  organizationId: Scalars['Int']['output'];
  resources: Scalars['Int']['output'];
  rrule: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TaskScheduleCreateInput = {
  approver?: InputMaybe<UserCreateNestedOneWithoutApproverForTaskSchedulesInput>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutCreatedTaskSchedulesInput;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTaskSchedulesInput;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule: Scalars['String']['input'];
  tasks?: InputMaybe<TaskCreateNestedManyWithoutCreatedByTaskScheduleInput>;
  title: Scalars['String']['input'];
  units?: InputMaybe<PropertyUnitCreateNestedManyWithoutTaskSchedulesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleCreateManyApproverInput = {
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById: Scalars['String']['input'];
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleCreateManyApproverInputEnvelope = {
  data: Array<TaskScheduleCreateManyApproverInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskScheduleCreateManyCreatedByInput = {
  approverId?: InputMaybe<Scalars['String']['input']>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleCreateManyCreatedByInputEnvelope = {
  data: Array<TaskScheduleCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskScheduleCreateManyOrganizationInput = {
  approverId?: InputMaybe<Scalars['String']['input']>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById: Scalars['String']['input'];
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleCreateManyOrganizationInputEnvelope = {
  data: Array<TaskScheduleCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskScheduleCreateNestedManyWithoutApproverInput = {
  connect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskScheduleCreateOrConnectWithoutApproverInput>>;
  create?: InputMaybe<Array<TaskScheduleCreateWithoutApproverInput>>;
  createMany?: InputMaybe<TaskScheduleCreateManyApproverInputEnvelope>;
};

export type TaskScheduleCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskScheduleCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<TaskScheduleCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<TaskScheduleCreateManyCreatedByInputEnvelope>;
};

export type TaskScheduleCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskScheduleCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TaskScheduleCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TaskScheduleCreateManyOrganizationInputEnvelope>;
};

export type TaskScheduleCreateNestedManyWithoutUnitsInput = {
  connect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskScheduleCreateOrConnectWithoutUnitsInput>>;
  create?: InputMaybe<Array<TaskScheduleCreateWithoutUnitsInput>>;
};

export type TaskScheduleCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<TaskScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskScheduleCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<TaskScheduleCreateWithoutTasksInput>;
};

export type TaskScheduleCreateOrConnectWithoutApproverInput = {
  create: TaskScheduleCreateWithoutApproverInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleCreateOrConnectWithoutCreatedByInput = {
  create: TaskScheduleCreateWithoutCreatedByInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleCreateOrConnectWithoutOrganizationInput = {
  create: TaskScheduleCreateWithoutOrganizationInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleCreateOrConnectWithoutTasksInput = {
  create: TaskScheduleCreateWithoutTasksInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleCreateOrConnectWithoutUnitsInput = {
  create: TaskScheduleCreateWithoutUnitsInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleCreateWithoutApproverInput = {
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutCreatedTaskSchedulesInput;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTaskSchedulesInput;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule: Scalars['String']['input'];
  tasks?: InputMaybe<TaskCreateNestedManyWithoutCreatedByTaskScheduleInput>;
  title: Scalars['String']['input'];
  units?: InputMaybe<PropertyUnitCreateNestedManyWithoutTaskSchedulesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleCreateWithoutCreatedByInput = {
  approver?: InputMaybe<UserCreateNestedOneWithoutApproverForTaskSchedulesInput>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTaskSchedulesInput;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule: Scalars['String']['input'];
  tasks?: InputMaybe<TaskCreateNestedManyWithoutCreatedByTaskScheduleInput>;
  title: Scalars['String']['input'];
  units?: InputMaybe<PropertyUnitCreateNestedManyWithoutTaskSchedulesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleCreateWithoutOrganizationInput = {
  approver?: InputMaybe<UserCreateNestedOneWithoutApproverForTaskSchedulesInput>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutCreatedTaskSchedulesInput;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule: Scalars['String']['input'];
  tasks?: InputMaybe<TaskCreateNestedManyWithoutCreatedByTaskScheduleInput>;
  title: Scalars['String']['input'];
  units?: InputMaybe<PropertyUnitCreateNestedManyWithoutTaskSchedulesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleCreateWithoutTasksInput = {
  approver?: InputMaybe<UserCreateNestedOneWithoutApproverForTaskSchedulesInput>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutCreatedTaskSchedulesInput;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTaskSchedulesInput;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule: Scalars['String']['input'];
  title: Scalars['String']['input'];
  units?: InputMaybe<PropertyUnitCreateNestedManyWithoutTaskSchedulesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleCreateWithoutUnitsInput = {
  approver?: InputMaybe<UserCreateNestedOneWithoutApproverForTaskSchedulesInput>;
  assignee: TaskAssignee;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy: UserCreateNestedOneWithoutCreatedTaskSchedulesInput;
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization: OrganizationCreateNestedOneWithoutTaskSchedulesInput;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule: Scalars['String']['input'];
  tasks?: InputMaybe<TaskCreateNestedManyWithoutCreatedByTaskScheduleInput>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleListRelationFilter = {
  every?: InputMaybe<TaskScheduleWhereInput>;
  none?: InputMaybe<TaskScheduleWhereInput>;
  some?: InputMaybe<TaskScheduleWhereInput>;
};

export type TaskScheduleMaxAggregate = {
  __typename?: 'TaskScheduleMaxAggregate';
  approverId?: Maybe<Scalars['String']['output']>;
  assignee?: Maybe<TaskAssignee>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueIn?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  rrule?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TaskScheduleMinAggregate = {
  __typename?: 'TaskScheduleMinAggregate';
  approverId?: Maybe<Scalars['String']['output']>;
  assignee?: Maybe<TaskAssignee>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueIn?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  rrule?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TaskScheduleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TaskScheduleOrderByWithRelationInput = {
  approver?: InputMaybe<UserOrderByWithRelationInput>;
  approverId?: InputMaybe<SortOrderInput>;
  assignee?: InputMaybe<SortOrder>;
  categories?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<UserOrderByWithRelationInput>;
  createdById?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  details?: InputMaybe<SortOrderInput>;
  dueIn?: InputMaybe<SortOrderInput>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  organization?: InputMaybe<OrganizationOrderByWithRelationInput>;
  organizationId?: InputMaybe<SortOrder>;
  resources?: InputMaybe<SortOrderInput>;
  rrule?: InputMaybe<SortOrder>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  title?: InputMaybe<SortOrder>;
  units?: InputMaybe<PropertyUnitOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TaskScheduleRelationFilter = {
  is?: InputMaybe<TaskScheduleWhereInput>;
  isNot?: InputMaybe<TaskScheduleWhereInput>;
};

export enum TaskScheduleScalarFieldEnum {
  ApproverId = 'approverId',
  Assignee = 'assignee',
  Categories = 'categories',
  CreatedAt = 'createdAt',
  CreatedById = 'createdById',
  Description = 'description',
  Details = 'details',
  DueIn = 'dueIn',
  Enabled = 'enabled',
  Id = 'id',
  OrganizationId = 'organizationId',
  Resources = 'resources',
  Rrule = 'rrule',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type TaskScheduleScalarWhereInput = {
  AND?: InputMaybe<Array<TaskScheduleScalarWhereInput>>;
  NOT?: InputMaybe<Array<TaskScheduleScalarWhereInput>>;
  OR?: InputMaybe<Array<TaskScheduleScalarWhereInput>>;
  approverId?: InputMaybe<StringFilter>;
  assignee?: InputMaybe<EnumTaskAssigneeFilter>;
  categories?: InputMaybe<StringListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  details?: InputMaybe<JsonFilter>;
  dueIn?: InputMaybe<StringFilter>;
  enabled?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  organizationId?: InputMaybe<StringFilter>;
  resources?: InputMaybe<JsonFilter>;
  rrule?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TaskScheduleUpdateInput = {
  approver?: InputMaybe<UserUpdateOneWithoutApproverForTaskSchedulesNestedInput>;
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCreatedTaskSchedulesNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTaskSchedulesNestedInput>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUpdateManyWithoutCreatedByTaskScheduleNestedInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<PropertyUnitUpdateManyWithoutTaskSchedulesNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleUpdateManyMutationInput = {
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleUpdateManyWithWhereWithoutApproverInput = {
  data: TaskScheduleUpdateManyMutationInput;
  where: TaskScheduleScalarWhereInput;
};

export type TaskScheduleUpdateManyWithWhereWithoutCreatedByInput = {
  data: TaskScheduleUpdateManyMutationInput;
  where: TaskScheduleScalarWhereInput;
};

export type TaskScheduleUpdateManyWithWhereWithoutOrganizationInput = {
  data: TaskScheduleUpdateManyMutationInput;
  where: TaskScheduleScalarWhereInput;
};

export type TaskScheduleUpdateManyWithWhereWithoutUnitsInput = {
  data: TaskScheduleUpdateManyMutationInput;
  where: TaskScheduleScalarWhereInput;
};

export type TaskScheduleUpdateManyWithoutApproverNestedInput = {
  connect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskScheduleCreateOrConnectWithoutApproverInput>>;
  create?: InputMaybe<Array<TaskScheduleCreateWithoutApproverInput>>;
  createMany?: InputMaybe<TaskScheduleCreateManyApproverInputEnvelope>;
  delete?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScheduleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskScheduleUpdateWithWhereUniqueWithoutApproverInput>>;
  updateMany?: InputMaybe<Array<TaskScheduleUpdateManyWithWhereWithoutApproverInput>>;
  upsert?: InputMaybe<Array<TaskScheduleUpsertWithWhereUniqueWithoutApproverInput>>;
};

export type TaskScheduleUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskScheduleCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<TaskScheduleCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<TaskScheduleCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScheduleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskScheduleUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<TaskScheduleUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<TaskScheduleUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type TaskScheduleUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskScheduleCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<TaskScheduleCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<TaskScheduleCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScheduleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskScheduleUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<TaskScheduleUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<TaskScheduleUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type TaskScheduleUpdateManyWithoutUnitsNestedInput = {
  connect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskScheduleCreateOrConnectWithoutUnitsInput>>;
  create?: InputMaybe<Array<TaskScheduleCreateWithoutUnitsInput>>;
  delete?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScheduleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskScheduleWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskScheduleUpdateWithWhereUniqueWithoutUnitsInput>>;
  updateMany?: InputMaybe<Array<TaskScheduleUpdateManyWithWhereWithoutUnitsInput>>;
  upsert?: InputMaybe<Array<TaskScheduleUpsertWithWhereUniqueWithoutUnitsInput>>;
};

export type TaskScheduleUpdateOneWithoutTasksNestedInput = {
  connect?: InputMaybe<TaskScheduleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskScheduleCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<TaskScheduleCreateWithoutTasksInput>;
  delete?: InputMaybe<TaskScheduleWhereInput>;
  disconnect?: InputMaybe<TaskScheduleWhereInput>;
  update?: InputMaybe<TaskScheduleUpdateToOneWithWhereWithoutTasksInput>;
  upsert?: InputMaybe<TaskScheduleUpsertWithoutTasksInput>;
};

export type TaskScheduleUpdateToOneWithWhereWithoutTasksInput = {
  data: TaskScheduleUpdateWithoutTasksInput;
  where?: InputMaybe<TaskScheduleWhereInput>;
};

export type TaskScheduleUpdateWithWhereUniqueWithoutApproverInput = {
  data: TaskScheduleUpdateWithoutApproverInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: TaskScheduleUpdateWithoutCreatedByInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: TaskScheduleUpdateWithoutOrganizationInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleUpdateWithWhereUniqueWithoutUnitsInput = {
  data: TaskScheduleUpdateWithoutUnitsInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleUpdateWithoutApproverInput = {
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCreatedTaskSchedulesNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTaskSchedulesNestedInput>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUpdateManyWithoutCreatedByTaskScheduleNestedInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<PropertyUnitUpdateManyWithoutTaskSchedulesNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleUpdateWithoutCreatedByInput = {
  approver?: InputMaybe<UserUpdateOneWithoutApproverForTaskSchedulesNestedInput>;
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTaskSchedulesNestedInput>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUpdateManyWithoutCreatedByTaskScheduleNestedInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<PropertyUnitUpdateManyWithoutTaskSchedulesNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleUpdateWithoutOrganizationInput = {
  approver?: InputMaybe<UserUpdateOneWithoutApproverForTaskSchedulesNestedInput>;
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCreatedTaskSchedulesNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUpdateManyWithoutCreatedByTaskScheduleNestedInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<PropertyUnitUpdateManyWithoutTaskSchedulesNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleUpdateWithoutTasksInput = {
  approver?: InputMaybe<UserUpdateOneWithoutApproverForTaskSchedulesNestedInput>;
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCreatedTaskSchedulesNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTaskSchedulesNestedInput>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  units?: InputMaybe<PropertyUnitUpdateManyWithoutTaskSchedulesNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleUpdateWithoutUnitsInput = {
  approver?: InputMaybe<UserUpdateOneWithoutApproverForTaskSchedulesNestedInput>;
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCreatedTaskSchedulesNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueIn?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutTaskSchedulesNestedInput>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  rrule?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<TaskUpdateManyWithoutCreatedByTaskScheduleNestedInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskScheduleUpsertWithWhereUniqueWithoutApproverInput = {
  create: TaskScheduleCreateWithoutApproverInput;
  update: TaskScheduleUpdateWithoutApproverInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: TaskScheduleCreateWithoutCreatedByInput;
  update: TaskScheduleUpdateWithoutCreatedByInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: TaskScheduleCreateWithoutOrganizationInput;
  update: TaskScheduleUpdateWithoutOrganizationInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleUpsertWithWhereUniqueWithoutUnitsInput = {
  create: TaskScheduleCreateWithoutUnitsInput;
  update: TaskScheduleUpdateWithoutUnitsInput;
  where: TaskScheduleWhereUniqueInput;
};

export type TaskScheduleUpsertWithoutTasksInput = {
  create: TaskScheduleCreateWithoutTasksInput;
  update: TaskScheduleUpdateWithoutTasksInput;
  where?: InputMaybe<TaskScheduleWhereInput>;
};

export type TaskScheduleWhereInput = {
  AND?: InputMaybe<Array<TaskScheduleWhereInput>>;
  NOT?: InputMaybe<Array<TaskScheduleWhereInput>>;
  OR?: InputMaybe<Array<TaskScheduleWhereInput>>;
  approver?: InputMaybe<UserRelationFilter>;
  approverId?: InputMaybe<StringFilter>;
  assignee?: InputMaybe<EnumTaskAssigneeFilter>;
  categories?: InputMaybe<StringListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  details?: InputMaybe<JsonFilter>;
  dueIn?: InputMaybe<StringFilter>;
  enabled?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  resources?: InputMaybe<JsonFilter>;
  rrule?: InputMaybe<StringFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  units?: InputMaybe<PropertyUnitListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TaskScheduleWhereUniqueInput = {
  AND?: InputMaybe<Array<TaskScheduleWhereInput>>;
  NOT?: InputMaybe<Array<TaskScheduleWhereInput>>;
  OR?: InputMaybe<Array<TaskScheduleWhereInput>>;
  approver?: InputMaybe<UserRelationFilter>;
  approverId?: InputMaybe<StringFilter>;
  assignee?: InputMaybe<EnumTaskAssigneeFilter>;
  categories?: InputMaybe<StringListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  details?: InputMaybe<JsonFilter>;
  dueIn?: InputMaybe<StringFilter>;
  enabled?: InputMaybe<BoolFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  resources?: InputMaybe<JsonFilter>;
  rrule?: InputMaybe<StringFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  units?: InputMaybe<PropertyUnitListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum TaskStatus {
  Completed = 'Completed',
  Open = 'Open',
  PendingReview = 'PendingReview'
}

export type TaskUpdateInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approver?: InputMaybe<UserUpdateOneWithoutApproverForTasksNestedInput>;
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCreatedTasksNestedInput>;
  createdByTaskSchedule?: InputMaybe<TaskScheduleUpdateOneWithoutTasksNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<PropertyUnitUpdateOneRequiredWithoutTasksNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskUpdateManyMutationInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskUpdateManyWithWhereWithoutApproverInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithWhereWithoutCreatedByInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithWhereWithoutCreatedByTaskScheduleInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithWhereWithoutUnitInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithoutApproverNestedInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutApproverInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutApproverInput>>;
  createMany?: InputMaybe<TaskCreateManyApproverInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutApproverInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutApproverInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutApproverInput>>;
};

export type TaskUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<TaskCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type TaskUpdateManyWithoutCreatedByTaskScheduleNestedInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutCreatedByTaskScheduleInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutCreatedByTaskScheduleInput>>;
  createMany?: InputMaybe<TaskCreateManyCreatedByTaskScheduleInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutCreatedByTaskScheduleInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutCreatedByTaskScheduleInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutCreatedByTaskScheduleInput>>;
};

export type TaskUpdateManyWithoutUnitNestedInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutUnitInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutUnitInput>>;
  createMany?: InputMaybe<TaskCreateManyUnitInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutUnitInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutUnitInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutUnitInput>>;
};

export type TaskUpdateWithWhereUniqueWithoutApproverInput = {
  data: TaskUpdateWithoutApproverInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: TaskUpdateWithoutCreatedByInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithWhereUniqueWithoutCreatedByTaskScheduleInput = {
  data: TaskUpdateWithoutCreatedByTaskScheduleInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithWhereUniqueWithoutUnitInput = {
  data: TaskUpdateWithoutUnitInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithoutApproverInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCreatedTasksNestedInput>;
  createdByTaskSchedule?: InputMaybe<TaskScheduleUpdateOneWithoutTasksNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<PropertyUnitUpdateOneRequiredWithoutTasksNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskUpdateWithoutCreatedByInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approver?: InputMaybe<UserUpdateOneWithoutApproverForTasksNestedInput>;
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdByTaskSchedule?: InputMaybe<TaskScheduleUpdateOneWithoutTasksNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<PropertyUnitUpdateOneRequiredWithoutTasksNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskUpdateWithoutCreatedByTaskScheduleInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approver?: InputMaybe<UserUpdateOneWithoutApproverForTasksNestedInput>;
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCreatedTasksNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<PropertyUnitUpdateOneRequiredWithoutTasksNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskUpdateWithoutUnitInput = {
  approvedAt?: InputMaybe<Scalars['DateTime']['input']>;
  approver?: InputMaybe<UserUpdateOneWithoutApproverForTasksNestedInput>;
  assignee?: InputMaybe<TaskAssignee>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<UserUpdateOneRequiredWithoutCreatedTasksNestedInput>;
  createdByTaskSchedule?: InputMaybe<TaskScheduleUpdateOneWithoutTasksNestedInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  evidence?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<TaskStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskUpsertWithWhereUniqueWithoutApproverInput = {
  create: TaskCreateWithoutApproverInput;
  update: TaskUpdateWithoutApproverInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: TaskCreateWithoutCreatedByInput;
  update: TaskUpdateWithoutCreatedByInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithWhereUniqueWithoutCreatedByTaskScheduleInput = {
  create: TaskCreateWithoutCreatedByTaskScheduleInput;
  update: TaskUpdateWithoutCreatedByTaskScheduleInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithWhereUniqueWithoutUnitInput = {
  create: TaskCreateWithoutUnitInput;
  update: TaskUpdateWithoutUnitInput;
  where: TaskWhereUniqueInput;
};

export type TaskWhereInput = {
  AND?: InputMaybe<Array<TaskWhereInput>>;
  NOT?: InputMaybe<Array<TaskWhereInput>>;
  OR?: InputMaybe<Array<TaskWhereInput>>;
  approvedAt?: InputMaybe<DateTimeFilter>;
  approver?: InputMaybe<UserRelationFilter>;
  approverId?: InputMaybe<StringFilter>;
  assignee?: InputMaybe<EnumTaskAssigneeFilter>;
  categories?: InputMaybe<StringListFilter>;
  completedAt?: InputMaybe<DateTimeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  createdByTaskSchedule?: InputMaybe<TaskScheduleRelationFilter>;
  createdByTaskScheduleId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  details?: InputMaybe<JsonFilter>;
  dueAt?: InputMaybe<DateTimeFilter>;
  evidence?: InputMaybe<BytesFilter>;
  id?: InputMaybe<StringFilter>;
  resources?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumTaskStatusFilter>;
  title?: InputMaybe<StringFilter>;
  unit?: InputMaybe<PropertyUnitRelationFilter>;
  unitId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TaskWhereUniqueInput = {
  AND?: InputMaybe<Array<TaskWhereInput>>;
  NOT?: InputMaybe<Array<TaskWhereInput>>;
  OR?: InputMaybe<Array<TaskWhereInput>>;
  approvedAt?: InputMaybe<DateTimeFilter>;
  approver?: InputMaybe<UserRelationFilter>;
  approverId?: InputMaybe<StringFilter>;
  assignee?: InputMaybe<EnumTaskAssigneeFilter>;
  categories?: InputMaybe<StringListFilter>;
  completedAt?: InputMaybe<DateTimeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserRelationFilter>;
  createdById?: InputMaybe<StringFilter>;
  createdByTaskSchedule?: InputMaybe<TaskScheduleRelationFilter>;
  createdByTaskScheduleId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  details?: InputMaybe<JsonFilter>;
  dueAt?: InputMaybe<DateTimeFilter>;
  evidence?: InputMaybe<BytesFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  resources?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumTaskStatusFilter>;
  title?: InputMaybe<StringFilter>;
  unit?: InputMaybe<PropertyUnitRelationFilter>;
  unitId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Tenant = {
  __typename?: 'Tenant';
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  messageCodes?: Maybe<Array<MessageCode>>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  propertyUnit: PropertyUnit;
  propertyUnitId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TenantCountAggregate = {
  __typename?: 'TenantCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  phone: Scalars['Int']['output'];
  propertyUnitId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TenantCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  messageCodes?: InputMaybe<MessageCodeCreateNestedManyWithoutTenantInput>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  propertyUnit: PropertyUnitCreateNestedOneWithoutTenantsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TenantCreateManyPropertyUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TenantCreateManyPropertyUnitInputEnvelope = {
  data: Array<TenantCreateManyPropertyUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TenantCreateNestedManyWithoutPropertyUnitInput = {
  connect?: InputMaybe<Array<TenantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TenantCreateOrConnectWithoutPropertyUnitInput>>;
  create?: InputMaybe<Array<TenantCreateWithoutPropertyUnitInput>>;
  createMany?: InputMaybe<TenantCreateManyPropertyUnitInputEnvelope>;
};

export type TenantCreateOrConnectWithoutPropertyUnitInput = {
  create: TenantCreateWithoutPropertyUnitInput;
  where: TenantWhereUniqueInput;
};

export type TenantCreateWithoutPropertyUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  messageCodes?: InputMaybe<MessageCodeCreateNestedManyWithoutTenantInput>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TenantListRelationFilter = {
  every?: InputMaybe<TenantWhereInput>;
  none?: InputMaybe<TenantWhereInput>;
  some?: InputMaybe<TenantWhereInput>;
};

export type TenantMaxAggregate = {
  __typename?: 'TenantMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  propertyUnitId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TenantMinAggregate = {
  __typename?: 'TenantMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  propertyUnitId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TenantOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TenantRelationFilter = {
  is?: InputMaybe<TenantWhereInput>;
  isNot?: InputMaybe<TenantWhereInput>;
};

export type TenantScalarWhereInput = {
  AND?: InputMaybe<Array<TenantScalarWhereInput>>;
  NOT?: InputMaybe<Array<TenantScalarWhereInput>>;
  OR?: InputMaybe<Array<TenantScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  propertyUnitId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TenantUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TenantUpdateManyWithWhereWithoutPropertyUnitInput = {
  data: TenantUpdateManyMutationInput;
  where: TenantScalarWhereInput;
};

export type TenantUpdateManyWithoutPropertyUnitNestedInput = {
  connect?: InputMaybe<Array<TenantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TenantCreateOrConnectWithoutPropertyUnitInput>>;
  create?: InputMaybe<Array<TenantCreateWithoutPropertyUnitInput>>;
  createMany?: InputMaybe<TenantCreateManyPropertyUnitInputEnvelope>;
  delete?: InputMaybe<Array<TenantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TenantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TenantWhereUniqueInput>>;
  set?: InputMaybe<Array<TenantWhereUniqueInput>>;
  update?: InputMaybe<Array<TenantUpdateWithWhereUniqueWithoutPropertyUnitInput>>;
  updateMany?: InputMaybe<Array<TenantUpdateManyWithWhereWithoutPropertyUnitInput>>;
  upsert?: InputMaybe<Array<TenantUpsertWithWhereUniqueWithoutPropertyUnitInput>>;
};

export type TenantUpdateWithWhereUniqueWithoutPropertyUnitInput = {
  data: TenantUpdateWithoutPropertyUnitInput;
  where: TenantWhereUniqueInput;
};

export type TenantUpdateWithoutPropertyUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  messageCodes?: InputMaybe<MessageCodeUpdateManyWithoutTenantNestedInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TenantUpsertWithWhereUniqueWithoutPropertyUnitInput = {
  create: TenantCreateWithoutPropertyUnitInput;
  update: TenantUpdateWithoutPropertyUnitInput;
  where: TenantWhereUniqueInput;
};

export type TenantWhereInput = {
  AND?: InputMaybe<Array<TenantWhereInput>>;
  NOT?: InputMaybe<Array<TenantWhereInput>>;
  OR?: InputMaybe<Array<TenantWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  messageCodes?: InputMaybe<MessageCodeListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  propertyUnit?: InputMaybe<PropertyUnitRelationFilter>;
  propertyUnitId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TenantWhereUniqueInput = {
  AND?: InputMaybe<Array<TenantWhereInput>>;
  NOT?: InputMaybe<Array<TenantWhereInput>>;
  OR?: InputMaybe<Array<TenantWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  messageCodes?: InputMaybe<MessageCodeListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  propertyUnit?: InputMaybe<PropertyUnitRelationFilter>;
  propertyUnitId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type User = {
  __typename?: 'User';
  approverForTaskSchedules?: Maybe<Array<TaskSchedule>>;
  approverForTasks?: Maybe<Array<Task>>;
  createdAt: Scalars['DateTime']['output'];
  createdTaskSchedules?: Maybe<Array<TaskSchedule>>;
  createdTasks?: Maybe<Array<Task>>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizations?: Maybe<Array<UsersInOrganizations>>;
  subscription?: Maybe<UserSubscriptionDetails>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type UserCreateNestedOneWithoutApproverForTaskSchedulesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutApproverForTaskSchedulesInput>;
  create?: InputMaybe<UserCreateWithoutApproverForTaskSchedulesInput>;
};

export type UserCreateNestedOneWithoutApproverForTasksInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutApproverForTasksInput>;
  create?: InputMaybe<UserCreateWithoutApproverForTasksInput>;
};

export type UserCreateNestedOneWithoutCreatedTaskSchedulesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedTaskSchedulesInput>;
  create?: InputMaybe<UserCreateWithoutCreatedTaskSchedulesInput>;
};

export type UserCreateNestedOneWithoutCreatedTasksInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedTasksInput>;
  create?: InputMaybe<UserCreateWithoutCreatedTasksInput>;
};

export type UserCreateNestedOneWithoutOrganizationsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOrganizationsInput>;
  create?: InputMaybe<UserCreateWithoutOrganizationsInput>;
};

export type UserCreateOrConnectWithoutApproverForTaskSchedulesInput = {
  create: UserCreateWithoutApproverForTaskSchedulesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutApproverForTasksInput = {
  create: UserCreateWithoutApproverForTasksInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreatedTaskSchedulesInput = {
  create: UserCreateWithoutCreatedTaskSchedulesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreatedTasksInput = {
  create: UserCreateWithoutCreatedTasksInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOrganizationsInput = {
  create: UserCreateWithoutOrganizationsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutApproverForTaskSchedulesInput = {
  approverForTasks?: InputMaybe<TaskCreateNestedManyWithoutApproverInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTaskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutCreatedByInput>;
  createdTasks?: InputMaybe<TaskCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizations?: InputMaybe<UsersInOrganizationsCreateNestedManyWithoutUserInput>;
  subscription?: InputMaybe<UserSubscriptionDetailsCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserCreateWithoutApproverForTasksInput = {
  approverForTaskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutApproverInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTaskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutCreatedByInput>;
  createdTasks?: InputMaybe<TaskCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizations?: InputMaybe<UsersInOrganizationsCreateNestedManyWithoutUserInput>;
  subscription?: InputMaybe<UserSubscriptionDetailsCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserCreateWithoutCreatedTaskSchedulesInput = {
  approverForTaskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutApproverInput>;
  approverForTasks?: InputMaybe<TaskCreateNestedManyWithoutApproverInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTasks?: InputMaybe<TaskCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizations?: InputMaybe<UsersInOrganizationsCreateNestedManyWithoutUserInput>;
  subscription?: InputMaybe<UserSubscriptionDetailsCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserCreateWithoutCreatedTasksInput = {
  approverForTaskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutApproverInput>;
  approverForTasks?: InputMaybe<TaskCreateNestedManyWithoutApproverInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTaskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizations?: InputMaybe<UsersInOrganizationsCreateNestedManyWithoutUserInput>;
  subscription?: InputMaybe<UserSubscriptionDetailsCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserCreateWithoutOrganizationsInput = {
  approverForTaskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutApproverInput>;
  approverForTasks?: InputMaybe<TaskCreateNestedManyWithoutApproverInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTaskSchedules?: InputMaybe<TaskScheduleCreateNestedManyWithoutCreatedByInput>;
  createdTasks?: InputMaybe<TaskCreateNestedManyWithoutCreatedByInput>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  subscription?: InputMaybe<UserSubscriptionDetailsCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserOrderByWithRelationInput = {
  approverForTaskSchedules?: InputMaybe<TaskScheduleOrderByRelationAggregateInput>;
  approverForTasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdTaskSchedules?: InputMaybe<TaskScheduleOrderByRelationAggregateInput>;
  createdTasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  organizations?: InputMaybe<UsersInOrganizationsOrderByRelationAggregateInput>;
  subscription?: InputMaybe<UserSubscriptionDetailsOrderByWithRelationInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserSubscriptionDetails = {
  __typename?: 'UserSubscriptionDetails';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  stripeCustomerId: Scalars['String']['output'];
  stripeEntitlements?: Maybe<Array<Scalars['String']['output']>>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userEmail: Scalars['String']['output'];
};

export type UserSubscriptionDetailsCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<UserSubscriptionDetailsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSubscriptionDetailsCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<UserSubscriptionDetailsCreateWithoutUserInput>;
};

export type UserSubscriptionDetailsCreateOrConnectWithoutUserInput = {
  create: UserSubscriptionDetailsCreateWithoutUserInput;
  where: UserSubscriptionDetailsWhereUniqueInput;
};

export type UserSubscriptionDetailsCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId: Scalars['String']['input'];
  stripeEntitlements?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserSubscriptionDetailsOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  stripeCustomerId?: InputMaybe<SortOrder>;
  stripeEntitlements?: InputMaybe<SortOrder>;
  stripeSubscriptionId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userEmail?: InputMaybe<SortOrder>;
};

export type UserSubscriptionDetailsRelationFilter = {
  is?: InputMaybe<UserSubscriptionDetailsWhereInput>;
  isNot?: InputMaybe<UserSubscriptionDetailsWhereInput>;
};

export type UserSubscriptionDetailsUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<UserSubscriptionDetailsWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserSubscriptionDetailsCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<UserSubscriptionDetailsCreateWithoutUserInput>;
  delete?: InputMaybe<UserSubscriptionDetailsWhereInput>;
  disconnect?: InputMaybe<UserSubscriptionDetailsWhereInput>;
  update?: InputMaybe<UserSubscriptionDetailsUpdateToOneWithWhereWithoutUserInput>;
  upsert?: InputMaybe<UserSubscriptionDetailsUpsertWithoutUserInput>;
};

export type UserSubscriptionDetailsUpdateToOneWithWhereWithoutUserInput = {
  data: UserSubscriptionDetailsUpdateWithoutUserInput;
  where?: InputMaybe<UserSubscriptionDetailsWhereInput>;
};

export type UserSubscriptionDetailsUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  stripeEntitlements?: InputMaybe<Array<Scalars['String']['input']>>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserSubscriptionDetailsUpsertWithoutUserInput = {
  create: UserSubscriptionDetailsCreateWithoutUserInput;
  update: UserSubscriptionDetailsUpdateWithoutUserInput;
  where?: InputMaybe<UserSubscriptionDetailsWhereInput>;
};

export type UserSubscriptionDetailsWhereInput = {
  AND?: InputMaybe<Array<UserSubscriptionDetailsWhereInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionDetailsWhereInput>>;
  OR?: InputMaybe<Array<UserSubscriptionDetailsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  stripeCustomerId?: InputMaybe<StringFilter>;
  stripeEntitlements?: InputMaybe<StringListFilter>;
  stripeSubscriptionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userEmail?: InputMaybe<StringFilter>;
};

export type UserSubscriptionDetailsWhereUniqueInput = {
  AND?: InputMaybe<Array<UserSubscriptionDetailsWhereInput>>;
  NOT?: InputMaybe<Array<UserSubscriptionDetailsWhereInput>>;
  OR?: InputMaybe<Array<UserSubscriptionDetailsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  stripeEntitlements?: InputMaybe<StringListFilter>;
  stripeSubscriptionId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export type UserUpdateOneRequiredWithoutCreatedTaskSchedulesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedTaskSchedulesInput>;
  create?: InputMaybe<UserCreateWithoutCreatedTaskSchedulesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutCreatedTaskSchedulesInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreatedTaskSchedulesInput>;
};

export type UserUpdateOneRequiredWithoutCreatedTasksNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedTasksInput>;
  create?: InputMaybe<UserCreateWithoutCreatedTasksInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutCreatedTasksInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreatedTasksInput>;
};

export type UserUpdateOneRequiredWithoutOrganizationsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOrganizationsInput>;
  create?: InputMaybe<UserCreateWithoutOrganizationsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutOrganizationsInput>;
  upsert?: InputMaybe<UserUpsertWithoutOrganizationsInput>;
};

export type UserUpdateOneWithoutApproverForTaskSchedulesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutApproverForTaskSchedulesInput>;
  create?: InputMaybe<UserCreateWithoutApproverForTaskSchedulesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutApproverForTaskSchedulesInput>;
  upsert?: InputMaybe<UserUpsertWithoutApproverForTaskSchedulesInput>;
};

export type UserUpdateOneWithoutApproverForTasksNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutApproverForTasksInput>;
  create?: InputMaybe<UserCreateWithoutApproverForTasksInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutApproverForTasksInput>;
  upsert?: InputMaybe<UserUpsertWithoutApproverForTasksInput>;
};

export type UserUpdateToOneWithWhereWithoutApproverForTaskSchedulesInput = {
  data: UserUpdateWithoutApproverForTaskSchedulesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutApproverForTasksInput = {
  data: UserUpdateWithoutApproverForTasksInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutCreatedTaskSchedulesInput = {
  data: UserUpdateWithoutCreatedTaskSchedulesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutCreatedTasksInput = {
  data: UserUpdateWithoutCreatedTasksInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutOrganizationsInput = {
  data: UserUpdateWithoutOrganizationsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateWithoutApproverForTaskSchedulesInput = {
  approverForTasks?: InputMaybe<TaskUpdateManyWithoutApproverNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTaskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutCreatedByNestedInput>;
  createdTasks?: InputMaybe<TaskUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<UsersInOrganizationsUpdateManyWithoutUserNestedInput>;
  subscription?: InputMaybe<UserSubscriptionDetailsUpdateOneWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserUpdateWithoutApproverForTasksInput = {
  approverForTaskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutApproverNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTaskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutCreatedByNestedInput>;
  createdTasks?: InputMaybe<TaskUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<UsersInOrganizationsUpdateManyWithoutUserNestedInput>;
  subscription?: InputMaybe<UserSubscriptionDetailsUpdateOneWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserUpdateWithoutCreatedTaskSchedulesInput = {
  approverForTaskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutApproverNestedInput>;
  approverForTasks?: InputMaybe<TaskUpdateManyWithoutApproverNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTasks?: InputMaybe<TaskUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<UsersInOrganizationsUpdateManyWithoutUserNestedInput>;
  subscription?: InputMaybe<UserSubscriptionDetailsUpdateOneWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserUpdateWithoutCreatedTasksInput = {
  approverForTaskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutApproverNestedInput>;
  approverForTasks?: InputMaybe<TaskUpdateManyWithoutApproverNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTaskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<UsersInOrganizationsUpdateManyWithoutUserNestedInput>;
  subscription?: InputMaybe<UserSubscriptionDetailsUpdateOneWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserUpdateWithoutOrganizationsInput = {
  approverForTaskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutApproverNestedInput>;
  approverForTasks?: InputMaybe<TaskUpdateManyWithoutApproverNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdTaskSchedules?: InputMaybe<TaskScheduleUpdateManyWithoutCreatedByNestedInput>;
  createdTasks?: InputMaybe<TaskUpdateManyWithoutCreatedByNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<UserSubscriptionDetailsUpdateOneWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserUpsertWithoutApproverForTaskSchedulesInput = {
  create: UserCreateWithoutApproverForTaskSchedulesInput;
  update: UserUpdateWithoutApproverForTaskSchedulesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutApproverForTasksInput = {
  create: UserCreateWithoutApproverForTasksInput;
  update: UserUpdateWithoutApproverForTasksInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutCreatedTaskSchedulesInput = {
  create: UserCreateWithoutCreatedTaskSchedulesInput;
  update: UserUpdateWithoutCreatedTaskSchedulesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutCreatedTasksInput = {
  create: UserCreateWithoutCreatedTasksInput;
  update: UserUpdateWithoutCreatedTasksInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutOrganizationsInput = {
  create: UserCreateWithoutOrganizationsInput;
  update: UserUpdateWithoutOrganizationsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  approverForTaskSchedules?: InputMaybe<TaskScheduleListRelationFilter>;
  approverForTasks?: InputMaybe<TaskListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdTaskSchedules?: InputMaybe<TaskScheduleListRelationFilter>;
  createdTasks?: InputMaybe<TaskListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  organizations?: InputMaybe<UsersInOrganizationsListRelationFilter>;
  subscription?: InputMaybe<UserSubscriptionDetailsRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  approverForTaskSchedules?: InputMaybe<TaskScheduleListRelationFilter>;
  approverForTasks?: InputMaybe<TaskListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdTaskSchedules?: InputMaybe<TaskScheduleListRelationFilter>;
  createdTasks?: InputMaybe<TaskListRelationFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<StringFilter>;
  organizations?: InputMaybe<UsersInOrganizationsListRelationFilter>;
  subscription?: InputMaybe<UserSubscriptionDetailsRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UsersInOrganizations = {
  __typename?: 'UsersInOrganizations';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  organization: Organization;
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UsersInOrganizationsCreateManyOrganizationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type UsersInOrganizationsCreateManyOrganizationInputEnvelope = {
  data: Array<UsersInOrganizationsCreateManyOrganizationInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UsersInOrganizationsCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UsersInOrganizationsCreateManyUserInputEnvelope = {
  data: Array<UsersInOrganizationsCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UsersInOrganizationsCreateNestedManyWithoutOrganizationInput = {
  connect?: InputMaybe<Array<UsersInOrganizationsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UsersInOrganizationsCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<UsersInOrganizationsCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<UsersInOrganizationsCreateManyOrganizationInputEnvelope>;
};

export type UsersInOrganizationsCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UsersInOrganizationsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UsersInOrganizationsCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UsersInOrganizationsCreateWithoutUserInput>>;
  createMany?: InputMaybe<UsersInOrganizationsCreateManyUserInputEnvelope>;
};

export type UsersInOrganizationsCreateOrConnectWithoutOrganizationInput = {
  create: UsersInOrganizationsCreateWithoutOrganizationInput;
  where: UsersInOrganizationsWhereUniqueInput;
};

export type UsersInOrganizationsCreateOrConnectWithoutUserInput = {
  create: UsersInOrganizationsCreateWithoutUserInput;
  where: UsersInOrganizationsWhereUniqueInput;
};

export type UsersInOrganizationsCreateWithoutOrganizationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutOrganizationsInput;
};

export type UsersInOrganizationsCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationCreateNestedOneWithoutMembersInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UsersInOrganizationsListRelationFilter = {
  every?: InputMaybe<UsersInOrganizationsWhereInput>;
  none?: InputMaybe<UsersInOrganizationsWhereInput>;
  some?: InputMaybe<UsersInOrganizationsWhereInput>;
};

export type UsersInOrganizationsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UsersInOrganizationsScalarWhereInput = {
  AND?: InputMaybe<Array<UsersInOrganizationsScalarWhereInput>>;
  NOT?: InputMaybe<Array<UsersInOrganizationsScalarWhereInput>>;
  OR?: InputMaybe<Array<UsersInOrganizationsScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isAdmin?: InputMaybe<BoolFilter>;
  organizationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UsersInOrganizationsUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UsersInOrganizationsUpdateManyWithWhereWithoutOrganizationInput = {
  data: UsersInOrganizationsUpdateManyMutationInput;
  where: UsersInOrganizationsScalarWhereInput;
};

export type UsersInOrganizationsUpdateManyWithWhereWithoutUserInput = {
  data: UsersInOrganizationsUpdateManyMutationInput;
  where: UsersInOrganizationsScalarWhereInput;
};

export type UsersInOrganizationsUpdateManyWithoutOrganizationNestedInput = {
  connect?: InputMaybe<Array<UsersInOrganizationsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UsersInOrganizationsCreateOrConnectWithoutOrganizationInput>>;
  create?: InputMaybe<Array<UsersInOrganizationsCreateWithoutOrganizationInput>>;
  createMany?: InputMaybe<UsersInOrganizationsCreateManyOrganizationInputEnvelope>;
  delete?: InputMaybe<Array<UsersInOrganizationsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UsersInOrganizationsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UsersInOrganizationsWhereUniqueInput>>;
  set?: InputMaybe<Array<UsersInOrganizationsWhereUniqueInput>>;
  update?: InputMaybe<Array<UsersInOrganizationsUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: InputMaybe<Array<UsersInOrganizationsUpdateManyWithWhereWithoutOrganizationInput>>;
  upsert?: InputMaybe<Array<UsersInOrganizationsUpsertWithWhereUniqueWithoutOrganizationInput>>;
};

export type UsersInOrganizationsUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UsersInOrganizationsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UsersInOrganizationsCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UsersInOrganizationsCreateWithoutUserInput>>;
  createMany?: InputMaybe<UsersInOrganizationsCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UsersInOrganizationsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UsersInOrganizationsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UsersInOrganizationsWhereUniqueInput>>;
  set?: InputMaybe<Array<UsersInOrganizationsWhereUniqueInput>>;
  update?: InputMaybe<Array<UsersInOrganizationsUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UsersInOrganizationsUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UsersInOrganizationsUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UsersInOrganizationsUpdateWithWhereUniqueWithoutOrganizationInput = {
  data: UsersInOrganizationsUpdateWithoutOrganizationInput;
  where: UsersInOrganizationsWhereUniqueInput;
};

export type UsersInOrganizationsUpdateWithWhereUniqueWithoutUserInput = {
  data: UsersInOrganizationsUpdateWithoutUserInput;
  where: UsersInOrganizationsWhereUniqueInput;
};

export type UsersInOrganizationsUpdateWithoutOrganizationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOrganizationsNestedInput>;
};

export type UsersInOrganizationsUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<OrganizationUpdateOneRequiredWithoutMembersNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UsersInOrganizationsUpsertWithWhereUniqueWithoutOrganizationInput = {
  create: UsersInOrganizationsCreateWithoutOrganizationInput;
  update: UsersInOrganizationsUpdateWithoutOrganizationInput;
  where: UsersInOrganizationsWhereUniqueInput;
};

export type UsersInOrganizationsUpsertWithWhereUniqueWithoutUserInput = {
  create: UsersInOrganizationsCreateWithoutUserInput;
  update: UsersInOrganizationsUpdateWithoutUserInput;
  where: UsersInOrganizationsWhereUniqueInput;
};

export type UsersInOrganizationsWhereInput = {
  AND?: InputMaybe<Array<UsersInOrganizationsWhereInput>>;
  NOT?: InputMaybe<Array<UsersInOrganizationsWhereInput>>;
  OR?: InputMaybe<Array<UsersInOrganizationsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isAdmin?: InputMaybe<BoolFilter>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UsersInOrganizationsWhereUniqueInput = {
  AND?: InputMaybe<Array<UsersInOrganizationsWhereInput>>;
  NOT?: InputMaybe<Array<UsersInOrganizationsWhereInput>>;
  OR?: InputMaybe<Array<UsersInOrganizationsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<BoolFilter>;
  organization?: InputMaybe<OrganizationRelationFilter>;
  organizationId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AggregateTask: ResolverTypeWrapper<AggregateTask>;
  BoolFilter: BoolFilter;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  BytesFilter: BytesFilter;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DateTimeFilter: DateTimeFilter;
  EnumTaskAssigneeFilter: EnumTaskAssigneeFilter;
  EnumTaskStatusFilter: EnumTaskStatusFilter;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  JsonFilter: JsonFilter;
  MessageCode: ResolverTypeWrapper<MessageCode>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  MessageCodeCreateManyTenantInput: MessageCodeCreateManyTenantInput;
  MessageCodeCreateManyTenantInputEnvelope: MessageCodeCreateManyTenantInputEnvelope;
  MessageCodeCreateNestedManyWithoutTenantInput: MessageCodeCreateNestedManyWithoutTenantInput;
  MessageCodeCreateOrConnectWithoutTenantInput: MessageCodeCreateOrConnectWithoutTenantInput;
  MessageCodeCreateWithoutTenantInput: MessageCodeCreateWithoutTenantInput;
  MessageCodeListRelationFilter: MessageCodeListRelationFilter;
  MessageCodeScalarWhereInput: MessageCodeScalarWhereInput;
  MessageCodeUpdateManyMutationInput: MessageCodeUpdateManyMutationInput;
  MessageCodeUpdateManyWithWhereWithoutTenantInput: MessageCodeUpdateManyWithWhereWithoutTenantInput;
  MessageCodeUpdateManyWithoutTenantNestedInput: MessageCodeUpdateManyWithoutTenantNestedInput;
  MessageCodeUpdateWithWhereUniqueWithoutTenantInput: MessageCodeUpdateWithWhereUniqueWithoutTenantInput;
  MessageCodeUpdateWithoutTenantInput: MessageCodeUpdateWithoutTenantInput;
  MessageCodeUpsertWithWhereUniqueWithoutTenantInput: MessageCodeUpsertWithWhereUniqueWithoutTenantInput;
  MessageCodeWhereInput: MessageCodeWhereInput;
  MessageCodeWhereUniqueInput: MessageCodeWhereUniqueInput;
  Mutation: ResolverTypeWrapper<{}>;
  NullsOrder: NullsOrder;
  Organization: ResolverTypeWrapper<Organization>;
  OrganizationCountAggregate: ResolverTypeWrapper<OrganizationCountAggregate>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  OrganizationCreateNestedOneWithoutMembersInput: OrganizationCreateNestedOneWithoutMembersInput;
  OrganizationCreateNestedOneWithoutPropertiesInput: OrganizationCreateNestedOneWithoutPropertiesInput;
  OrganizationCreateNestedOneWithoutTaskSchedulesInput: OrganizationCreateNestedOneWithoutTaskSchedulesInput;
  OrganizationCreateOrConnectWithoutMembersInput: OrganizationCreateOrConnectWithoutMembersInput;
  OrganizationCreateOrConnectWithoutPropertiesInput: OrganizationCreateOrConnectWithoutPropertiesInput;
  OrganizationCreateOrConnectWithoutTaskSchedulesInput: OrganizationCreateOrConnectWithoutTaskSchedulesInput;
  OrganizationCreateWithoutMembersInput: OrganizationCreateWithoutMembersInput;
  OrganizationCreateWithoutPropertiesInput: OrganizationCreateWithoutPropertiesInput;
  OrganizationCreateWithoutTaskSchedulesInput: OrganizationCreateWithoutTaskSchedulesInput;
  OrganizationMaxAggregate: ResolverTypeWrapper<OrganizationMaxAggregate>;
  OrganizationMinAggregate: ResolverTypeWrapper<OrganizationMinAggregate>;
  OrganizationOrderByWithRelationInput: OrganizationOrderByWithRelationInput;
  OrganizationRelationFilter: OrganizationRelationFilter;
  OrganizationUpdateInput: OrganizationUpdateInput;
  OrganizationUpdateOneRequiredWithoutMembersNestedInput: OrganizationUpdateOneRequiredWithoutMembersNestedInput;
  OrganizationUpdateOneRequiredWithoutPropertiesNestedInput: OrganizationUpdateOneRequiredWithoutPropertiesNestedInput;
  OrganizationUpdateOneRequiredWithoutTaskSchedulesNestedInput: OrganizationUpdateOneRequiredWithoutTaskSchedulesNestedInput;
  OrganizationUpdateToOneWithWhereWithoutMembersInput: OrganizationUpdateToOneWithWhereWithoutMembersInput;
  OrganizationUpdateToOneWithWhereWithoutPropertiesInput: OrganizationUpdateToOneWithWhereWithoutPropertiesInput;
  OrganizationUpdateToOneWithWhereWithoutTaskSchedulesInput: OrganizationUpdateToOneWithWhereWithoutTaskSchedulesInput;
  OrganizationUpdateWithoutMembersInput: OrganizationUpdateWithoutMembersInput;
  OrganizationUpdateWithoutPropertiesInput: OrganizationUpdateWithoutPropertiesInput;
  OrganizationUpdateWithoutTaskSchedulesInput: OrganizationUpdateWithoutTaskSchedulesInput;
  OrganizationUpsertWithoutMembersInput: OrganizationUpsertWithoutMembersInput;
  OrganizationUpsertWithoutPropertiesInput: OrganizationUpsertWithoutPropertiesInput;
  OrganizationUpsertWithoutTaskSchedulesInput: OrganizationUpsertWithoutTaskSchedulesInput;
  OrganizationWhereInput: OrganizationWhereInput;
  OrganizationWhereUniqueInput: OrganizationWhereUniqueInput;
  Property: ResolverTypeWrapper<Property>;
  PropertyCountAggregate: ResolverTypeWrapper<PropertyCountAggregate>;
  PropertyCreateInput: PropertyCreateInput;
  PropertyCreateManyOrganizationInput: PropertyCreateManyOrganizationInput;
  PropertyCreateManyOrganizationInputEnvelope: PropertyCreateManyOrganizationInputEnvelope;
  PropertyCreateNestedManyWithoutOrganizationInput: PropertyCreateNestedManyWithoutOrganizationInput;
  PropertyCreateNestedOneWithoutUnitsInput: PropertyCreateNestedOneWithoutUnitsInput;
  PropertyCreateOrConnectWithoutOrganizationInput: PropertyCreateOrConnectWithoutOrganizationInput;
  PropertyCreateOrConnectWithoutUnitsInput: PropertyCreateOrConnectWithoutUnitsInput;
  PropertyCreateWithoutOrganizationInput: PropertyCreateWithoutOrganizationInput;
  PropertyCreateWithoutUnitsInput: PropertyCreateWithoutUnitsInput;
  PropertyListRelationFilter: PropertyListRelationFilter;
  PropertyMaxAggregate: ResolverTypeWrapper<PropertyMaxAggregate>;
  PropertyMinAggregate: ResolverTypeWrapper<PropertyMinAggregate>;
  PropertyOrderByRelationAggregateInput: PropertyOrderByRelationAggregateInput;
  PropertyOrderByWithRelationInput: PropertyOrderByWithRelationInput;
  PropertyRelationFilter: PropertyRelationFilter;
  PropertyScalarWhereInput: PropertyScalarWhereInput;
  PropertyUnit: ResolverTypeWrapper<PropertyUnit>;
  PropertyUnitCountAggregate: ResolverTypeWrapper<PropertyUnitCountAggregate>;
  PropertyUnitCreateInput: PropertyUnitCreateInput;
  PropertyUnitCreateManyPropertyInput: PropertyUnitCreateManyPropertyInput;
  PropertyUnitCreateManyPropertyInputEnvelope: PropertyUnitCreateManyPropertyInputEnvelope;
  PropertyUnitCreateNestedManyWithoutPropertyInput: PropertyUnitCreateNestedManyWithoutPropertyInput;
  PropertyUnitCreateNestedManyWithoutTaskSchedulesInput: PropertyUnitCreateNestedManyWithoutTaskSchedulesInput;
  PropertyUnitCreateNestedOneWithoutTasksInput: PropertyUnitCreateNestedOneWithoutTasksInput;
  PropertyUnitCreateNestedOneWithoutTenantsInput: PropertyUnitCreateNestedOneWithoutTenantsInput;
  PropertyUnitCreateOrConnectWithoutPropertyInput: PropertyUnitCreateOrConnectWithoutPropertyInput;
  PropertyUnitCreateOrConnectWithoutTaskSchedulesInput: PropertyUnitCreateOrConnectWithoutTaskSchedulesInput;
  PropertyUnitCreateOrConnectWithoutTasksInput: PropertyUnitCreateOrConnectWithoutTasksInput;
  PropertyUnitCreateOrConnectWithoutTenantsInput: PropertyUnitCreateOrConnectWithoutTenantsInput;
  PropertyUnitCreateWithoutPropertyInput: PropertyUnitCreateWithoutPropertyInput;
  PropertyUnitCreateWithoutTaskSchedulesInput: PropertyUnitCreateWithoutTaskSchedulesInput;
  PropertyUnitCreateWithoutTasksInput: PropertyUnitCreateWithoutTasksInput;
  PropertyUnitCreateWithoutTenantsInput: PropertyUnitCreateWithoutTenantsInput;
  PropertyUnitListRelationFilter: PropertyUnitListRelationFilter;
  PropertyUnitMaxAggregate: ResolverTypeWrapper<PropertyUnitMaxAggregate>;
  PropertyUnitMinAggregate: ResolverTypeWrapper<PropertyUnitMinAggregate>;
  PropertyUnitOrderByRelationAggregateInput: PropertyUnitOrderByRelationAggregateInput;
  PropertyUnitOrderByWithRelationInput: PropertyUnitOrderByWithRelationInput;
  PropertyUnitRelationFilter: PropertyUnitRelationFilter;
  PropertyUnitScalarFieldEnum: PropertyUnitScalarFieldEnum;
  PropertyUnitScalarWhereInput: PropertyUnitScalarWhereInput;
  PropertyUnitUpdateInput: PropertyUnitUpdateInput;
  PropertyUnitUpdateManyMutationInput: PropertyUnitUpdateManyMutationInput;
  PropertyUnitUpdateManyWithWhereWithoutPropertyInput: PropertyUnitUpdateManyWithWhereWithoutPropertyInput;
  PropertyUnitUpdateManyWithWhereWithoutTaskSchedulesInput: PropertyUnitUpdateManyWithWhereWithoutTaskSchedulesInput;
  PropertyUnitUpdateManyWithoutPropertyNestedInput: PropertyUnitUpdateManyWithoutPropertyNestedInput;
  PropertyUnitUpdateManyWithoutTaskSchedulesNestedInput: PropertyUnitUpdateManyWithoutTaskSchedulesNestedInput;
  PropertyUnitUpdateOneRequiredWithoutTasksNestedInput: PropertyUnitUpdateOneRequiredWithoutTasksNestedInput;
  PropertyUnitUpdateToOneWithWhereWithoutTasksInput: PropertyUnitUpdateToOneWithWhereWithoutTasksInput;
  PropertyUnitUpdateWithWhereUniqueWithoutPropertyInput: PropertyUnitUpdateWithWhereUniqueWithoutPropertyInput;
  PropertyUnitUpdateWithWhereUniqueWithoutTaskSchedulesInput: PropertyUnitUpdateWithWhereUniqueWithoutTaskSchedulesInput;
  PropertyUnitUpdateWithoutPropertyInput: PropertyUnitUpdateWithoutPropertyInput;
  PropertyUnitUpdateWithoutTaskSchedulesInput: PropertyUnitUpdateWithoutTaskSchedulesInput;
  PropertyUnitUpdateWithoutTasksInput: PropertyUnitUpdateWithoutTasksInput;
  PropertyUnitUpsertWithWhereUniqueWithoutPropertyInput: PropertyUnitUpsertWithWhereUniqueWithoutPropertyInput;
  PropertyUnitUpsertWithWhereUniqueWithoutTaskSchedulesInput: PropertyUnitUpsertWithWhereUniqueWithoutTaskSchedulesInput;
  PropertyUnitUpsertWithoutTasksInput: PropertyUnitUpsertWithoutTasksInput;
  PropertyUnitWhereInput: PropertyUnitWhereInput;
  PropertyUnitWhereUniqueInput: PropertyUnitWhereUniqueInput;
  PropertyUpdateInput: PropertyUpdateInput;
  PropertyUpdateManyMutationInput: PropertyUpdateManyMutationInput;
  PropertyUpdateManyWithWhereWithoutOrganizationInput: PropertyUpdateManyWithWhereWithoutOrganizationInput;
  PropertyUpdateManyWithoutOrganizationNestedInput: PropertyUpdateManyWithoutOrganizationNestedInput;
  PropertyUpdateOneRequiredWithoutUnitsNestedInput: PropertyUpdateOneRequiredWithoutUnitsNestedInput;
  PropertyUpdateToOneWithWhereWithoutUnitsInput: PropertyUpdateToOneWithWhereWithoutUnitsInput;
  PropertyUpdateWithWhereUniqueWithoutOrganizationInput: PropertyUpdateWithWhereUniqueWithoutOrganizationInput;
  PropertyUpdateWithoutOrganizationInput: PropertyUpdateWithoutOrganizationInput;
  PropertyUpdateWithoutUnitsInput: PropertyUpdateWithoutUnitsInput;
  PropertyUpsertWithWhereUniqueWithoutOrganizationInput: PropertyUpsertWithWhereUniqueWithoutOrganizationInput;
  PropertyUpsertWithoutUnitsInput: PropertyUpsertWithoutUnitsInput;
  PropertyWhereInput: PropertyWhereInput;
  PropertyWhereUniqueInput: PropertyWhereUniqueInput;
  Query: ResolverTypeWrapper<{}>;
  QueryMode: QueryMode;
  SortOrder: SortOrder;
  SortOrderInput: SortOrderInput;
  StringFilter: StringFilter;
  StringListFilter: StringListFilter;
  Task: ResolverTypeWrapper<Task>;
  TaskAssignee: TaskAssignee;
  TaskCountAggregate: ResolverTypeWrapper<TaskCountAggregate>;
  TaskCountAggregateInput: TaskCountAggregateInput;
  TaskCreateManyApproverInput: TaskCreateManyApproverInput;
  TaskCreateManyApproverInputEnvelope: TaskCreateManyApproverInputEnvelope;
  TaskCreateManyCreatedByInput: TaskCreateManyCreatedByInput;
  TaskCreateManyCreatedByInputEnvelope: TaskCreateManyCreatedByInputEnvelope;
  TaskCreateManyCreatedByTaskScheduleInput: TaskCreateManyCreatedByTaskScheduleInput;
  TaskCreateManyCreatedByTaskScheduleInputEnvelope: TaskCreateManyCreatedByTaskScheduleInputEnvelope;
  TaskCreateManyInput: TaskCreateManyInput;
  TaskCreateManyUnitInput: TaskCreateManyUnitInput;
  TaskCreateManyUnitInputEnvelope: TaskCreateManyUnitInputEnvelope;
  TaskCreateNestedManyWithoutApproverInput: TaskCreateNestedManyWithoutApproverInput;
  TaskCreateNestedManyWithoutCreatedByInput: TaskCreateNestedManyWithoutCreatedByInput;
  TaskCreateNestedManyWithoutCreatedByTaskScheduleInput: TaskCreateNestedManyWithoutCreatedByTaskScheduleInput;
  TaskCreateNestedManyWithoutUnitInput: TaskCreateNestedManyWithoutUnitInput;
  TaskCreateOrConnectWithoutApproverInput: TaskCreateOrConnectWithoutApproverInput;
  TaskCreateOrConnectWithoutCreatedByInput: TaskCreateOrConnectWithoutCreatedByInput;
  TaskCreateOrConnectWithoutCreatedByTaskScheduleInput: TaskCreateOrConnectWithoutCreatedByTaskScheduleInput;
  TaskCreateOrConnectWithoutUnitInput: TaskCreateOrConnectWithoutUnitInput;
  TaskCreateWithoutApproverInput: TaskCreateWithoutApproverInput;
  TaskCreateWithoutCreatedByInput: TaskCreateWithoutCreatedByInput;
  TaskCreateWithoutCreatedByTaskScheduleInput: TaskCreateWithoutCreatedByTaskScheduleInput;
  TaskCreateWithoutUnitInput: TaskCreateWithoutUnitInput;
  TaskListRelationFilter: TaskListRelationFilter;
  TaskMaxAggregate: ResolverTypeWrapper<TaskMaxAggregate>;
  TaskMaxAggregateInput: TaskMaxAggregateInput;
  TaskMinAggregate: ResolverTypeWrapper<TaskMinAggregate>;
  TaskMinAggregateInput: TaskMinAggregateInput;
  TaskOrderByRelationAggregateInput: TaskOrderByRelationAggregateInput;
  TaskOrderByWithRelationInput: TaskOrderByWithRelationInput;
  TaskScalarFieldEnum: TaskScalarFieldEnum;
  TaskScalarWhereInput: TaskScalarWhereInput;
  TaskSchedule: ResolverTypeWrapper<TaskSchedule>;
  TaskScheduleCountAggregate: ResolverTypeWrapper<TaskScheduleCountAggregate>;
  TaskScheduleCreateInput: TaskScheduleCreateInput;
  TaskScheduleCreateManyApproverInput: TaskScheduleCreateManyApproverInput;
  TaskScheduleCreateManyApproverInputEnvelope: TaskScheduleCreateManyApproverInputEnvelope;
  TaskScheduleCreateManyCreatedByInput: TaskScheduleCreateManyCreatedByInput;
  TaskScheduleCreateManyCreatedByInputEnvelope: TaskScheduleCreateManyCreatedByInputEnvelope;
  TaskScheduleCreateManyOrganizationInput: TaskScheduleCreateManyOrganizationInput;
  TaskScheduleCreateManyOrganizationInputEnvelope: TaskScheduleCreateManyOrganizationInputEnvelope;
  TaskScheduleCreateNestedManyWithoutApproverInput: TaskScheduleCreateNestedManyWithoutApproverInput;
  TaskScheduleCreateNestedManyWithoutCreatedByInput: TaskScheduleCreateNestedManyWithoutCreatedByInput;
  TaskScheduleCreateNestedManyWithoutOrganizationInput: TaskScheduleCreateNestedManyWithoutOrganizationInput;
  TaskScheduleCreateNestedManyWithoutUnitsInput: TaskScheduleCreateNestedManyWithoutUnitsInput;
  TaskScheduleCreateNestedOneWithoutTasksInput: TaskScheduleCreateNestedOneWithoutTasksInput;
  TaskScheduleCreateOrConnectWithoutApproverInput: TaskScheduleCreateOrConnectWithoutApproverInput;
  TaskScheduleCreateOrConnectWithoutCreatedByInput: TaskScheduleCreateOrConnectWithoutCreatedByInput;
  TaskScheduleCreateOrConnectWithoutOrganizationInput: TaskScheduleCreateOrConnectWithoutOrganizationInput;
  TaskScheduleCreateOrConnectWithoutTasksInput: TaskScheduleCreateOrConnectWithoutTasksInput;
  TaskScheduleCreateOrConnectWithoutUnitsInput: TaskScheduleCreateOrConnectWithoutUnitsInput;
  TaskScheduleCreateWithoutApproverInput: TaskScheduleCreateWithoutApproverInput;
  TaskScheduleCreateWithoutCreatedByInput: TaskScheduleCreateWithoutCreatedByInput;
  TaskScheduleCreateWithoutOrganizationInput: TaskScheduleCreateWithoutOrganizationInput;
  TaskScheduleCreateWithoutTasksInput: TaskScheduleCreateWithoutTasksInput;
  TaskScheduleCreateWithoutUnitsInput: TaskScheduleCreateWithoutUnitsInput;
  TaskScheduleListRelationFilter: TaskScheduleListRelationFilter;
  TaskScheduleMaxAggregate: ResolverTypeWrapper<TaskScheduleMaxAggregate>;
  TaskScheduleMinAggregate: ResolverTypeWrapper<TaskScheduleMinAggregate>;
  TaskScheduleOrderByRelationAggregateInput: TaskScheduleOrderByRelationAggregateInput;
  TaskScheduleOrderByWithRelationInput: TaskScheduleOrderByWithRelationInput;
  TaskScheduleRelationFilter: TaskScheduleRelationFilter;
  TaskScheduleScalarFieldEnum: TaskScheduleScalarFieldEnum;
  TaskScheduleScalarWhereInput: TaskScheduleScalarWhereInput;
  TaskScheduleUpdateInput: TaskScheduleUpdateInput;
  TaskScheduleUpdateManyMutationInput: TaskScheduleUpdateManyMutationInput;
  TaskScheduleUpdateManyWithWhereWithoutApproverInput: TaskScheduleUpdateManyWithWhereWithoutApproverInput;
  TaskScheduleUpdateManyWithWhereWithoutCreatedByInput: TaskScheduleUpdateManyWithWhereWithoutCreatedByInput;
  TaskScheduleUpdateManyWithWhereWithoutOrganizationInput: TaskScheduleUpdateManyWithWhereWithoutOrganizationInput;
  TaskScheduleUpdateManyWithWhereWithoutUnitsInput: TaskScheduleUpdateManyWithWhereWithoutUnitsInput;
  TaskScheduleUpdateManyWithoutApproverNestedInput: TaskScheduleUpdateManyWithoutApproverNestedInput;
  TaskScheduleUpdateManyWithoutCreatedByNestedInput: TaskScheduleUpdateManyWithoutCreatedByNestedInput;
  TaskScheduleUpdateManyWithoutOrganizationNestedInput: TaskScheduleUpdateManyWithoutOrganizationNestedInput;
  TaskScheduleUpdateManyWithoutUnitsNestedInput: TaskScheduleUpdateManyWithoutUnitsNestedInput;
  TaskScheduleUpdateOneWithoutTasksNestedInput: TaskScheduleUpdateOneWithoutTasksNestedInput;
  TaskScheduleUpdateToOneWithWhereWithoutTasksInput: TaskScheduleUpdateToOneWithWhereWithoutTasksInput;
  TaskScheduleUpdateWithWhereUniqueWithoutApproverInput: TaskScheduleUpdateWithWhereUniqueWithoutApproverInput;
  TaskScheduleUpdateWithWhereUniqueWithoutCreatedByInput: TaskScheduleUpdateWithWhereUniqueWithoutCreatedByInput;
  TaskScheduleUpdateWithWhereUniqueWithoutOrganizationInput: TaskScheduleUpdateWithWhereUniqueWithoutOrganizationInput;
  TaskScheduleUpdateWithWhereUniqueWithoutUnitsInput: TaskScheduleUpdateWithWhereUniqueWithoutUnitsInput;
  TaskScheduleUpdateWithoutApproverInput: TaskScheduleUpdateWithoutApproverInput;
  TaskScheduleUpdateWithoutCreatedByInput: TaskScheduleUpdateWithoutCreatedByInput;
  TaskScheduleUpdateWithoutOrganizationInput: TaskScheduleUpdateWithoutOrganizationInput;
  TaskScheduleUpdateWithoutTasksInput: TaskScheduleUpdateWithoutTasksInput;
  TaskScheduleUpdateWithoutUnitsInput: TaskScheduleUpdateWithoutUnitsInput;
  TaskScheduleUpsertWithWhereUniqueWithoutApproverInput: TaskScheduleUpsertWithWhereUniqueWithoutApproverInput;
  TaskScheduleUpsertWithWhereUniqueWithoutCreatedByInput: TaskScheduleUpsertWithWhereUniqueWithoutCreatedByInput;
  TaskScheduleUpsertWithWhereUniqueWithoutOrganizationInput: TaskScheduleUpsertWithWhereUniqueWithoutOrganizationInput;
  TaskScheduleUpsertWithWhereUniqueWithoutUnitsInput: TaskScheduleUpsertWithWhereUniqueWithoutUnitsInput;
  TaskScheduleUpsertWithoutTasksInput: TaskScheduleUpsertWithoutTasksInput;
  TaskScheduleWhereInput: TaskScheduleWhereInput;
  TaskScheduleWhereUniqueInput: TaskScheduleWhereUniqueInput;
  TaskStatus: TaskStatus;
  TaskUpdateInput: TaskUpdateInput;
  TaskUpdateManyMutationInput: TaskUpdateManyMutationInput;
  TaskUpdateManyWithWhereWithoutApproverInput: TaskUpdateManyWithWhereWithoutApproverInput;
  TaskUpdateManyWithWhereWithoutCreatedByInput: TaskUpdateManyWithWhereWithoutCreatedByInput;
  TaskUpdateManyWithWhereWithoutCreatedByTaskScheduleInput: TaskUpdateManyWithWhereWithoutCreatedByTaskScheduleInput;
  TaskUpdateManyWithWhereWithoutUnitInput: TaskUpdateManyWithWhereWithoutUnitInput;
  TaskUpdateManyWithoutApproverNestedInput: TaskUpdateManyWithoutApproverNestedInput;
  TaskUpdateManyWithoutCreatedByNestedInput: TaskUpdateManyWithoutCreatedByNestedInput;
  TaskUpdateManyWithoutCreatedByTaskScheduleNestedInput: TaskUpdateManyWithoutCreatedByTaskScheduleNestedInput;
  TaskUpdateManyWithoutUnitNestedInput: TaskUpdateManyWithoutUnitNestedInput;
  TaskUpdateWithWhereUniqueWithoutApproverInput: TaskUpdateWithWhereUniqueWithoutApproverInput;
  TaskUpdateWithWhereUniqueWithoutCreatedByInput: TaskUpdateWithWhereUniqueWithoutCreatedByInput;
  TaskUpdateWithWhereUniqueWithoutCreatedByTaskScheduleInput: TaskUpdateWithWhereUniqueWithoutCreatedByTaskScheduleInput;
  TaskUpdateWithWhereUniqueWithoutUnitInput: TaskUpdateWithWhereUniqueWithoutUnitInput;
  TaskUpdateWithoutApproverInput: TaskUpdateWithoutApproverInput;
  TaskUpdateWithoutCreatedByInput: TaskUpdateWithoutCreatedByInput;
  TaskUpdateWithoutCreatedByTaskScheduleInput: TaskUpdateWithoutCreatedByTaskScheduleInput;
  TaskUpdateWithoutUnitInput: TaskUpdateWithoutUnitInput;
  TaskUpsertWithWhereUniqueWithoutApproverInput: TaskUpsertWithWhereUniqueWithoutApproverInput;
  TaskUpsertWithWhereUniqueWithoutCreatedByInput: TaskUpsertWithWhereUniqueWithoutCreatedByInput;
  TaskUpsertWithWhereUniqueWithoutCreatedByTaskScheduleInput: TaskUpsertWithWhereUniqueWithoutCreatedByTaskScheduleInput;
  TaskUpsertWithWhereUniqueWithoutUnitInput: TaskUpsertWithWhereUniqueWithoutUnitInput;
  TaskWhereInput: TaskWhereInput;
  TaskWhereUniqueInput: TaskWhereUniqueInput;
  Tenant: ResolverTypeWrapper<Tenant>;
  TenantCountAggregate: ResolverTypeWrapper<TenantCountAggregate>;
  TenantCreateInput: TenantCreateInput;
  TenantCreateManyPropertyUnitInput: TenantCreateManyPropertyUnitInput;
  TenantCreateManyPropertyUnitInputEnvelope: TenantCreateManyPropertyUnitInputEnvelope;
  TenantCreateNestedManyWithoutPropertyUnitInput: TenantCreateNestedManyWithoutPropertyUnitInput;
  TenantCreateOrConnectWithoutPropertyUnitInput: TenantCreateOrConnectWithoutPropertyUnitInput;
  TenantCreateWithoutPropertyUnitInput: TenantCreateWithoutPropertyUnitInput;
  TenantListRelationFilter: TenantListRelationFilter;
  TenantMaxAggregate: ResolverTypeWrapper<TenantMaxAggregate>;
  TenantMinAggregate: ResolverTypeWrapper<TenantMinAggregate>;
  TenantOrderByRelationAggregateInput: TenantOrderByRelationAggregateInput;
  TenantRelationFilter: TenantRelationFilter;
  TenantScalarWhereInput: TenantScalarWhereInput;
  TenantUpdateManyMutationInput: TenantUpdateManyMutationInput;
  TenantUpdateManyWithWhereWithoutPropertyUnitInput: TenantUpdateManyWithWhereWithoutPropertyUnitInput;
  TenantUpdateManyWithoutPropertyUnitNestedInput: TenantUpdateManyWithoutPropertyUnitNestedInput;
  TenantUpdateWithWhereUniqueWithoutPropertyUnitInput: TenantUpdateWithWhereUniqueWithoutPropertyUnitInput;
  TenantUpdateWithoutPropertyUnitInput: TenantUpdateWithoutPropertyUnitInput;
  TenantUpsertWithWhereUniqueWithoutPropertyUnitInput: TenantUpsertWithWhereUniqueWithoutPropertyUnitInput;
  TenantWhereInput: TenantWhereInput;
  TenantWhereUniqueInput: TenantWhereUniqueInput;
  User: ResolverTypeWrapper<User>;
  UserCountAggregate: ResolverTypeWrapper<UserCountAggregate>;
  UserCreateNestedOneWithoutApproverForTaskSchedulesInput: UserCreateNestedOneWithoutApproverForTaskSchedulesInput;
  UserCreateNestedOneWithoutApproverForTasksInput: UserCreateNestedOneWithoutApproverForTasksInput;
  UserCreateNestedOneWithoutCreatedTaskSchedulesInput: UserCreateNestedOneWithoutCreatedTaskSchedulesInput;
  UserCreateNestedOneWithoutCreatedTasksInput: UserCreateNestedOneWithoutCreatedTasksInput;
  UserCreateNestedOneWithoutOrganizationsInput: UserCreateNestedOneWithoutOrganizationsInput;
  UserCreateOrConnectWithoutApproverForTaskSchedulesInput: UserCreateOrConnectWithoutApproverForTaskSchedulesInput;
  UserCreateOrConnectWithoutApproverForTasksInput: UserCreateOrConnectWithoutApproverForTasksInput;
  UserCreateOrConnectWithoutCreatedTaskSchedulesInput: UserCreateOrConnectWithoutCreatedTaskSchedulesInput;
  UserCreateOrConnectWithoutCreatedTasksInput: UserCreateOrConnectWithoutCreatedTasksInput;
  UserCreateOrConnectWithoutOrganizationsInput: UserCreateOrConnectWithoutOrganizationsInput;
  UserCreateWithoutApproverForTaskSchedulesInput: UserCreateWithoutApproverForTaskSchedulesInput;
  UserCreateWithoutApproverForTasksInput: UserCreateWithoutApproverForTasksInput;
  UserCreateWithoutCreatedTaskSchedulesInput: UserCreateWithoutCreatedTaskSchedulesInput;
  UserCreateWithoutCreatedTasksInput: UserCreateWithoutCreatedTasksInput;
  UserCreateWithoutOrganizationsInput: UserCreateWithoutOrganizationsInput;
  UserMaxAggregate: ResolverTypeWrapper<UserMaxAggregate>;
  UserMinAggregate: ResolverTypeWrapper<UserMinAggregate>;
  UserOrderByWithRelationInput: UserOrderByWithRelationInput;
  UserRelationFilter: UserRelationFilter;
  UserSubscriptionDetails: ResolverTypeWrapper<UserSubscriptionDetails>;
  UserSubscriptionDetailsCreateNestedOneWithoutUserInput: UserSubscriptionDetailsCreateNestedOneWithoutUserInput;
  UserSubscriptionDetailsCreateOrConnectWithoutUserInput: UserSubscriptionDetailsCreateOrConnectWithoutUserInput;
  UserSubscriptionDetailsCreateWithoutUserInput: UserSubscriptionDetailsCreateWithoutUserInput;
  UserSubscriptionDetailsOrderByWithRelationInput: UserSubscriptionDetailsOrderByWithRelationInput;
  UserSubscriptionDetailsRelationFilter: UserSubscriptionDetailsRelationFilter;
  UserSubscriptionDetailsUpdateOneWithoutUserNestedInput: UserSubscriptionDetailsUpdateOneWithoutUserNestedInput;
  UserSubscriptionDetailsUpdateToOneWithWhereWithoutUserInput: UserSubscriptionDetailsUpdateToOneWithWhereWithoutUserInput;
  UserSubscriptionDetailsUpdateWithoutUserInput: UserSubscriptionDetailsUpdateWithoutUserInput;
  UserSubscriptionDetailsUpsertWithoutUserInput: UserSubscriptionDetailsUpsertWithoutUserInput;
  UserSubscriptionDetailsWhereInput: UserSubscriptionDetailsWhereInput;
  UserSubscriptionDetailsWhereUniqueInput: UserSubscriptionDetailsWhereUniqueInput;
  UserUpdateOneRequiredWithoutCreatedTaskSchedulesNestedInput: UserUpdateOneRequiredWithoutCreatedTaskSchedulesNestedInput;
  UserUpdateOneRequiredWithoutCreatedTasksNestedInput: UserUpdateOneRequiredWithoutCreatedTasksNestedInput;
  UserUpdateOneRequiredWithoutOrganizationsNestedInput: UserUpdateOneRequiredWithoutOrganizationsNestedInput;
  UserUpdateOneWithoutApproverForTaskSchedulesNestedInput: UserUpdateOneWithoutApproverForTaskSchedulesNestedInput;
  UserUpdateOneWithoutApproverForTasksNestedInput: UserUpdateOneWithoutApproverForTasksNestedInput;
  UserUpdateToOneWithWhereWithoutApproverForTaskSchedulesInput: UserUpdateToOneWithWhereWithoutApproverForTaskSchedulesInput;
  UserUpdateToOneWithWhereWithoutApproverForTasksInput: UserUpdateToOneWithWhereWithoutApproverForTasksInput;
  UserUpdateToOneWithWhereWithoutCreatedTaskSchedulesInput: UserUpdateToOneWithWhereWithoutCreatedTaskSchedulesInput;
  UserUpdateToOneWithWhereWithoutCreatedTasksInput: UserUpdateToOneWithWhereWithoutCreatedTasksInput;
  UserUpdateToOneWithWhereWithoutOrganizationsInput: UserUpdateToOneWithWhereWithoutOrganizationsInput;
  UserUpdateWithoutApproverForTaskSchedulesInput: UserUpdateWithoutApproverForTaskSchedulesInput;
  UserUpdateWithoutApproverForTasksInput: UserUpdateWithoutApproverForTasksInput;
  UserUpdateWithoutCreatedTaskSchedulesInput: UserUpdateWithoutCreatedTaskSchedulesInput;
  UserUpdateWithoutCreatedTasksInput: UserUpdateWithoutCreatedTasksInput;
  UserUpdateWithoutOrganizationsInput: UserUpdateWithoutOrganizationsInput;
  UserUpsertWithoutApproverForTaskSchedulesInput: UserUpsertWithoutApproverForTaskSchedulesInput;
  UserUpsertWithoutApproverForTasksInput: UserUpsertWithoutApproverForTasksInput;
  UserUpsertWithoutCreatedTaskSchedulesInput: UserUpsertWithoutCreatedTaskSchedulesInput;
  UserUpsertWithoutCreatedTasksInput: UserUpsertWithoutCreatedTasksInput;
  UserUpsertWithoutOrganizationsInput: UserUpsertWithoutOrganizationsInput;
  UserWhereInput: UserWhereInput;
  UserWhereUniqueInput: UserWhereUniqueInput;
  UsersInOrganizations: ResolverTypeWrapper<UsersInOrganizations>;
  UsersInOrganizationsCreateManyOrganizationInput: UsersInOrganizationsCreateManyOrganizationInput;
  UsersInOrganizationsCreateManyOrganizationInputEnvelope: UsersInOrganizationsCreateManyOrganizationInputEnvelope;
  UsersInOrganizationsCreateManyUserInput: UsersInOrganizationsCreateManyUserInput;
  UsersInOrganizationsCreateManyUserInputEnvelope: UsersInOrganizationsCreateManyUserInputEnvelope;
  UsersInOrganizationsCreateNestedManyWithoutOrganizationInput: UsersInOrganizationsCreateNestedManyWithoutOrganizationInput;
  UsersInOrganizationsCreateNestedManyWithoutUserInput: UsersInOrganizationsCreateNestedManyWithoutUserInput;
  UsersInOrganizationsCreateOrConnectWithoutOrganizationInput: UsersInOrganizationsCreateOrConnectWithoutOrganizationInput;
  UsersInOrganizationsCreateOrConnectWithoutUserInput: UsersInOrganizationsCreateOrConnectWithoutUserInput;
  UsersInOrganizationsCreateWithoutOrganizationInput: UsersInOrganizationsCreateWithoutOrganizationInput;
  UsersInOrganizationsCreateWithoutUserInput: UsersInOrganizationsCreateWithoutUserInput;
  UsersInOrganizationsListRelationFilter: UsersInOrganizationsListRelationFilter;
  UsersInOrganizationsOrderByRelationAggregateInput: UsersInOrganizationsOrderByRelationAggregateInput;
  UsersInOrganizationsScalarWhereInput: UsersInOrganizationsScalarWhereInput;
  UsersInOrganizationsUpdateManyMutationInput: UsersInOrganizationsUpdateManyMutationInput;
  UsersInOrganizationsUpdateManyWithWhereWithoutOrganizationInput: UsersInOrganizationsUpdateManyWithWhereWithoutOrganizationInput;
  UsersInOrganizationsUpdateManyWithWhereWithoutUserInput: UsersInOrganizationsUpdateManyWithWhereWithoutUserInput;
  UsersInOrganizationsUpdateManyWithoutOrganizationNestedInput: UsersInOrganizationsUpdateManyWithoutOrganizationNestedInput;
  UsersInOrganizationsUpdateManyWithoutUserNestedInput: UsersInOrganizationsUpdateManyWithoutUserNestedInput;
  UsersInOrganizationsUpdateWithWhereUniqueWithoutOrganizationInput: UsersInOrganizationsUpdateWithWhereUniqueWithoutOrganizationInput;
  UsersInOrganizationsUpdateWithWhereUniqueWithoutUserInput: UsersInOrganizationsUpdateWithWhereUniqueWithoutUserInput;
  UsersInOrganizationsUpdateWithoutOrganizationInput: UsersInOrganizationsUpdateWithoutOrganizationInput;
  UsersInOrganizationsUpdateWithoutUserInput: UsersInOrganizationsUpdateWithoutUserInput;
  UsersInOrganizationsUpsertWithWhereUniqueWithoutOrganizationInput: UsersInOrganizationsUpsertWithWhereUniqueWithoutOrganizationInput;
  UsersInOrganizationsUpsertWithWhereUniqueWithoutUserInput: UsersInOrganizationsUpsertWithWhereUniqueWithoutUserInput;
  UsersInOrganizationsWhereInput: UsersInOrganizationsWhereInput;
  UsersInOrganizationsWhereUniqueInput: UsersInOrganizationsWhereUniqueInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AggregateTask: AggregateTask;
  BoolFilter: BoolFilter;
  Boolean: Scalars['Boolean']['output'];
  BytesFilter: BytesFilter;
  String: Scalars['String']['output'];
  DateTime: Scalars['DateTime']['output'];
  DateTimeFilter: DateTimeFilter;
  EnumTaskAssigneeFilter: EnumTaskAssigneeFilter;
  EnumTaskStatusFilter: EnumTaskStatusFilter;
  JSON: Scalars['JSON']['output'];
  JsonFilter: JsonFilter;
  MessageCode: MessageCode;
  ID: Scalars['ID']['output'];
  MessageCodeCreateManyTenantInput: MessageCodeCreateManyTenantInput;
  MessageCodeCreateManyTenantInputEnvelope: MessageCodeCreateManyTenantInputEnvelope;
  MessageCodeCreateNestedManyWithoutTenantInput: MessageCodeCreateNestedManyWithoutTenantInput;
  MessageCodeCreateOrConnectWithoutTenantInput: MessageCodeCreateOrConnectWithoutTenantInput;
  MessageCodeCreateWithoutTenantInput: MessageCodeCreateWithoutTenantInput;
  MessageCodeListRelationFilter: MessageCodeListRelationFilter;
  MessageCodeScalarWhereInput: MessageCodeScalarWhereInput;
  MessageCodeUpdateManyMutationInput: MessageCodeUpdateManyMutationInput;
  MessageCodeUpdateManyWithWhereWithoutTenantInput: MessageCodeUpdateManyWithWhereWithoutTenantInput;
  MessageCodeUpdateManyWithoutTenantNestedInput: MessageCodeUpdateManyWithoutTenantNestedInput;
  MessageCodeUpdateWithWhereUniqueWithoutTenantInput: MessageCodeUpdateWithWhereUniqueWithoutTenantInput;
  MessageCodeUpdateWithoutTenantInput: MessageCodeUpdateWithoutTenantInput;
  MessageCodeUpsertWithWhereUniqueWithoutTenantInput: MessageCodeUpsertWithWhereUniqueWithoutTenantInput;
  MessageCodeWhereInput: MessageCodeWhereInput;
  MessageCodeWhereUniqueInput: MessageCodeWhereUniqueInput;
  Mutation: {};
  Organization: Organization;
  OrganizationCountAggregate: OrganizationCountAggregate;
  Int: Scalars['Int']['output'];
  OrganizationCreateNestedOneWithoutMembersInput: OrganizationCreateNestedOneWithoutMembersInput;
  OrganizationCreateNestedOneWithoutPropertiesInput: OrganizationCreateNestedOneWithoutPropertiesInput;
  OrganizationCreateNestedOneWithoutTaskSchedulesInput: OrganizationCreateNestedOneWithoutTaskSchedulesInput;
  OrganizationCreateOrConnectWithoutMembersInput: OrganizationCreateOrConnectWithoutMembersInput;
  OrganizationCreateOrConnectWithoutPropertiesInput: OrganizationCreateOrConnectWithoutPropertiesInput;
  OrganizationCreateOrConnectWithoutTaskSchedulesInput: OrganizationCreateOrConnectWithoutTaskSchedulesInput;
  OrganizationCreateWithoutMembersInput: OrganizationCreateWithoutMembersInput;
  OrganizationCreateWithoutPropertiesInput: OrganizationCreateWithoutPropertiesInput;
  OrganizationCreateWithoutTaskSchedulesInput: OrganizationCreateWithoutTaskSchedulesInput;
  OrganizationMaxAggregate: OrganizationMaxAggregate;
  OrganizationMinAggregate: OrganizationMinAggregate;
  OrganizationOrderByWithRelationInput: OrganizationOrderByWithRelationInput;
  OrganizationRelationFilter: OrganizationRelationFilter;
  OrganizationUpdateInput: OrganizationUpdateInput;
  OrganizationUpdateOneRequiredWithoutMembersNestedInput: OrganizationUpdateOneRequiredWithoutMembersNestedInput;
  OrganizationUpdateOneRequiredWithoutPropertiesNestedInput: OrganizationUpdateOneRequiredWithoutPropertiesNestedInput;
  OrganizationUpdateOneRequiredWithoutTaskSchedulesNestedInput: OrganizationUpdateOneRequiredWithoutTaskSchedulesNestedInput;
  OrganizationUpdateToOneWithWhereWithoutMembersInput: OrganizationUpdateToOneWithWhereWithoutMembersInput;
  OrganizationUpdateToOneWithWhereWithoutPropertiesInput: OrganizationUpdateToOneWithWhereWithoutPropertiesInput;
  OrganizationUpdateToOneWithWhereWithoutTaskSchedulesInput: OrganizationUpdateToOneWithWhereWithoutTaskSchedulesInput;
  OrganizationUpdateWithoutMembersInput: OrganizationUpdateWithoutMembersInput;
  OrganizationUpdateWithoutPropertiesInput: OrganizationUpdateWithoutPropertiesInput;
  OrganizationUpdateWithoutTaskSchedulesInput: OrganizationUpdateWithoutTaskSchedulesInput;
  OrganizationUpsertWithoutMembersInput: OrganizationUpsertWithoutMembersInput;
  OrganizationUpsertWithoutPropertiesInput: OrganizationUpsertWithoutPropertiesInput;
  OrganizationUpsertWithoutTaskSchedulesInput: OrganizationUpsertWithoutTaskSchedulesInput;
  OrganizationWhereInput: OrganizationWhereInput;
  OrganizationWhereUniqueInput: OrganizationWhereUniqueInput;
  Property: Property;
  PropertyCountAggregate: PropertyCountAggregate;
  PropertyCreateInput: PropertyCreateInput;
  PropertyCreateManyOrganizationInput: PropertyCreateManyOrganizationInput;
  PropertyCreateManyOrganizationInputEnvelope: PropertyCreateManyOrganizationInputEnvelope;
  PropertyCreateNestedManyWithoutOrganizationInput: PropertyCreateNestedManyWithoutOrganizationInput;
  PropertyCreateNestedOneWithoutUnitsInput: PropertyCreateNestedOneWithoutUnitsInput;
  PropertyCreateOrConnectWithoutOrganizationInput: PropertyCreateOrConnectWithoutOrganizationInput;
  PropertyCreateOrConnectWithoutUnitsInput: PropertyCreateOrConnectWithoutUnitsInput;
  PropertyCreateWithoutOrganizationInput: PropertyCreateWithoutOrganizationInput;
  PropertyCreateWithoutUnitsInput: PropertyCreateWithoutUnitsInput;
  PropertyListRelationFilter: PropertyListRelationFilter;
  PropertyMaxAggregate: PropertyMaxAggregate;
  PropertyMinAggregate: PropertyMinAggregate;
  PropertyOrderByRelationAggregateInput: PropertyOrderByRelationAggregateInput;
  PropertyOrderByWithRelationInput: PropertyOrderByWithRelationInput;
  PropertyRelationFilter: PropertyRelationFilter;
  PropertyScalarWhereInput: PropertyScalarWhereInput;
  PropertyUnit: PropertyUnit;
  PropertyUnitCountAggregate: PropertyUnitCountAggregate;
  PropertyUnitCreateInput: PropertyUnitCreateInput;
  PropertyUnitCreateManyPropertyInput: PropertyUnitCreateManyPropertyInput;
  PropertyUnitCreateManyPropertyInputEnvelope: PropertyUnitCreateManyPropertyInputEnvelope;
  PropertyUnitCreateNestedManyWithoutPropertyInput: PropertyUnitCreateNestedManyWithoutPropertyInput;
  PropertyUnitCreateNestedManyWithoutTaskSchedulesInput: PropertyUnitCreateNestedManyWithoutTaskSchedulesInput;
  PropertyUnitCreateNestedOneWithoutTasksInput: PropertyUnitCreateNestedOneWithoutTasksInput;
  PropertyUnitCreateNestedOneWithoutTenantsInput: PropertyUnitCreateNestedOneWithoutTenantsInput;
  PropertyUnitCreateOrConnectWithoutPropertyInput: PropertyUnitCreateOrConnectWithoutPropertyInput;
  PropertyUnitCreateOrConnectWithoutTaskSchedulesInput: PropertyUnitCreateOrConnectWithoutTaskSchedulesInput;
  PropertyUnitCreateOrConnectWithoutTasksInput: PropertyUnitCreateOrConnectWithoutTasksInput;
  PropertyUnitCreateOrConnectWithoutTenantsInput: PropertyUnitCreateOrConnectWithoutTenantsInput;
  PropertyUnitCreateWithoutPropertyInput: PropertyUnitCreateWithoutPropertyInput;
  PropertyUnitCreateWithoutTaskSchedulesInput: PropertyUnitCreateWithoutTaskSchedulesInput;
  PropertyUnitCreateWithoutTasksInput: PropertyUnitCreateWithoutTasksInput;
  PropertyUnitCreateWithoutTenantsInput: PropertyUnitCreateWithoutTenantsInput;
  PropertyUnitListRelationFilter: PropertyUnitListRelationFilter;
  PropertyUnitMaxAggregate: PropertyUnitMaxAggregate;
  PropertyUnitMinAggregate: PropertyUnitMinAggregate;
  PropertyUnitOrderByRelationAggregateInput: PropertyUnitOrderByRelationAggregateInput;
  PropertyUnitOrderByWithRelationInput: PropertyUnitOrderByWithRelationInput;
  PropertyUnitRelationFilter: PropertyUnitRelationFilter;
  PropertyUnitScalarWhereInput: PropertyUnitScalarWhereInput;
  PropertyUnitUpdateInput: PropertyUnitUpdateInput;
  PropertyUnitUpdateManyMutationInput: PropertyUnitUpdateManyMutationInput;
  PropertyUnitUpdateManyWithWhereWithoutPropertyInput: PropertyUnitUpdateManyWithWhereWithoutPropertyInput;
  PropertyUnitUpdateManyWithWhereWithoutTaskSchedulesInput: PropertyUnitUpdateManyWithWhereWithoutTaskSchedulesInput;
  PropertyUnitUpdateManyWithoutPropertyNestedInput: PropertyUnitUpdateManyWithoutPropertyNestedInput;
  PropertyUnitUpdateManyWithoutTaskSchedulesNestedInput: PropertyUnitUpdateManyWithoutTaskSchedulesNestedInput;
  PropertyUnitUpdateOneRequiredWithoutTasksNestedInput: PropertyUnitUpdateOneRequiredWithoutTasksNestedInput;
  PropertyUnitUpdateToOneWithWhereWithoutTasksInput: PropertyUnitUpdateToOneWithWhereWithoutTasksInput;
  PropertyUnitUpdateWithWhereUniqueWithoutPropertyInput: PropertyUnitUpdateWithWhereUniqueWithoutPropertyInput;
  PropertyUnitUpdateWithWhereUniqueWithoutTaskSchedulesInput: PropertyUnitUpdateWithWhereUniqueWithoutTaskSchedulesInput;
  PropertyUnitUpdateWithoutPropertyInput: PropertyUnitUpdateWithoutPropertyInput;
  PropertyUnitUpdateWithoutTaskSchedulesInput: PropertyUnitUpdateWithoutTaskSchedulesInput;
  PropertyUnitUpdateWithoutTasksInput: PropertyUnitUpdateWithoutTasksInput;
  PropertyUnitUpsertWithWhereUniqueWithoutPropertyInput: PropertyUnitUpsertWithWhereUniqueWithoutPropertyInput;
  PropertyUnitUpsertWithWhereUniqueWithoutTaskSchedulesInput: PropertyUnitUpsertWithWhereUniqueWithoutTaskSchedulesInput;
  PropertyUnitUpsertWithoutTasksInput: PropertyUnitUpsertWithoutTasksInput;
  PropertyUnitWhereInput: PropertyUnitWhereInput;
  PropertyUnitWhereUniqueInput: PropertyUnitWhereUniqueInput;
  PropertyUpdateInput: PropertyUpdateInput;
  PropertyUpdateManyMutationInput: PropertyUpdateManyMutationInput;
  PropertyUpdateManyWithWhereWithoutOrganizationInput: PropertyUpdateManyWithWhereWithoutOrganizationInput;
  PropertyUpdateManyWithoutOrganizationNestedInput: PropertyUpdateManyWithoutOrganizationNestedInput;
  PropertyUpdateOneRequiredWithoutUnitsNestedInput: PropertyUpdateOneRequiredWithoutUnitsNestedInput;
  PropertyUpdateToOneWithWhereWithoutUnitsInput: PropertyUpdateToOneWithWhereWithoutUnitsInput;
  PropertyUpdateWithWhereUniqueWithoutOrganizationInput: PropertyUpdateWithWhereUniqueWithoutOrganizationInput;
  PropertyUpdateWithoutOrganizationInput: PropertyUpdateWithoutOrganizationInput;
  PropertyUpdateWithoutUnitsInput: PropertyUpdateWithoutUnitsInput;
  PropertyUpsertWithWhereUniqueWithoutOrganizationInput: PropertyUpsertWithWhereUniqueWithoutOrganizationInput;
  PropertyUpsertWithoutUnitsInput: PropertyUpsertWithoutUnitsInput;
  PropertyWhereInput: PropertyWhereInput;
  PropertyWhereUniqueInput: PropertyWhereUniqueInput;
  Query: {};
  SortOrderInput: SortOrderInput;
  StringFilter: StringFilter;
  StringListFilter: StringListFilter;
  Task: Task;
  TaskCountAggregate: TaskCountAggregate;
  TaskCountAggregateInput: TaskCountAggregateInput;
  TaskCreateManyApproverInput: TaskCreateManyApproverInput;
  TaskCreateManyApproverInputEnvelope: TaskCreateManyApproverInputEnvelope;
  TaskCreateManyCreatedByInput: TaskCreateManyCreatedByInput;
  TaskCreateManyCreatedByInputEnvelope: TaskCreateManyCreatedByInputEnvelope;
  TaskCreateManyCreatedByTaskScheduleInput: TaskCreateManyCreatedByTaskScheduleInput;
  TaskCreateManyCreatedByTaskScheduleInputEnvelope: TaskCreateManyCreatedByTaskScheduleInputEnvelope;
  TaskCreateManyInput: TaskCreateManyInput;
  TaskCreateManyUnitInput: TaskCreateManyUnitInput;
  TaskCreateManyUnitInputEnvelope: TaskCreateManyUnitInputEnvelope;
  TaskCreateNestedManyWithoutApproverInput: TaskCreateNestedManyWithoutApproverInput;
  TaskCreateNestedManyWithoutCreatedByInput: TaskCreateNestedManyWithoutCreatedByInput;
  TaskCreateNestedManyWithoutCreatedByTaskScheduleInput: TaskCreateNestedManyWithoutCreatedByTaskScheduleInput;
  TaskCreateNestedManyWithoutUnitInput: TaskCreateNestedManyWithoutUnitInput;
  TaskCreateOrConnectWithoutApproverInput: TaskCreateOrConnectWithoutApproverInput;
  TaskCreateOrConnectWithoutCreatedByInput: TaskCreateOrConnectWithoutCreatedByInput;
  TaskCreateOrConnectWithoutCreatedByTaskScheduleInput: TaskCreateOrConnectWithoutCreatedByTaskScheduleInput;
  TaskCreateOrConnectWithoutUnitInput: TaskCreateOrConnectWithoutUnitInput;
  TaskCreateWithoutApproverInput: TaskCreateWithoutApproverInput;
  TaskCreateWithoutCreatedByInput: TaskCreateWithoutCreatedByInput;
  TaskCreateWithoutCreatedByTaskScheduleInput: TaskCreateWithoutCreatedByTaskScheduleInput;
  TaskCreateWithoutUnitInput: TaskCreateWithoutUnitInput;
  TaskListRelationFilter: TaskListRelationFilter;
  TaskMaxAggregate: TaskMaxAggregate;
  TaskMaxAggregateInput: TaskMaxAggregateInput;
  TaskMinAggregate: TaskMinAggregate;
  TaskMinAggregateInput: TaskMinAggregateInput;
  TaskOrderByRelationAggregateInput: TaskOrderByRelationAggregateInput;
  TaskOrderByWithRelationInput: TaskOrderByWithRelationInput;
  TaskScalarWhereInput: TaskScalarWhereInput;
  TaskSchedule: TaskSchedule;
  TaskScheduleCountAggregate: TaskScheduleCountAggregate;
  TaskScheduleCreateInput: TaskScheduleCreateInput;
  TaskScheduleCreateManyApproverInput: TaskScheduleCreateManyApproverInput;
  TaskScheduleCreateManyApproverInputEnvelope: TaskScheduleCreateManyApproverInputEnvelope;
  TaskScheduleCreateManyCreatedByInput: TaskScheduleCreateManyCreatedByInput;
  TaskScheduleCreateManyCreatedByInputEnvelope: TaskScheduleCreateManyCreatedByInputEnvelope;
  TaskScheduleCreateManyOrganizationInput: TaskScheduleCreateManyOrganizationInput;
  TaskScheduleCreateManyOrganizationInputEnvelope: TaskScheduleCreateManyOrganizationInputEnvelope;
  TaskScheduleCreateNestedManyWithoutApproverInput: TaskScheduleCreateNestedManyWithoutApproverInput;
  TaskScheduleCreateNestedManyWithoutCreatedByInput: TaskScheduleCreateNestedManyWithoutCreatedByInput;
  TaskScheduleCreateNestedManyWithoutOrganizationInput: TaskScheduleCreateNestedManyWithoutOrganizationInput;
  TaskScheduleCreateNestedManyWithoutUnitsInput: TaskScheduleCreateNestedManyWithoutUnitsInput;
  TaskScheduleCreateNestedOneWithoutTasksInput: TaskScheduleCreateNestedOneWithoutTasksInput;
  TaskScheduleCreateOrConnectWithoutApproverInput: TaskScheduleCreateOrConnectWithoutApproverInput;
  TaskScheduleCreateOrConnectWithoutCreatedByInput: TaskScheduleCreateOrConnectWithoutCreatedByInput;
  TaskScheduleCreateOrConnectWithoutOrganizationInput: TaskScheduleCreateOrConnectWithoutOrganizationInput;
  TaskScheduleCreateOrConnectWithoutTasksInput: TaskScheduleCreateOrConnectWithoutTasksInput;
  TaskScheduleCreateOrConnectWithoutUnitsInput: TaskScheduleCreateOrConnectWithoutUnitsInput;
  TaskScheduleCreateWithoutApproverInput: TaskScheduleCreateWithoutApproverInput;
  TaskScheduleCreateWithoutCreatedByInput: TaskScheduleCreateWithoutCreatedByInput;
  TaskScheduleCreateWithoutOrganizationInput: TaskScheduleCreateWithoutOrganizationInput;
  TaskScheduleCreateWithoutTasksInput: TaskScheduleCreateWithoutTasksInput;
  TaskScheduleCreateWithoutUnitsInput: TaskScheduleCreateWithoutUnitsInput;
  TaskScheduleListRelationFilter: TaskScheduleListRelationFilter;
  TaskScheduleMaxAggregate: TaskScheduleMaxAggregate;
  TaskScheduleMinAggregate: TaskScheduleMinAggregate;
  TaskScheduleOrderByRelationAggregateInput: TaskScheduleOrderByRelationAggregateInput;
  TaskScheduleOrderByWithRelationInput: TaskScheduleOrderByWithRelationInput;
  TaskScheduleRelationFilter: TaskScheduleRelationFilter;
  TaskScheduleScalarWhereInput: TaskScheduleScalarWhereInput;
  TaskScheduleUpdateInput: TaskScheduleUpdateInput;
  TaskScheduleUpdateManyMutationInput: TaskScheduleUpdateManyMutationInput;
  TaskScheduleUpdateManyWithWhereWithoutApproverInput: TaskScheduleUpdateManyWithWhereWithoutApproverInput;
  TaskScheduleUpdateManyWithWhereWithoutCreatedByInput: TaskScheduleUpdateManyWithWhereWithoutCreatedByInput;
  TaskScheduleUpdateManyWithWhereWithoutOrganizationInput: TaskScheduleUpdateManyWithWhereWithoutOrganizationInput;
  TaskScheduleUpdateManyWithWhereWithoutUnitsInput: TaskScheduleUpdateManyWithWhereWithoutUnitsInput;
  TaskScheduleUpdateManyWithoutApproverNestedInput: TaskScheduleUpdateManyWithoutApproverNestedInput;
  TaskScheduleUpdateManyWithoutCreatedByNestedInput: TaskScheduleUpdateManyWithoutCreatedByNestedInput;
  TaskScheduleUpdateManyWithoutOrganizationNestedInput: TaskScheduleUpdateManyWithoutOrganizationNestedInput;
  TaskScheduleUpdateManyWithoutUnitsNestedInput: TaskScheduleUpdateManyWithoutUnitsNestedInput;
  TaskScheduleUpdateOneWithoutTasksNestedInput: TaskScheduleUpdateOneWithoutTasksNestedInput;
  TaskScheduleUpdateToOneWithWhereWithoutTasksInput: TaskScheduleUpdateToOneWithWhereWithoutTasksInput;
  TaskScheduleUpdateWithWhereUniqueWithoutApproverInput: TaskScheduleUpdateWithWhereUniqueWithoutApproverInput;
  TaskScheduleUpdateWithWhereUniqueWithoutCreatedByInput: TaskScheduleUpdateWithWhereUniqueWithoutCreatedByInput;
  TaskScheduleUpdateWithWhereUniqueWithoutOrganizationInput: TaskScheduleUpdateWithWhereUniqueWithoutOrganizationInput;
  TaskScheduleUpdateWithWhereUniqueWithoutUnitsInput: TaskScheduleUpdateWithWhereUniqueWithoutUnitsInput;
  TaskScheduleUpdateWithoutApproverInput: TaskScheduleUpdateWithoutApproverInput;
  TaskScheduleUpdateWithoutCreatedByInput: TaskScheduleUpdateWithoutCreatedByInput;
  TaskScheduleUpdateWithoutOrganizationInput: TaskScheduleUpdateWithoutOrganizationInput;
  TaskScheduleUpdateWithoutTasksInput: TaskScheduleUpdateWithoutTasksInput;
  TaskScheduleUpdateWithoutUnitsInput: TaskScheduleUpdateWithoutUnitsInput;
  TaskScheduleUpsertWithWhereUniqueWithoutApproverInput: TaskScheduleUpsertWithWhereUniqueWithoutApproverInput;
  TaskScheduleUpsertWithWhereUniqueWithoutCreatedByInput: TaskScheduleUpsertWithWhereUniqueWithoutCreatedByInput;
  TaskScheduleUpsertWithWhereUniqueWithoutOrganizationInput: TaskScheduleUpsertWithWhereUniqueWithoutOrganizationInput;
  TaskScheduleUpsertWithWhereUniqueWithoutUnitsInput: TaskScheduleUpsertWithWhereUniqueWithoutUnitsInput;
  TaskScheduleUpsertWithoutTasksInput: TaskScheduleUpsertWithoutTasksInput;
  TaskScheduleWhereInput: TaskScheduleWhereInput;
  TaskScheduleWhereUniqueInput: TaskScheduleWhereUniqueInput;
  TaskUpdateInput: TaskUpdateInput;
  TaskUpdateManyMutationInput: TaskUpdateManyMutationInput;
  TaskUpdateManyWithWhereWithoutApproverInput: TaskUpdateManyWithWhereWithoutApproverInput;
  TaskUpdateManyWithWhereWithoutCreatedByInput: TaskUpdateManyWithWhereWithoutCreatedByInput;
  TaskUpdateManyWithWhereWithoutCreatedByTaskScheduleInput: TaskUpdateManyWithWhereWithoutCreatedByTaskScheduleInput;
  TaskUpdateManyWithWhereWithoutUnitInput: TaskUpdateManyWithWhereWithoutUnitInput;
  TaskUpdateManyWithoutApproverNestedInput: TaskUpdateManyWithoutApproverNestedInput;
  TaskUpdateManyWithoutCreatedByNestedInput: TaskUpdateManyWithoutCreatedByNestedInput;
  TaskUpdateManyWithoutCreatedByTaskScheduleNestedInput: TaskUpdateManyWithoutCreatedByTaskScheduleNestedInput;
  TaskUpdateManyWithoutUnitNestedInput: TaskUpdateManyWithoutUnitNestedInput;
  TaskUpdateWithWhereUniqueWithoutApproverInput: TaskUpdateWithWhereUniqueWithoutApproverInput;
  TaskUpdateWithWhereUniqueWithoutCreatedByInput: TaskUpdateWithWhereUniqueWithoutCreatedByInput;
  TaskUpdateWithWhereUniqueWithoutCreatedByTaskScheduleInput: TaskUpdateWithWhereUniqueWithoutCreatedByTaskScheduleInput;
  TaskUpdateWithWhereUniqueWithoutUnitInput: TaskUpdateWithWhereUniqueWithoutUnitInput;
  TaskUpdateWithoutApproverInput: TaskUpdateWithoutApproverInput;
  TaskUpdateWithoutCreatedByInput: TaskUpdateWithoutCreatedByInput;
  TaskUpdateWithoutCreatedByTaskScheduleInput: TaskUpdateWithoutCreatedByTaskScheduleInput;
  TaskUpdateWithoutUnitInput: TaskUpdateWithoutUnitInput;
  TaskUpsertWithWhereUniqueWithoutApproverInput: TaskUpsertWithWhereUniqueWithoutApproverInput;
  TaskUpsertWithWhereUniqueWithoutCreatedByInput: TaskUpsertWithWhereUniqueWithoutCreatedByInput;
  TaskUpsertWithWhereUniqueWithoutCreatedByTaskScheduleInput: TaskUpsertWithWhereUniqueWithoutCreatedByTaskScheduleInput;
  TaskUpsertWithWhereUniqueWithoutUnitInput: TaskUpsertWithWhereUniqueWithoutUnitInput;
  TaskWhereInput: TaskWhereInput;
  TaskWhereUniqueInput: TaskWhereUniqueInput;
  Tenant: Tenant;
  TenantCountAggregate: TenantCountAggregate;
  TenantCreateInput: TenantCreateInput;
  TenantCreateManyPropertyUnitInput: TenantCreateManyPropertyUnitInput;
  TenantCreateManyPropertyUnitInputEnvelope: TenantCreateManyPropertyUnitInputEnvelope;
  TenantCreateNestedManyWithoutPropertyUnitInput: TenantCreateNestedManyWithoutPropertyUnitInput;
  TenantCreateOrConnectWithoutPropertyUnitInput: TenantCreateOrConnectWithoutPropertyUnitInput;
  TenantCreateWithoutPropertyUnitInput: TenantCreateWithoutPropertyUnitInput;
  TenantListRelationFilter: TenantListRelationFilter;
  TenantMaxAggregate: TenantMaxAggregate;
  TenantMinAggregate: TenantMinAggregate;
  TenantOrderByRelationAggregateInput: TenantOrderByRelationAggregateInput;
  TenantRelationFilter: TenantRelationFilter;
  TenantScalarWhereInput: TenantScalarWhereInput;
  TenantUpdateManyMutationInput: TenantUpdateManyMutationInput;
  TenantUpdateManyWithWhereWithoutPropertyUnitInput: TenantUpdateManyWithWhereWithoutPropertyUnitInput;
  TenantUpdateManyWithoutPropertyUnitNestedInput: TenantUpdateManyWithoutPropertyUnitNestedInput;
  TenantUpdateWithWhereUniqueWithoutPropertyUnitInput: TenantUpdateWithWhereUniqueWithoutPropertyUnitInput;
  TenantUpdateWithoutPropertyUnitInput: TenantUpdateWithoutPropertyUnitInput;
  TenantUpsertWithWhereUniqueWithoutPropertyUnitInput: TenantUpsertWithWhereUniqueWithoutPropertyUnitInput;
  TenantWhereInput: TenantWhereInput;
  TenantWhereUniqueInput: TenantWhereUniqueInput;
  User: User;
  UserCountAggregate: UserCountAggregate;
  UserCreateNestedOneWithoutApproverForTaskSchedulesInput: UserCreateNestedOneWithoutApproverForTaskSchedulesInput;
  UserCreateNestedOneWithoutApproverForTasksInput: UserCreateNestedOneWithoutApproverForTasksInput;
  UserCreateNestedOneWithoutCreatedTaskSchedulesInput: UserCreateNestedOneWithoutCreatedTaskSchedulesInput;
  UserCreateNestedOneWithoutCreatedTasksInput: UserCreateNestedOneWithoutCreatedTasksInput;
  UserCreateNestedOneWithoutOrganizationsInput: UserCreateNestedOneWithoutOrganizationsInput;
  UserCreateOrConnectWithoutApproverForTaskSchedulesInput: UserCreateOrConnectWithoutApproverForTaskSchedulesInput;
  UserCreateOrConnectWithoutApproverForTasksInput: UserCreateOrConnectWithoutApproverForTasksInput;
  UserCreateOrConnectWithoutCreatedTaskSchedulesInput: UserCreateOrConnectWithoutCreatedTaskSchedulesInput;
  UserCreateOrConnectWithoutCreatedTasksInput: UserCreateOrConnectWithoutCreatedTasksInput;
  UserCreateOrConnectWithoutOrganizationsInput: UserCreateOrConnectWithoutOrganizationsInput;
  UserCreateWithoutApproverForTaskSchedulesInput: UserCreateWithoutApproverForTaskSchedulesInput;
  UserCreateWithoutApproverForTasksInput: UserCreateWithoutApproverForTasksInput;
  UserCreateWithoutCreatedTaskSchedulesInput: UserCreateWithoutCreatedTaskSchedulesInput;
  UserCreateWithoutCreatedTasksInput: UserCreateWithoutCreatedTasksInput;
  UserCreateWithoutOrganizationsInput: UserCreateWithoutOrganizationsInput;
  UserMaxAggregate: UserMaxAggregate;
  UserMinAggregate: UserMinAggregate;
  UserOrderByWithRelationInput: UserOrderByWithRelationInput;
  UserRelationFilter: UserRelationFilter;
  UserSubscriptionDetails: UserSubscriptionDetails;
  UserSubscriptionDetailsCreateNestedOneWithoutUserInput: UserSubscriptionDetailsCreateNestedOneWithoutUserInput;
  UserSubscriptionDetailsCreateOrConnectWithoutUserInput: UserSubscriptionDetailsCreateOrConnectWithoutUserInput;
  UserSubscriptionDetailsCreateWithoutUserInput: UserSubscriptionDetailsCreateWithoutUserInput;
  UserSubscriptionDetailsOrderByWithRelationInput: UserSubscriptionDetailsOrderByWithRelationInput;
  UserSubscriptionDetailsRelationFilter: UserSubscriptionDetailsRelationFilter;
  UserSubscriptionDetailsUpdateOneWithoutUserNestedInput: UserSubscriptionDetailsUpdateOneWithoutUserNestedInput;
  UserSubscriptionDetailsUpdateToOneWithWhereWithoutUserInput: UserSubscriptionDetailsUpdateToOneWithWhereWithoutUserInput;
  UserSubscriptionDetailsUpdateWithoutUserInput: UserSubscriptionDetailsUpdateWithoutUserInput;
  UserSubscriptionDetailsUpsertWithoutUserInput: UserSubscriptionDetailsUpsertWithoutUserInput;
  UserSubscriptionDetailsWhereInput: UserSubscriptionDetailsWhereInput;
  UserSubscriptionDetailsWhereUniqueInput: UserSubscriptionDetailsWhereUniqueInput;
  UserUpdateOneRequiredWithoutCreatedTaskSchedulesNestedInput: UserUpdateOneRequiredWithoutCreatedTaskSchedulesNestedInput;
  UserUpdateOneRequiredWithoutCreatedTasksNestedInput: UserUpdateOneRequiredWithoutCreatedTasksNestedInput;
  UserUpdateOneRequiredWithoutOrganizationsNestedInput: UserUpdateOneRequiredWithoutOrganizationsNestedInput;
  UserUpdateOneWithoutApproverForTaskSchedulesNestedInput: UserUpdateOneWithoutApproverForTaskSchedulesNestedInput;
  UserUpdateOneWithoutApproverForTasksNestedInput: UserUpdateOneWithoutApproverForTasksNestedInput;
  UserUpdateToOneWithWhereWithoutApproverForTaskSchedulesInput: UserUpdateToOneWithWhereWithoutApproverForTaskSchedulesInput;
  UserUpdateToOneWithWhereWithoutApproverForTasksInput: UserUpdateToOneWithWhereWithoutApproverForTasksInput;
  UserUpdateToOneWithWhereWithoutCreatedTaskSchedulesInput: UserUpdateToOneWithWhereWithoutCreatedTaskSchedulesInput;
  UserUpdateToOneWithWhereWithoutCreatedTasksInput: UserUpdateToOneWithWhereWithoutCreatedTasksInput;
  UserUpdateToOneWithWhereWithoutOrganizationsInput: UserUpdateToOneWithWhereWithoutOrganizationsInput;
  UserUpdateWithoutApproverForTaskSchedulesInput: UserUpdateWithoutApproverForTaskSchedulesInput;
  UserUpdateWithoutApproverForTasksInput: UserUpdateWithoutApproverForTasksInput;
  UserUpdateWithoutCreatedTaskSchedulesInput: UserUpdateWithoutCreatedTaskSchedulesInput;
  UserUpdateWithoutCreatedTasksInput: UserUpdateWithoutCreatedTasksInput;
  UserUpdateWithoutOrganizationsInput: UserUpdateWithoutOrganizationsInput;
  UserUpsertWithoutApproverForTaskSchedulesInput: UserUpsertWithoutApproverForTaskSchedulesInput;
  UserUpsertWithoutApproverForTasksInput: UserUpsertWithoutApproverForTasksInput;
  UserUpsertWithoutCreatedTaskSchedulesInput: UserUpsertWithoutCreatedTaskSchedulesInput;
  UserUpsertWithoutCreatedTasksInput: UserUpsertWithoutCreatedTasksInput;
  UserUpsertWithoutOrganizationsInput: UserUpsertWithoutOrganizationsInput;
  UserWhereInput: UserWhereInput;
  UserWhereUniqueInput: UserWhereUniqueInput;
  UsersInOrganizations: UsersInOrganizations;
  UsersInOrganizationsCreateManyOrganizationInput: UsersInOrganizationsCreateManyOrganizationInput;
  UsersInOrganizationsCreateManyOrganizationInputEnvelope: UsersInOrganizationsCreateManyOrganizationInputEnvelope;
  UsersInOrganizationsCreateManyUserInput: UsersInOrganizationsCreateManyUserInput;
  UsersInOrganizationsCreateManyUserInputEnvelope: UsersInOrganizationsCreateManyUserInputEnvelope;
  UsersInOrganizationsCreateNestedManyWithoutOrganizationInput: UsersInOrganizationsCreateNestedManyWithoutOrganizationInput;
  UsersInOrganizationsCreateNestedManyWithoutUserInput: UsersInOrganizationsCreateNestedManyWithoutUserInput;
  UsersInOrganizationsCreateOrConnectWithoutOrganizationInput: UsersInOrganizationsCreateOrConnectWithoutOrganizationInput;
  UsersInOrganizationsCreateOrConnectWithoutUserInput: UsersInOrganizationsCreateOrConnectWithoutUserInput;
  UsersInOrganizationsCreateWithoutOrganizationInput: UsersInOrganizationsCreateWithoutOrganizationInput;
  UsersInOrganizationsCreateWithoutUserInput: UsersInOrganizationsCreateWithoutUserInput;
  UsersInOrganizationsListRelationFilter: UsersInOrganizationsListRelationFilter;
  UsersInOrganizationsOrderByRelationAggregateInput: UsersInOrganizationsOrderByRelationAggregateInput;
  UsersInOrganizationsScalarWhereInput: UsersInOrganizationsScalarWhereInput;
  UsersInOrganizationsUpdateManyMutationInput: UsersInOrganizationsUpdateManyMutationInput;
  UsersInOrganizationsUpdateManyWithWhereWithoutOrganizationInput: UsersInOrganizationsUpdateManyWithWhereWithoutOrganizationInput;
  UsersInOrganizationsUpdateManyWithWhereWithoutUserInput: UsersInOrganizationsUpdateManyWithWhereWithoutUserInput;
  UsersInOrganizationsUpdateManyWithoutOrganizationNestedInput: UsersInOrganizationsUpdateManyWithoutOrganizationNestedInput;
  UsersInOrganizationsUpdateManyWithoutUserNestedInput: UsersInOrganizationsUpdateManyWithoutUserNestedInput;
  UsersInOrganizationsUpdateWithWhereUniqueWithoutOrganizationInput: UsersInOrganizationsUpdateWithWhereUniqueWithoutOrganizationInput;
  UsersInOrganizationsUpdateWithWhereUniqueWithoutUserInput: UsersInOrganizationsUpdateWithWhereUniqueWithoutUserInput;
  UsersInOrganizationsUpdateWithoutOrganizationInput: UsersInOrganizationsUpdateWithoutOrganizationInput;
  UsersInOrganizationsUpdateWithoutUserInput: UsersInOrganizationsUpdateWithoutUserInput;
  UsersInOrganizationsUpsertWithWhereUniqueWithoutOrganizationInput: UsersInOrganizationsUpsertWithWhereUniqueWithoutOrganizationInput;
  UsersInOrganizationsUpsertWithWhereUniqueWithoutUserInput: UsersInOrganizationsUpsertWithWhereUniqueWithoutUserInput;
  UsersInOrganizationsWhereInput: UsersInOrganizationsWhereInput;
  UsersInOrganizationsWhereUniqueInput: UsersInOrganizationsWhereUniqueInput;
};

export type NgModuleDirectiveArgs = {
  module: Scalars['String']['input'];
};

export type NgModuleDirectiveResolver<Result, Parent, ContextType = any, Args = NgModuleDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type NamedClientDirectiveArgs = {
  name: Scalars['String']['input'];
};

export type NamedClientDirectiveResolver<Result, Parent, ContextType = any, Args = NamedClientDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AggregateTaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['AggregateTask'] = ResolversParentTypes['AggregateTask']> = {
  _count?: Resolver<Maybe<ResolversTypes['TaskCountAggregate']>, ParentType, ContextType>;
  _max?: Resolver<Maybe<ResolversTypes['TaskMaxAggregate']>, ParentType, ContextType>;
  _min?: Resolver<Maybe<ResolversTypes['TaskMinAggregate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type MessageCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageCode'] = ResolversParentTypes['MessageCode']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  tenant?: Resolver<ResolversTypes['Tenant'], ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  addPropertyUnitTenant?: Resolver<ResolversTypes['PropertyUnit'], ParentType, ContextType, RequireFields<MutationAddPropertyUnitTenantArgs, 'data'>>;
  createOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType, RequireFields<MutationCreateOrganizationArgs, 'name'>>;
  createProperty?: Resolver<ResolversTypes['Property'], ParentType, ContextType, RequireFields<MutationCreatePropertyArgs, 'data'>>;
  createPropertyUnit?: Resolver<ResolversTypes['PropertyUnit'], ParentType, ContextType, RequireFields<MutationCreatePropertyUnitArgs, 'data'>>;
  createTaskSchedule?: Resolver<ResolversTypes['TaskSchedule'], ParentType, ContextType, RequireFields<MutationCreateTaskScheduleArgs, 'data'>>;
  createTasks?: Resolver<Array<ResolversTypes['Task']>, ParentType, ContextType, RequireFields<MutationCreateTasksArgs, 'data'>>;
  deleteProperty?: Resolver<ResolversTypes['Property'], ParentType, ContextType, RequireFields<MutationDeletePropertyArgs, 'id'>>;
  deleteTask?: Resolver<ResolversTypes['Task'], ParentType, ContextType, RequireFields<MutationDeleteTaskArgs, 'where'>>;
  deleteTaskSchedule?: Resolver<ResolversTypes['TaskSchedule'], ParentType, ContextType, RequireFields<MutationDeleteTaskScheduleArgs, 'where'>>;
  updateOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType, RequireFields<MutationUpdateOrganizationArgs, 'data' | 'id'>>;
  updateProfile?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateProfileArgs, 'name'>>;
  updateProperty?: Resolver<ResolversTypes['Property'], ParentType, ContextType, RequireFields<MutationUpdatePropertyArgs, 'data' | 'where'>>;
  updatePropertyUnit?: Resolver<ResolversTypes['PropertyUnit'], ParentType, ContextType, RequireFields<MutationUpdatePropertyUnitArgs, 'data' | 'where'>>;
  updateTask?: Resolver<ResolversTypes['Task'], ParentType, ContextType, RequireFields<MutationUpdateTaskArgs, 'data' | 'where'>>;
  updateTaskSchedule?: Resolver<ResolversTypes['TaskSchedule'], ParentType, ContextType, RequireFields<MutationUpdateTaskScheduleArgs, 'data' | 'where'>>;
};

export type OrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  members?: Resolver<Maybe<Array<ResolversTypes['UsersInOrganizations']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  properties?: Resolver<Maybe<Array<ResolversTypes['Property']>>, ParentType, ContextType>;
  taskSchedules?: Resolver<Maybe<Array<ResolversTypes['TaskSchedule']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationCountAggregate'] = ResolversParentTypes['OrganizationCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationMaxAggregate'] = ResolversParentTypes['OrganizationMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationMinAggregate'] = ResolversParentTypes['OrganizationMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Property'] = ResolversParentTypes['Property']> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  units?: Resolver<Maybe<Array<ResolversTypes['PropertyUnit']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropertyCountAggregate'] = ResolversParentTypes['PropertyCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropertyMaxAggregate'] = ResolversParentTypes['PropertyMaxAggregate']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropertyMinAggregate'] = ResolversParentTypes['PropertyMinAggregate']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyUnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropertyUnit'] = ResolversParentTypes['PropertyUnit']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  property?: Resolver<ResolversTypes['Property'], ParentType, ContextType>;
  propertyId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taskSchedules?: Resolver<Maybe<Array<ResolversTypes['TaskSchedule']>>, ParentType, ContextType>;
  tasks?: Resolver<Maybe<Array<ResolversTypes['Task']>>, ParentType, ContextType>;
  tenants?: Resolver<Maybe<Array<ResolversTypes['Tenant']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyUnitCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropertyUnitCountAggregate'] = ResolversParentTypes['PropertyUnitCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  propertyId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyUnitMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropertyUnitMaxAggregate'] = ResolversParentTypes['PropertyUnitMaxAggregate']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  propertyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyUnitMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['PropertyUnitMinAggregate'] = ResolversParentTypes['PropertyUnitMinAggregate']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  propertyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  aggregateTasks?: Resolver<ResolversTypes['AggregateTask'], ParentType, ContextType, Partial<QueryAggregateTasksArgs>>;
  findManyTaskSchedules?: Resolver<Array<ResolversTypes['TaskSchedule']>, ParentType, ContextType, Partial<QueryFindManyTaskSchedulesArgs>>;
  findManyTasks?: Resolver<Array<ResolversTypes['Task']>, ParentType, ContextType, Partial<QueryFindManyTasksArgs>>;
  findUniqueTaskSchedule?: Resolver<ResolversTypes['TaskSchedule'], ParentType, ContextType, RequireFields<QueryFindUniqueTaskScheduleArgs, 'where'>>;
  getProfile?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  getPropertyById?: Resolver<ResolversTypes['Property'], ParentType, ContextType, RequireFields<QueryGetPropertyByIdArgs, 'id'>>;
  getPropertyUnitById?: Resolver<ResolversTypes['PropertyUnit'], ParentType, ContextType, RequireFields<QueryGetPropertyUnitByIdArgs, 'id'>>;
  getTaskById?: Resolver<ResolversTypes['Task'], ParentType, ContextType, RequireFields<QueryGetTaskByIdArgs, 'id'>>;
  listOrganizations?: Resolver<Array<ResolversTypes['Organization']>, ParentType, ContextType>;
  listProperties?: Resolver<Array<ResolversTypes['Property']>, ParentType, ContextType>;
  listPropertyUnit?: Resolver<Array<ResolversTypes['PropertyUnit']>, ParentType, ContextType, Partial<QueryListPropertyUnitArgs>>;
};

export type TaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['Task'] = ResolversParentTypes['Task']> = {
  approvedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  approver?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  approverId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assignee?: Resolver<ResolversTypes['TaskAssignee'], ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdByTaskSchedule?: Resolver<Maybe<ResolversTypes['TaskSchedule']>, ParentType, ContextType>;
  createdByTaskScheduleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  dueAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  evidence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TaskStatus'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unit?: Resolver<ResolversTypes['PropertyUnit'], ParentType, ContextType>;
  unitId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskCountAggregate'] = ResolversParentTypes['TaskCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  approvedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  approverId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  assignee?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  categories?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  completedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdByTaskScheduleId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  details?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dueAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  evidence?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resources?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unitId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskMaxAggregate'] = ResolversParentTypes['TaskMaxAggregate']> = {
  approvedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  approverId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assignee?: Resolver<Maybe<ResolversTypes['TaskAssignee']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdByTaskScheduleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  evidence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['TaskStatus']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskMinAggregate'] = ResolversParentTypes['TaskMinAggregate']> = {
  approvedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  approverId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assignee?: Resolver<Maybe<ResolversTypes['TaskAssignee']>, ParentType, ContextType>;
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdByTaskScheduleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  evidence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['TaskStatus']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskScheduleResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskSchedule'] = ResolversParentTypes['TaskSchedule']> = {
  approver?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  approverId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assignee?: Resolver<ResolversTypes['TaskAssignee'], ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  dueIn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  rrule?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tasks?: Resolver<Maybe<Array<ResolversTypes['Task']>>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  units?: Resolver<Maybe<Array<ResolversTypes['PropertyUnit']>>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskScheduleCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskScheduleCountAggregate'] = ResolversParentTypes['TaskScheduleCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  approverId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  assignee?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  categories?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdById?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  details?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dueIn?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resources?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rrule?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskScheduleMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskScheduleMaxAggregate'] = ResolversParentTypes['TaskScheduleMaxAggregate']> = {
  approverId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assignee?: Resolver<Maybe<ResolversTypes['TaskAssignee']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueIn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rrule?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaskScheduleMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaskScheduleMinAggregate'] = ResolversParentTypes['TaskScheduleMinAggregate']> = {
  approverId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  assignee?: Resolver<Maybe<ResolversTypes['TaskAssignee']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueIn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rrule?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tenant'] = ResolversParentTypes['Tenant']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  messageCodes?: Resolver<Maybe<Array<ResolversTypes['MessageCode']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  propertyUnit?: Resolver<ResolversTypes['PropertyUnit'], ParentType, ContextType>;
  propertyUnitId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantCountAggregate'] = ResolversParentTypes['TenantCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  propertyUnitId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantMaxAggregate'] = ResolversParentTypes['TenantMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  propertyUnitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TenantMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantMinAggregate'] = ResolversParentTypes['TenantMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  propertyUnitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  approverForTaskSchedules?: Resolver<Maybe<Array<ResolversTypes['TaskSchedule']>>, ParentType, ContextType>;
  approverForTasks?: Resolver<Maybe<Array<ResolversTypes['Task']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdTaskSchedules?: Resolver<Maybe<Array<ResolversTypes['TaskSchedule']>>, ParentType, ContextType>;
  createdTasks?: Resolver<Maybe<Array<ResolversTypes['Task']>>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizations?: Resolver<Maybe<Array<ResolversTypes['UsersInOrganizations']>>, ParentType, ContextType>;
  subscription?: Resolver<Maybe<ResolversTypes['UserSubscriptionDetails']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCountAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCountAggregate'] = ResolversParentTypes['UserCountAggregate']> = {
  _all?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserMaxAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMaxAggregate'] = ResolversParentTypes['UserMaxAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserMinAggregateResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserMinAggregate'] = ResolversParentTypes['UserMinAggregate']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserSubscriptionDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserSubscriptionDetails'] = ResolversParentTypes['UserSubscriptionDetails']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  stripeCustomerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stripeEntitlements?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  stripeSubscriptionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersInOrganizationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersInOrganizations'] = ResolversParentTypes['UsersInOrganizations']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AggregateTask?: AggregateTaskResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  JSON?: GraphQLScalarType;
  MessageCode?: MessageCodeResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  OrganizationCountAggregate?: OrganizationCountAggregateResolvers<ContextType>;
  OrganizationMaxAggregate?: OrganizationMaxAggregateResolvers<ContextType>;
  OrganizationMinAggregate?: OrganizationMinAggregateResolvers<ContextType>;
  Property?: PropertyResolvers<ContextType>;
  PropertyCountAggregate?: PropertyCountAggregateResolvers<ContextType>;
  PropertyMaxAggregate?: PropertyMaxAggregateResolvers<ContextType>;
  PropertyMinAggregate?: PropertyMinAggregateResolvers<ContextType>;
  PropertyUnit?: PropertyUnitResolvers<ContextType>;
  PropertyUnitCountAggregate?: PropertyUnitCountAggregateResolvers<ContextType>;
  PropertyUnitMaxAggregate?: PropertyUnitMaxAggregateResolvers<ContextType>;
  PropertyUnitMinAggregate?: PropertyUnitMinAggregateResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Task?: TaskResolvers<ContextType>;
  TaskCountAggregate?: TaskCountAggregateResolvers<ContextType>;
  TaskMaxAggregate?: TaskMaxAggregateResolvers<ContextType>;
  TaskMinAggregate?: TaskMinAggregateResolvers<ContextType>;
  TaskSchedule?: TaskScheduleResolvers<ContextType>;
  TaskScheduleCountAggregate?: TaskScheduleCountAggregateResolvers<ContextType>;
  TaskScheduleMaxAggregate?: TaskScheduleMaxAggregateResolvers<ContextType>;
  TaskScheduleMinAggregate?: TaskScheduleMinAggregateResolvers<ContextType>;
  Tenant?: TenantResolvers<ContextType>;
  TenantCountAggregate?: TenantCountAggregateResolvers<ContextType>;
  TenantMaxAggregate?: TenantMaxAggregateResolvers<ContextType>;
  TenantMinAggregate?: TenantMinAggregateResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserCountAggregate?: UserCountAggregateResolvers<ContextType>;
  UserMaxAggregate?: UserMaxAggregateResolvers<ContextType>;
  UserMinAggregate?: UserMinAggregateResolvers<ContextType>;
  UserSubscriptionDetails?: UserSubscriptionDetailsResolvers<ContextType>;
  UsersInOrganizations?: UsersInOrganizationsResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  NgModule?: NgModuleDirectiveResolver<any, any, ContextType>;
  namedClient?: NamedClientDirectiveResolver<any, any, ContextType>;
};

export type ListOrganizations_Query_listOrganizations_Organization_members_UsersInOrganizations_user_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type ListOrganizations_Query_listOrganizations_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string, user: ListOrganizations_Query_listOrganizations_Organization_members_UsersInOrganizations_user_User };

export type ListOrganizations_Query_listOrganizations_Organization_properties_Property_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type ListOrganizations_Query_listOrganizations_Organization_properties_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, units?: Array<ListOrganizations_Query_listOrganizations_Organization_properties_Property_units_PropertyUnit> | null | undefined };

export type ListOrganizations_Query_listOrganizations_Organization_taskSchedules_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type ListOrganizations_Query_listOrganizations_Organization_taskSchedules_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type ListOrganizations_Query_listOrganizations_Organization_taskSchedules_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type ListOrganizations_Query_listOrganizations_Organization_taskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type ListOrganizations_Query_listOrganizations_Organization_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<ListOrganizations_Query_listOrganizations_Organization_taskSchedules_TaskSchedule_units_PropertyUnit> | null | undefined, createdBy: ListOrganizations_Query_listOrganizations_Organization_taskSchedules_TaskSchedule_createdBy_User, approver?: ListOrganizations_Query_listOrganizations_Organization_taskSchedules_TaskSchedule_approver_User | null | undefined, tasks?: Array<ListOrganizations_Query_listOrganizations_Organization_taskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type ListOrganizations_Query_listOrganizations_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<ListOrganizations_Query_listOrganizations_Organization_members_UsersInOrganizations> | null | undefined, properties?: Array<ListOrganizations_Query_listOrganizations_Organization_properties_Property> | null | undefined, taskSchedules?: Array<ListOrganizations_Query_listOrganizations_Organization_taskSchedules_TaskSchedule> | null | undefined };

export type ListOrganizations_Query_Query = { __typename?: 'Query', listOrganizations: Array<ListOrganizations_Query_listOrganizations_Organization> };


export type ListOrganizations_QueryVariables = Exact<{ [key: string]: never; }>;


export type ListOrganizations_Query = ListOrganizations_Query_Query;

export type GetPropertyById_Query_getPropertyById_Property_units_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type GetPropertyById_Query_getPropertyById_Property_units_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type GetPropertyById_Query_getPropertyById_Property_units_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type GetPropertyById_Query_getPropertyById_Property_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, tenants?: Array<GetPropertyById_Query_getPropertyById_Property_units_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<GetPropertyById_Query_getPropertyById_Property_units_PropertyUnit_tasks_Task> | null | undefined, taskSchedules?: Array<GetPropertyById_Query_getPropertyById_Property_units_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type GetPropertyById_Query_getPropertyById_Property_organization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type GetPropertyById_Query_getPropertyById_Property_organization_Organization_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type GetPropertyById_Query_getPropertyById_Property_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<GetPropertyById_Query_getPropertyById_Property_organization_Organization_members_UsersInOrganizations> | null | undefined, taskSchedules?: Array<GetPropertyById_Query_getPropertyById_Property_organization_Organization_taskSchedules_TaskSchedule> | null | undefined };

export type GetPropertyById_Query_getPropertyById_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, units?: Array<GetPropertyById_Query_getPropertyById_Property_units_PropertyUnit> | null | undefined, organization: GetPropertyById_Query_getPropertyById_Property_organization_Organization };

export type GetPropertyById_Query_Query = { __typename?: 'Query', getPropertyById: GetPropertyById_Query_getPropertyById_Property };


export type GetPropertyById_QueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPropertyById_Query = GetPropertyById_Query_Query;

export type ListProperties_Query_listProperties_Property_units_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type ListProperties_Query_listProperties_Property_units_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type ListProperties_Query_listProperties_Property_units_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type ListProperties_Query_listProperties_Property_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, tenants?: Array<ListProperties_Query_listProperties_Property_units_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<ListProperties_Query_listProperties_Property_units_PropertyUnit_tasks_Task> | null | undefined, taskSchedules?: Array<ListProperties_Query_listProperties_Property_units_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type ListProperties_Query_listProperties_Property_organization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type ListProperties_Query_listProperties_Property_organization_Organization_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type ListProperties_Query_listProperties_Property_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<ListProperties_Query_listProperties_Property_organization_Organization_members_UsersInOrganizations> | null | undefined, taskSchedules?: Array<ListProperties_Query_listProperties_Property_organization_Organization_taskSchedules_TaskSchedule> | null | undefined };

export type ListProperties_Query_listProperties_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, units?: Array<ListProperties_Query_listProperties_Property_units_PropertyUnit> | null | undefined, organization: ListProperties_Query_listProperties_Property_organization_Organization };

export type ListProperties_Query_Query = { __typename?: 'Query', listProperties: Array<ListProperties_Query_listProperties_Property> };


export type ListProperties_QueryVariables = Exact<{ [key: string]: never; }>;


export type ListProperties_Query = ListProperties_Query_Query;

export type GetTaskById_Query_getTaskById_Task_createdBy_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type GetTaskById_Query_getTaskById_Task_createdBy_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type GetTaskById_Query_getTaskById_Task_createdBy_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type GetTaskById_Query_getTaskById_Task_createdBy_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type GetTaskById_Query_getTaskById_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<GetTaskById_Query_getTaskById_Task_createdBy_User_organizations_UsersInOrganizations> | null | undefined, subscription?: GetTaskById_Query_getTaskById_Task_createdBy_User_subscription_UserSubscriptionDetails | null | undefined, approverForTaskSchedules?: Array<GetTaskById_Query_getTaskById_Task_createdBy_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTaskSchedules?: Array<GetTaskById_Query_getTaskById_Task_createdBy_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type GetTaskById_Query_getTaskById_Task_approver_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type GetTaskById_Query_getTaskById_Task_approver_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type GetTaskById_Query_getTaskById_Task_approver_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type GetTaskById_Query_getTaskById_Task_approver_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type GetTaskById_Query_getTaskById_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<GetTaskById_Query_getTaskById_Task_approver_User_organizations_UsersInOrganizations> | null | undefined, subscription?: GetTaskById_Query_getTaskById_Task_approver_User_subscription_UserSubscriptionDetails | null | undefined, approverForTaskSchedules?: Array<GetTaskById_Query_getTaskById_Task_approver_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTaskSchedules?: Array<GetTaskById_Query_getTaskById_Task_approver_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type GetTaskById_Query_getTaskById_Task_unit_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type GetTaskById_Query_getTaskById_Task_unit_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type GetTaskById_Query_getTaskById_Task_unit_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type GetTaskById_Query_getTaskById_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: GetTaskById_Query_getTaskById_Task_unit_PropertyUnit_property_Property, tenants?: Array<GetTaskById_Query_getTaskById_Task_unit_PropertyUnit_tenants_Tenant> | null | undefined, taskSchedules?: Array<GetTaskById_Query_getTaskById_Task_unit_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type GetTaskById_Query_getTaskById_Task_createdByTaskSchedule_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type GetTaskById_Query_getTaskById_Task_createdByTaskSchedule_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type GetTaskById_Query_getTaskById_Task_createdByTaskSchedule_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type GetTaskById_Query_getTaskById_Task_createdByTaskSchedule_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type GetTaskById_Query_getTaskById_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<GetTaskById_Query_getTaskById_Task_createdByTaskSchedule_TaskSchedule_units_PropertyUnit> | null | undefined, organization: GetTaskById_Query_getTaskById_Task_createdByTaskSchedule_TaskSchedule_organization_Organization, createdBy: GetTaskById_Query_getTaskById_Task_createdByTaskSchedule_TaskSchedule_createdBy_User, approver?: GetTaskById_Query_getTaskById_Task_createdByTaskSchedule_TaskSchedule_approver_User | null | undefined };

export type GetTaskById_Query_getTaskById_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: GetTaskById_Query_getTaskById_Task_createdBy_User, approver?: GetTaskById_Query_getTaskById_Task_approver_User | null | undefined, unit: GetTaskById_Query_getTaskById_Task_unit_PropertyUnit, createdByTaskSchedule?: GetTaskById_Query_getTaskById_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type GetTaskById_Query_Query = { __typename?: 'Query', getTaskById: GetTaskById_Query_getTaskById_Task };


export type GetTaskById_QueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTaskById_Query = GetTaskById_Query_Query;

export type FindManyTasks_Query_findManyTasks_Task_createdBy_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type FindManyTasks_Query_findManyTasks_Task_createdBy_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type FindManyTasks_Query_findManyTasks_Task_createdBy_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type FindManyTasks_Query_findManyTasks_Task_createdBy_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type FindManyTasks_Query_findManyTasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<FindManyTasks_Query_findManyTasks_Task_createdBy_User_organizations_UsersInOrganizations> | null | undefined, subscription?: FindManyTasks_Query_findManyTasks_Task_createdBy_User_subscription_UserSubscriptionDetails | null | undefined, approverForTaskSchedules?: Array<FindManyTasks_Query_findManyTasks_Task_createdBy_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTaskSchedules?: Array<FindManyTasks_Query_findManyTasks_Task_createdBy_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type FindManyTasks_Query_findManyTasks_Task_approver_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type FindManyTasks_Query_findManyTasks_Task_approver_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type FindManyTasks_Query_findManyTasks_Task_approver_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type FindManyTasks_Query_findManyTasks_Task_approver_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type FindManyTasks_Query_findManyTasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<FindManyTasks_Query_findManyTasks_Task_approver_User_organizations_UsersInOrganizations> | null | undefined, subscription?: FindManyTasks_Query_findManyTasks_Task_approver_User_subscription_UserSubscriptionDetails | null | undefined, approverForTaskSchedules?: Array<FindManyTasks_Query_findManyTasks_Task_approver_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTaskSchedules?: Array<FindManyTasks_Query_findManyTasks_Task_approver_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type FindManyTasks_Query_findManyTasks_Task_unit_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type FindManyTasks_Query_findManyTasks_Task_unit_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type FindManyTasks_Query_findManyTasks_Task_unit_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type FindManyTasks_Query_findManyTasks_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: FindManyTasks_Query_findManyTasks_Task_unit_PropertyUnit_property_Property, tenants?: Array<FindManyTasks_Query_findManyTasks_Task_unit_PropertyUnit_tenants_Tenant> | null | undefined, taskSchedules?: Array<FindManyTasks_Query_findManyTasks_Task_unit_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type FindManyTasks_Query_findManyTasks_Task_createdByTaskSchedule_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type FindManyTasks_Query_findManyTasks_Task_createdByTaskSchedule_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type FindManyTasks_Query_findManyTasks_Task_createdByTaskSchedule_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type FindManyTasks_Query_findManyTasks_Task_createdByTaskSchedule_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type FindManyTasks_Query_findManyTasks_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<FindManyTasks_Query_findManyTasks_Task_createdByTaskSchedule_TaskSchedule_units_PropertyUnit> | null | undefined, organization: FindManyTasks_Query_findManyTasks_Task_createdByTaskSchedule_TaskSchedule_organization_Organization, createdBy: FindManyTasks_Query_findManyTasks_Task_createdByTaskSchedule_TaskSchedule_createdBy_User, approver?: FindManyTasks_Query_findManyTasks_Task_createdByTaskSchedule_TaskSchedule_approver_User | null | undefined };

export type FindManyTasks_Query_findManyTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: FindManyTasks_Query_findManyTasks_Task_createdBy_User, approver?: FindManyTasks_Query_findManyTasks_Task_approver_User | null | undefined, unit: FindManyTasks_Query_findManyTasks_Task_unit_PropertyUnit, createdByTaskSchedule?: FindManyTasks_Query_findManyTasks_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type FindManyTasks_Query_Query = { __typename?: 'Query', findManyTasks: Array<FindManyTasks_Query_findManyTasks_Task> };


export type FindManyTasks_QueryVariables = Exact<{
  where?: InputMaybe<TaskWhereInput>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput> | TaskOrderByWithRelationInput>;
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum> | TaskScalarFieldEnum>;
}>;


export type FindManyTasks_Query = FindManyTasks_Query_Query;

export type AggregateTasks_Query_aggregateTasks_AggregateTask__count_TaskCountAggregate = { __typename?: 'TaskCountAggregate', id: number, createdAt: number, updatedAt: number, title: number, categories: number, description: number, details: number, resources: number, status: number, assignee: number, dueAt: number, completedAt: number, createdById: number, approverId: number, approvedAt: number, evidence: number, unitId: number, createdByTaskScheduleId: number, _all: number };

export type AggregateTasks_Query_aggregateTasks_AggregateTask__min_TaskMinAggregate = { __typename?: 'TaskMinAggregate', id?: string | null | undefined, createdAt?: Date | null | undefined, updatedAt?: Date | null | undefined, title?: string | null | undefined, description?: string | null | undefined, status?: TaskStatus | null | undefined, assignee?: TaskAssignee | null | undefined, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById?: string | null | undefined, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId?: string | null | undefined, createdByTaskScheduleId?: string | null | undefined };

export type AggregateTasks_Query_aggregateTasks_AggregateTask__max_TaskMaxAggregate = { __typename?: 'TaskMaxAggregate', id?: string | null | undefined, createdAt?: Date | null | undefined, updatedAt?: Date | null | undefined, title?: string | null | undefined, description?: string | null | undefined, status?: TaskStatus | null | undefined, assignee?: TaskAssignee | null | undefined, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById?: string | null | undefined, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId?: string | null | undefined, createdByTaskScheduleId?: string | null | undefined };

export type AggregateTasks_Query_aggregateTasks_AggregateTask = { __typename?: 'AggregateTask', _count?: AggregateTasks_Query_aggregateTasks_AggregateTask__count_TaskCountAggregate | null | undefined, _min?: AggregateTasks_Query_aggregateTasks_AggregateTask__min_TaskMinAggregate | null | undefined, _max?: AggregateTasks_Query_aggregateTasks_AggregateTask__max_TaskMaxAggregate | null | undefined };

export type AggregateTasks_Query_Query = { __typename?: 'Query', aggregateTasks: AggregateTasks_Query_aggregateTasks_AggregateTask };


export type AggregateTasks_QueryVariables = Exact<{
  where?: InputMaybe<TaskWhereInput>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput> | TaskOrderByWithRelationInput>;
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  _count?: InputMaybe<TaskCountAggregateInput>;
  _min?: InputMaybe<TaskMinAggregateInput>;
  _max?: InputMaybe<TaskMaxAggregateInput>;
}>;


export type AggregateTasks_Query = AggregateTasks_Query_Query;

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_units_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_units_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_units_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_units_PropertyUnit_property_Property, tenants?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_units_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_units_PropertyUnit_tasks_Task> | null | undefined };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_organization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_organization_Organization_properties_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_organization_Organization_members_UsersInOrganizations> | null | undefined, properties?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_organization_Organization_properties_Property> | null | undefined };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_createdBy_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_createdBy_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_createdBy_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_createdBy_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_createdBy_User_organizations_UsersInOrganizations> | null | undefined, subscription?: FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_createdBy_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_createdBy_User_approverForTasks_Task> | null | undefined, createdTasks?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_createdBy_User_createdTasks_Task> | null | undefined };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_approver_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_approver_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_approver_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_approver_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_approver_User_organizations_UsersInOrganizations> | null | undefined, subscription?: FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_approver_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_approver_User_approverForTasks_Task> | null | undefined, createdTasks?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_approver_User_createdTasks_Task> | null | undefined };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_tasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_tasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_tasks_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_tasks_Task_createdBy_User, approver?: FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_tasks_Task_approver_User | null | undefined, unit: FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_tasks_Task_unit_PropertyUnit };

export type FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_units_PropertyUnit> | null | undefined, organization: FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_organization_Organization, createdBy: FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_createdBy_User, approver?: FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_approver_User | null | undefined, tasks?: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type FindManyTaskSchedules_Query_Query = { __typename?: 'Query', findManyTaskSchedules: Array<FindManyTaskSchedules_Query_findManyTaskSchedules_TaskSchedule> };


export type FindManyTaskSchedules_QueryVariables = Exact<{
  where?: InputMaybe<TaskScheduleWhereInput>;
  orderBy?: InputMaybe<Array<TaskScheduleOrderByWithRelationInput> | TaskScheduleOrderByWithRelationInput>;
  cursor?: InputMaybe<TaskScheduleWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<TaskScheduleScalarFieldEnum> | TaskScheduleScalarFieldEnum>;
}>;


export type FindManyTaskSchedules_Query = FindManyTaskSchedules_Query_Query;

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_units_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_units_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_units_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_units_PropertyUnit_property_Property, tenants?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_units_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_units_PropertyUnit_tasks_Task> | null | undefined };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_organization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_organization_Organization_properties_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_organization_Organization_members_UsersInOrganizations> | null | undefined, properties?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_organization_Organization_properties_Property> | null | undefined };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_createdBy_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_createdBy_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_createdBy_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_createdBy_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_createdBy_User_organizations_UsersInOrganizations> | null | undefined, subscription?: FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_createdBy_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_createdBy_User_approverForTasks_Task> | null | undefined, createdTasks?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_createdBy_User_createdTasks_Task> | null | undefined };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_approver_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_approver_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_approver_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_approver_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_approver_User_organizations_UsersInOrganizations> | null | undefined, subscription?: FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_approver_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_approver_User_approverForTasks_Task> | null | undefined, createdTasks?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_approver_User_createdTasks_Task> | null | undefined };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_tasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_tasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_tasks_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_tasks_Task_createdBy_User, approver?: FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_tasks_Task_approver_User | null | undefined, unit: FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_tasks_Task_unit_PropertyUnit };

export type FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_units_PropertyUnit> | null | undefined, organization: FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_organization_Organization, createdBy: FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_createdBy_User, approver?: FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_approver_User | null | undefined, tasks?: Array<FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule_tasks_Task> | null | undefined };

export type FindUniqueTaskSchedule_Query_Query = { __typename?: 'Query', findUniqueTaskSchedule: FindUniqueTaskSchedule_Query_findUniqueTaskSchedule_TaskSchedule };


export type FindUniqueTaskSchedule_QueryVariables = Exact<{
  where: TaskScheduleWhereUniqueInput;
}>;


export type FindUniqueTaskSchedule_Query = FindUniqueTaskSchedule_Query_Query;

export type GetProfile_Query_getProfile_User_organizations_UsersInOrganizations_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type GetProfile_Query_getProfile_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string, organization: GetProfile_Query_getProfile_User_organizations_UsersInOrganizations_organization_Organization };

export type GetProfile_Query_getProfile_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type GetProfile_Query_getProfile_User_approverForTasks_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type GetProfile_Query_getProfile_User_approverForTasks_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type GetProfile_Query_getProfile_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, unit: GetProfile_Query_getProfile_User_approverForTasks_Task_unit_PropertyUnit, createdByTaskSchedule?: GetProfile_Query_getProfile_User_approverForTasks_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type GetProfile_Query_getProfile_User_approverForTaskSchedules_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type GetProfile_Query_getProfile_User_approverForTaskSchedules_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type GetProfile_Query_getProfile_User_approverForTaskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type GetProfile_Query_getProfile_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<GetProfile_Query_getProfile_User_approverForTaskSchedules_TaskSchedule_units_PropertyUnit> | null | undefined, organization: GetProfile_Query_getProfile_User_approverForTaskSchedules_TaskSchedule_organization_Organization, tasks?: Array<GetProfile_Query_getProfile_User_approverForTaskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type GetProfile_Query_getProfile_User_createdTasks_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type GetProfile_Query_getProfile_User_createdTasks_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type GetProfile_Query_getProfile_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, unit: GetProfile_Query_getProfile_User_createdTasks_Task_unit_PropertyUnit, createdByTaskSchedule?: GetProfile_Query_getProfile_User_createdTasks_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type GetProfile_Query_getProfile_User_createdTaskSchedules_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type GetProfile_Query_getProfile_User_createdTaskSchedules_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type GetProfile_Query_getProfile_User_createdTaskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type GetProfile_Query_getProfile_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<GetProfile_Query_getProfile_User_createdTaskSchedules_TaskSchedule_units_PropertyUnit> | null | undefined, organization: GetProfile_Query_getProfile_User_createdTaskSchedules_TaskSchedule_organization_Organization, tasks?: Array<GetProfile_Query_getProfile_User_createdTaskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type GetProfile_Query_getProfile_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<GetProfile_Query_getProfile_User_organizations_UsersInOrganizations> | null | undefined, subscription?: GetProfile_Query_getProfile_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<GetProfile_Query_getProfile_User_approverForTasks_Task> | null | undefined, approverForTaskSchedules?: Array<GetProfile_Query_getProfile_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTasks?: Array<GetProfile_Query_getProfile_User_createdTasks_Task> | null | undefined, createdTaskSchedules?: Array<GetProfile_Query_getProfile_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type GetProfile_Query_Query = { __typename?: 'Query', getProfile: GetProfile_Query_getProfile_User };


export type GetProfile_QueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfile_Query = GetProfile_Query_Query;

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_property_Property_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, organization: GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_property_Property_organization_Organization };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tenants_Tenant_messageCodes_MessageCode = { __typename?: 'MessageCode', token: string, createdAt: Date, expiresAt: Date, tenantId: string };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string, messageCodes?: Array<GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tenants_Tenant_messageCodes_MessageCode> | null | undefined };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tasks_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tasks_Task_createdBy_User, approver?: GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tasks_Task_approver_User | null | undefined, createdByTaskSchedule?: GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tasks_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_taskSchedules_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_taskSchedules_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_taskSchedules_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_taskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, organization: GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_taskSchedules_TaskSchedule_organization_Organization, createdBy: GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_taskSchedules_TaskSchedule_createdBy_User, approver?: GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_taskSchedules_TaskSchedule_approver_User | null | undefined, tasks?: Array<GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_taskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_property_Property, tenants?: Array<GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_tasks_Task> | null | undefined, taskSchedules?: Array<GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type GetPropertyUnitById_Query_Query = { __typename?: 'Query', getPropertyUnitById: GetPropertyUnitById_Query_getPropertyUnitById_PropertyUnit };


export type GetPropertyUnitById_QueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetPropertyUnitById_Query = GetPropertyUnitById_Query_Query;

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_property_Property_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, organization: ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_property_Property_organization_Organization };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tenants_Tenant_messageCodes_MessageCode = { __typename?: 'MessageCode', token: string, createdAt: Date, expiresAt: Date, tenantId: string };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string, messageCodes?: Array<ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tenants_Tenant_messageCodes_MessageCode> | null | undefined };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tasks_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tasks_Task_createdBy_User, approver?: ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tasks_Task_approver_User | null | undefined, createdByTaskSchedule?: ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tasks_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, organization: ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_organization_Organization, createdBy: ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_createdBy_User, approver?: ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_approver_User | null | undefined, tasks?: Array<ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type ListPropertyUnit_Query_listPropertyUnit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_property_Property, tenants?: Array<ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_tasks_Task> | null | undefined, taskSchedules?: Array<ListPropertyUnit_Query_listPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type ListPropertyUnit_Query_Query = { __typename?: 'Query', listPropertyUnit: Array<ListPropertyUnit_Query_listPropertyUnit_PropertyUnit> };


export type ListPropertyUnit_QueryVariables = Exact<{
  where?: InputMaybe<PropertyUnitWhereInput>;
  orderBy?: InputMaybe<Array<PropertyUnitOrderByWithRelationInput> | PropertyUnitOrderByWithRelationInput>;
  cursor?: InputMaybe<PropertyUnitWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PropertyUnitScalarFieldEnum> | PropertyUnitScalarFieldEnum>;
}>;


export type ListPropertyUnit_Query = ListPropertyUnit_Query_Query;

export type CreateOrganization_Mutation_createOrganization_Organization_members_UsersInOrganizations_user_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type CreateOrganization_Mutation_createOrganization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string, user: CreateOrganization_Mutation_createOrganization_Organization_members_UsersInOrganizations_user_User };

export type CreateOrganization_Mutation_createOrganization_Organization_properties_Property_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type CreateOrganization_Mutation_createOrganization_Organization_properties_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, units?: Array<CreateOrganization_Mutation_createOrganization_Organization_properties_Property_units_PropertyUnit> | null | undefined };

export type CreateOrganization_Mutation_createOrganization_Organization_taskSchedules_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type CreateOrganization_Mutation_createOrganization_Organization_taskSchedules_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type CreateOrganization_Mutation_createOrganization_Organization_taskSchedules_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type CreateOrganization_Mutation_createOrganization_Organization_taskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type CreateOrganization_Mutation_createOrganization_Organization_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<CreateOrganization_Mutation_createOrganization_Organization_taskSchedules_TaskSchedule_units_PropertyUnit> | null | undefined, createdBy: CreateOrganization_Mutation_createOrganization_Organization_taskSchedules_TaskSchedule_createdBy_User, approver?: CreateOrganization_Mutation_createOrganization_Organization_taskSchedules_TaskSchedule_approver_User | null | undefined, tasks?: Array<CreateOrganization_Mutation_createOrganization_Organization_taskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type CreateOrganization_Mutation_createOrganization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<CreateOrganization_Mutation_createOrganization_Organization_members_UsersInOrganizations> | null | undefined, properties?: Array<CreateOrganization_Mutation_createOrganization_Organization_properties_Property> | null | undefined, taskSchedules?: Array<CreateOrganization_Mutation_createOrganization_Organization_taskSchedules_TaskSchedule> | null | undefined };

export type CreateOrganization_Mutation_Mutation = { __typename?: 'Mutation', createOrganization: CreateOrganization_Mutation_createOrganization_Organization };


export type CreateOrganization_MutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type CreateOrganization_Mutation = CreateOrganization_Mutation_Mutation;

export type UpdateOrganization_Mutation_updateOrganization_Organization_members_UsersInOrganizations_user_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type UpdateOrganization_Mutation_updateOrganization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string, user: UpdateOrganization_Mutation_updateOrganization_Organization_members_UsersInOrganizations_user_User };

export type UpdateOrganization_Mutation_updateOrganization_Organization_properties_Property_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type UpdateOrganization_Mutation_updateOrganization_Organization_properties_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, units?: Array<UpdateOrganization_Mutation_updateOrganization_Organization_properties_Property_units_PropertyUnit> | null | undefined };

export type UpdateOrganization_Mutation_updateOrganization_Organization_taskSchedules_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type UpdateOrganization_Mutation_updateOrganization_Organization_taskSchedules_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type UpdateOrganization_Mutation_updateOrganization_Organization_taskSchedules_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type UpdateOrganization_Mutation_updateOrganization_Organization_taskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type UpdateOrganization_Mutation_updateOrganization_Organization_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<UpdateOrganization_Mutation_updateOrganization_Organization_taskSchedules_TaskSchedule_units_PropertyUnit> | null | undefined, createdBy: UpdateOrganization_Mutation_updateOrganization_Organization_taskSchedules_TaskSchedule_createdBy_User, approver?: UpdateOrganization_Mutation_updateOrganization_Organization_taskSchedules_TaskSchedule_approver_User | null | undefined, tasks?: Array<UpdateOrganization_Mutation_updateOrganization_Organization_taskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type UpdateOrganization_Mutation_updateOrganization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<UpdateOrganization_Mutation_updateOrganization_Organization_members_UsersInOrganizations> | null | undefined, properties?: Array<UpdateOrganization_Mutation_updateOrganization_Organization_properties_Property> | null | undefined, taskSchedules?: Array<UpdateOrganization_Mutation_updateOrganization_Organization_taskSchedules_TaskSchedule> | null | undefined };

export type UpdateOrganization_Mutation_Mutation = { __typename?: 'Mutation', updateOrganization: UpdateOrganization_Mutation_updateOrganization_Organization };


export type UpdateOrganization_MutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: OrganizationUpdateInput;
}>;


export type UpdateOrganization_Mutation = UpdateOrganization_Mutation_Mutation;

export type CreateProperty_Mutation_createProperty_Property_units_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type CreateProperty_Mutation_createProperty_Property_units_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type CreateProperty_Mutation_createProperty_Property_units_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type CreateProperty_Mutation_createProperty_Property_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, tenants?: Array<CreateProperty_Mutation_createProperty_Property_units_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<CreateProperty_Mutation_createProperty_Property_units_PropertyUnit_tasks_Task> | null | undefined, taskSchedules?: Array<CreateProperty_Mutation_createProperty_Property_units_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type CreateProperty_Mutation_createProperty_Property_organization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type CreateProperty_Mutation_createProperty_Property_organization_Organization_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type CreateProperty_Mutation_createProperty_Property_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<CreateProperty_Mutation_createProperty_Property_organization_Organization_members_UsersInOrganizations> | null | undefined, taskSchedules?: Array<CreateProperty_Mutation_createProperty_Property_organization_Organization_taskSchedules_TaskSchedule> | null | undefined };

export type CreateProperty_Mutation_createProperty_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, units?: Array<CreateProperty_Mutation_createProperty_Property_units_PropertyUnit> | null | undefined, organization: CreateProperty_Mutation_createProperty_Property_organization_Organization };

export type CreateProperty_Mutation_Mutation = { __typename?: 'Mutation', createProperty: CreateProperty_Mutation_createProperty_Property };


export type CreateProperty_MutationVariables = Exact<{
  data: PropertyCreateInput;
}>;


export type CreateProperty_Mutation = CreateProperty_Mutation_Mutation;

export type UpdateProperty_Mutation_updateProperty_Property_units_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type UpdateProperty_Mutation_updateProperty_Property_units_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type UpdateProperty_Mutation_updateProperty_Property_units_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type UpdateProperty_Mutation_updateProperty_Property_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, tenants?: Array<UpdateProperty_Mutation_updateProperty_Property_units_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<UpdateProperty_Mutation_updateProperty_Property_units_PropertyUnit_tasks_Task> | null | undefined, taskSchedules?: Array<UpdateProperty_Mutation_updateProperty_Property_units_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type UpdateProperty_Mutation_updateProperty_Property_organization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type UpdateProperty_Mutation_updateProperty_Property_organization_Organization_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type UpdateProperty_Mutation_updateProperty_Property_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<UpdateProperty_Mutation_updateProperty_Property_organization_Organization_members_UsersInOrganizations> | null | undefined, taskSchedules?: Array<UpdateProperty_Mutation_updateProperty_Property_organization_Organization_taskSchedules_TaskSchedule> | null | undefined };

export type UpdateProperty_Mutation_updateProperty_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, units?: Array<UpdateProperty_Mutation_updateProperty_Property_units_PropertyUnit> | null | undefined, organization: UpdateProperty_Mutation_updateProperty_Property_organization_Organization };

export type UpdateProperty_Mutation_Mutation = { __typename?: 'Mutation', updateProperty: UpdateProperty_Mutation_updateProperty_Property };


export type UpdateProperty_MutationVariables = Exact<{
  data: PropertyUpdateInput;
  where: PropertyWhereUniqueInput;
}>;


export type UpdateProperty_Mutation = UpdateProperty_Mutation_Mutation;

export type DeleteProperty_Mutation_deleteProperty_Property_units_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type DeleteProperty_Mutation_deleteProperty_Property_units_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type DeleteProperty_Mutation_deleteProperty_Property_units_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type DeleteProperty_Mutation_deleteProperty_Property_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, tenants?: Array<DeleteProperty_Mutation_deleteProperty_Property_units_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<DeleteProperty_Mutation_deleteProperty_Property_units_PropertyUnit_tasks_Task> | null | undefined, taskSchedules?: Array<DeleteProperty_Mutation_deleteProperty_Property_units_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type DeleteProperty_Mutation_deleteProperty_Property_organization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type DeleteProperty_Mutation_deleteProperty_Property_organization_Organization_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type DeleteProperty_Mutation_deleteProperty_Property_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<DeleteProperty_Mutation_deleteProperty_Property_organization_Organization_members_UsersInOrganizations> | null | undefined, taskSchedules?: Array<DeleteProperty_Mutation_deleteProperty_Property_organization_Organization_taskSchedules_TaskSchedule> | null | undefined };

export type DeleteProperty_Mutation_deleteProperty_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, units?: Array<DeleteProperty_Mutation_deleteProperty_Property_units_PropertyUnit> | null | undefined, organization: DeleteProperty_Mutation_deleteProperty_Property_organization_Organization };

export type DeleteProperty_Mutation_Mutation = { __typename?: 'Mutation', deleteProperty: DeleteProperty_Mutation_deleteProperty_Property };


export type DeleteProperty_MutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteProperty_Mutation = DeleteProperty_Mutation_Mutation;

export type CreateTasks_Mutation_createTasks_Task_createdBy_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type CreateTasks_Mutation_createTasks_Task_createdBy_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type CreateTasks_Mutation_createTasks_Task_createdBy_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type CreateTasks_Mutation_createTasks_Task_createdBy_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type CreateTasks_Mutation_createTasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<CreateTasks_Mutation_createTasks_Task_createdBy_User_organizations_UsersInOrganizations> | null | undefined, subscription?: CreateTasks_Mutation_createTasks_Task_createdBy_User_subscription_UserSubscriptionDetails | null | undefined, approverForTaskSchedules?: Array<CreateTasks_Mutation_createTasks_Task_createdBy_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTaskSchedules?: Array<CreateTasks_Mutation_createTasks_Task_createdBy_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type CreateTasks_Mutation_createTasks_Task_approver_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type CreateTasks_Mutation_createTasks_Task_approver_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type CreateTasks_Mutation_createTasks_Task_approver_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type CreateTasks_Mutation_createTasks_Task_approver_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type CreateTasks_Mutation_createTasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<CreateTasks_Mutation_createTasks_Task_approver_User_organizations_UsersInOrganizations> | null | undefined, subscription?: CreateTasks_Mutation_createTasks_Task_approver_User_subscription_UserSubscriptionDetails | null | undefined, approverForTaskSchedules?: Array<CreateTasks_Mutation_createTasks_Task_approver_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTaskSchedules?: Array<CreateTasks_Mutation_createTasks_Task_approver_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type CreateTasks_Mutation_createTasks_Task_unit_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type CreateTasks_Mutation_createTasks_Task_unit_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type CreateTasks_Mutation_createTasks_Task_unit_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type CreateTasks_Mutation_createTasks_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: CreateTasks_Mutation_createTasks_Task_unit_PropertyUnit_property_Property, tenants?: Array<CreateTasks_Mutation_createTasks_Task_unit_PropertyUnit_tenants_Tenant> | null | undefined, taskSchedules?: Array<CreateTasks_Mutation_createTasks_Task_unit_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type CreateTasks_Mutation_createTasks_Task_createdByTaskSchedule_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type CreateTasks_Mutation_createTasks_Task_createdByTaskSchedule_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type CreateTasks_Mutation_createTasks_Task_createdByTaskSchedule_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type CreateTasks_Mutation_createTasks_Task_createdByTaskSchedule_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type CreateTasks_Mutation_createTasks_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<CreateTasks_Mutation_createTasks_Task_createdByTaskSchedule_TaskSchedule_units_PropertyUnit> | null | undefined, organization: CreateTasks_Mutation_createTasks_Task_createdByTaskSchedule_TaskSchedule_organization_Organization, createdBy: CreateTasks_Mutation_createTasks_Task_createdByTaskSchedule_TaskSchedule_createdBy_User, approver?: CreateTasks_Mutation_createTasks_Task_createdByTaskSchedule_TaskSchedule_approver_User | null | undefined };

export type CreateTasks_Mutation_createTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: CreateTasks_Mutation_createTasks_Task_createdBy_User, approver?: CreateTasks_Mutation_createTasks_Task_approver_User | null | undefined, unit: CreateTasks_Mutation_createTasks_Task_unit_PropertyUnit, createdByTaskSchedule?: CreateTasks_Mutation_createTasks_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type CreateTasks_Mutation_Mutation = { __typename?: 'Mutation', createTasks: Array<CreateTasks_Mutation_createTasks_Task> };


export type CreateTasks_MutationVariables = Exact<{
  data: Array<TaskCreateManyInput> | TaskCreateManyInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateTasks_Mutation = CreateTasks_Mutation_Mutation;

export type UpdateTask_Mutation_updateTask_Task_createdBy_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type UpdateTask_Mutation_updateTask_Task_createdBy_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type UpdateTask_Mutation_updateTask_Task_createdBy_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type UpdateTask_Mutation_updateTask_Task_createdBy_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type UpdateTask_Mutation_updateTask_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<UpdateTask_Mutation_updateTask_Task_createdBy_User_organizations_UsersInOrganizations> | null | undefined, subscription?: UpdateTask_Mutation_updateTask_Task_createdBy_User_subscription_UserSubscriptionDetails | null | undefined, approverForTaskSchedules?: Array<UpdateTask_Mutation_updateTask_Task_createdBy_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTaskSchedules?: Array<UpdateTask_Mutation_updateTask_Task_createdBy_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type UpdateTask_Mutation_updateTask_Task_approver_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type UpdateTask_Mutation_updateTask_Task_approver_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type UpdateTask_Mutation_updateTask_Task_approver_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type UpdateTask_Mutation_updateTask_Task_approver_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type UpdateTask_Mutation_updateTask_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<UpdateTask_Mutation_updateTask_Task_approver_User_organizations_UsersInOrganizations> | null | undefined, subscription?: UpdateTask_Mutation_updateTask_Task_approver_User_subscription_UserSubscriptionDetails | null | undefined, approverForTaskSchedules?: Array<UpdateTask_Mutation_updateTask_Task_approver_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTaskSchedules?: Array<UpdateTask_Mutation_updateTask_Task_approver_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type UpdateTask_Mutation_updateTask_Task_unit_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type UpdateTask_Mutation_updateTask_Task_unit_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type UpdateTask_Mutation_updateTask_Task_unit_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type UpdateTask_Mutation_updateTask_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: UpdateTask_Mutation_updateTask_Task_unit_PropertyUnit_property_Property, tenants?: Array<UpdateTask_Mutation_updateTask_Task_unit_PropertyUnit_tenants_Tenant> | null | undefined, taskSchedules?: Array<UpdateTask_Mutation_updateTask_Task_unit_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type UpdateTask_Mutation_updateTask_Task_createdByTaskSchedule_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type UpdateTask_Mutation_updateTask_Task_createdByTaskSchedule_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type UpdateTask_Mutation_updateTask_Task_createdByTaskSchedule_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type UpdateTask_Mutation_updateTask_Task_createdByTaskSchedule_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type UpdateTask_Mutation_updateTask_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<UpdateTask_Mutation_updateTask_Task_createdByTaskSchedule_TaskSchedule_units_PropertyUnit> | null | undefined, organization: UpdateTask_Mutation_updateTask_Task_createdByTaskSchedule_TaskSchedule_organization_Organization, createdBy: UpdateTask_Mutation_updateTask_Task_createdByTaskSchedule_TaskSchedule_createdBy_User, approver?: UpdateTask_Mutation_updateTask_Task_createdByTaskSchedule_TaskSchedule_approver_User | null | undefined };

export type UpdateTask_Mutation_updateTask_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: UpdateTask_Mutation_updateTask_Task_createdBy_User, approver?: UpdateTask_Mutation_updateTask_Task_approver_User | null | undefined, unit: UpdateTask_Mutation_updateTask_Task_unit_PropertyUnit, createdByTaskSchedule?: UpdateTask_Mutation_updateTask_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type UpdateTask_Mutation_Mutation = { __typename?: 'Mutation', updateTask: UpdateTask_Mutation_updateTask_Task };


export type UpdateTask_MutationVariables = Exact<{
  data: TaskUpdateInput;
  where: TaskWhereUniqueInput;
}>;


export type UpdateTask_Mutation = UpdateTask_Mutation_Mutation;

export type DeleteTask_Mutation_deleteTask_Task_createdBy_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type DeleteTask_Mutation_deleteTask_Task_createdBy_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type DeleteTask_Mutation_deleteTask_Task_createdBy_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type DeleteTask_Mutation_deleteTask_Task_createdBy_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type DeleteTask_Mutation_deleteTask_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<DeleteTask_Mutation_deleteTask_Task_createdBy_User_organizations_UsersInOrganizations> | null | undefined, subscription?: DeleteTask_Mutation_deleteTask_Task_createdBy_User_subscription_UserSubscriptionDetails | null | undefined, approverForTaskSchedules?: Array<DeleteTask_Mutation_deleteTask_Task_createdBy_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTaskSchedules?: Array<DeleteTask_Mutation_deleteTask_Task_createdBy_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type DeleteTask_Mutation_deleteTask_Task_approver_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type DeleteTask_Mutation_deleteTask_Task_approver_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type DeleteTask_Mutation_deleteTask_Task_approver_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type DeleteTask_Mutation_deleteTask_Task_approver_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type DeleteTask_Mutation_deleteTask_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<DeleteTask_Mutation_deleteTask_Task_approver_User_organizations_UsersInOrganizations> | null | undefined, subscription?: DeleteTask_Mutation_deleteTask_Task_approver_User_subscription_UserSubscriptionDetails | null | undefined, approverForTaskSchedules?: Array<DeleteTask_Mutation_deleteTask_Task_approver_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTaskSchedules?: Array<DeleteTask_Mutation_deleteTask_Task_approver_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type DeleteTask_Mutation_deleteTask_Task_unit_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type DeleteTask_Mutation_deleteTask_Task_unit_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type DeleteTask_Mutation_deleteTask_Task_unit_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type DeleteTask_Mutation_deleteTask_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: DeleteTask_Mutation_deleteTask_Task_unit_PropertyUnit_property_Property, tenants?: Array<DeleteTask_Mutation_deleteTask_Task_unit_PropertyUnit_tenants_Tenant> | null | undefined, taskSchedules?: Array<DeleteTask_Mutation_deleteTask_Task_unit_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type DeleteTask_Mutation_deleteTask_Task_createdByTaskSchedule_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type DeleteTask_Mutation_deleteTask_Task_createdByTaskSchedule_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type DeleteTask_Mutation_deleteTask_Task_createdByTaskSchedule_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type DeleteTask_Mutation_deleteTask_Task_createdByTaskSchedule_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type DeleteTask_Mutation_deleteTask_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<DeleteTask_Mutation_deleteTask_Task_createdByTaskSchedule_TaskSchedule_units_PropertyUnit> | null | undefined, organization: DeleteTask_Mutation_deleteTask_Task_createdByTaskSchedule_TaskSchedule_organization_Organization, createdBy: DeleteTask_Mutation_deleteTask_Task_createdByTaskSchedule_TaskSchedule_createdBy_User, approver?: DeleteTask_Mutation_deleteTask_Task_createdByTaskSchedule_TaskSchedule_approver_User | null | undefined };

export type DeleteTask_Mutation_deleteTask_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: DeleteTask_Mutation_deleteTask_Task_createdBy_User, approver?: DeleteTask_Mutation_deleteTask_Task_approver_User | null | undefined, unit: DeleteTask_Mutation_deleteTask_Task_unit_PropertyUnit, createdByTaskSchedule?: DeleteTask_Mutation_deleteTask_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type DeleteTask_Mutation_Mutation = { __typename?: 'Mutation', deleteTask: DeleteTask_Mutation_deleteTask_Task };


export type DeleteTask_MutationVariables = Exact<{
  where: TaskWhereUniqueInput;
}>;


export type DeleteTask_Mutation = DeleteTask_Mutation_Mutation;

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_units_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_units_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_units_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_units_PropertyUnit_property_Property, tenants?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_units_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_units_PropertyUnit_tasks_Task> | null | undefined };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_organization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_organization_Organization_properties_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_organization_Organization_members_UsersInOrganizations> | null | undefined, properties?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_organization_Organization_properties_Property> | null | undefined };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_createdBy_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_createdBy_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_createdBy_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_createdBy_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_createdBy_User_organizations_UsersInOrganizations> | null | undefined, subscription?: CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_createdBy_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_createdBy_User_approverForTasks_Task> | null | undefined, createdTasks?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_createdBy_User_createdTasks_Task> | null | undefined };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_approver_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_approver_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_approver_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_approver_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_approver_User_organizations_UsersInOrganizations> | null | undefined, subscription?: CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_approver_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_approver_User_approverForTasks_Task> | null | undefined, createdTasks?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_approver_User_createdTasks_Task> | null | undefined };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_tasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_tasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_tasks_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_tasks_Task_createdBy_User, approver?: CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_tasks_Task_approver_User | null | undefined, unit: CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_tasks_Task_unit_PropertyUnit };

export type CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_units_PropertyUnit> | null | undefined, organization: CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_organization_Organization, createdBy: CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_createdBy_User, approver?: CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_approver_User | null | undefined, tasks?: Array<CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule_tasks_Task> | null | undefined };

export type CreateTaskSchedule_Mutation_Mutation = { __typename?: 'Mutation', createTaskSchedule: CreateTaskSchedule_Mutation_createTaskSchedule_TaskSchedule };


export type CreateTaskSchedule_MutationVariables = Exact<{
  data: TaskScheduleCreateInput;
}>;


export type CreateTaskSchedule_Mutation = CreateTaskSchedule_Mutation_Mutation;

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_units_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_units_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_units_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_units_PropertyUnit_property_Property, tenants?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_units_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_units_PropertyUnit_tasks_Task> | null | undefined };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_organization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_organization_Organization_properties_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_organization_Organization_members_UsersInOrganizations> | null | undefined, properties?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_organization_Organization_properties_Property> | null | undefined };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_createdBy_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_createdBy_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_createdBy_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_createdBy_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_createdBy_User_organizations_UsersInOrganizations> | null | undefined, subscription?: UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_createdBy_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_createdBy_User_approverForTasks_Task> | null | undefined, createdTasks?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_createdBy_User_createdTasks_Task> | null | undefined };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_approver_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_approver_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_approver_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_approver_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_approver_User_organizations_UsersInOrganizations> | null | undefined, subscription?: UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_approver_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_approver_User_approverForTasks_Task> | null | undefined, createdTasks?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_approver_User_createdTasks_Task> | null | undefined };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_tasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_tasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_tasks_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_tasks_Task_createdBy_User, approver?: UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_tasks_Task_approver_User | null | undefined, unit: UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_tasks_Task_unit_PropertyUnit };

export type UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_units_PropertyUnit> | null | undefined, organization: UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_organization_Organization, createdBy: UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_createdBy_User, approver?: UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_approver_User | null | undefined, tasks?: Array<UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule_tasks_Task> | null | undefined };

export type UpdateTaskSchedule_Mutation_Mutation = { __typename?: 'Mutation', updateTaskSchedule: UpdateTaskSchedule_Mutation_updateTaskSchedule_TaskSchedule };


export type UpdateTaskSchedule_MutationVariables = Exact<{
  data: TaskScheduleUpdateInput;
  where: TaskScheduleWhereUniqueInput;
}>;


export type UpdateTaskSchedule_Mutation = UpdateTaskSchedule_Mutation_Mutation;

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_units_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_units_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_units_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_units_PropertyUnit_property_Property, tenants?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_units_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_units_PropertyUnit_tasks_Task> | null | undefined };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_organization_Organization_members_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_organization_Organization_properties_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string, members?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_organization_Organization_members_UsersInOrganizations> | null | undefined, properties?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_organization_Organization_properties_Property> | null | undefined };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_createdBy_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_createdBy_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_createdBy_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_createdBy_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_createdBy_User_organizations_UsersInOrganizations> | null | undefined, subscription?: DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_createdBy_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_createdBy_User_approverForTasks_Task> | null | undefined, createdTasks?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_createdBy_User_createdTasks_Task> | null | undefined };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_approver_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_approver_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_approver_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_approver_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_approver_User_organizations_UsersInOrganizations> | null | undefined, subscription?: DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_approver_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_approver_User_approverForTasks_Task> | null | undefined, createdTasks?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_approver_User_createdTasks_Task> | null | undefined };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_tasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_tasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_tasks_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_tasks_Task_createdBy_User, approver?: DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_tasks_Task_approver_User | null | undefined, unit: DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_tasks_Task_unit_PropertyUnit };

export type DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_units_PropertyUnit> | null | undefined, organization: DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_organization_Organization, createdBy: DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_createdBy_User, approver?: DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_approver_User | null | undefined, tasks?: Array<DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule_tasks_Task> | null | undefined };

export type DeleteTaskSchedule_Mutation_Mutation = { __typename?: 'Mutation', deleteTaskSchedule: DeleteTaskSchedule_Mutation_deleteTaskSchedule_TaskSchedule };


export type DeleteTaskSchedule_MutationVariables = Exact<{
  where: TaskScheduleWhereUniqueInput;
}>;


export type DeleteTaskSchedule_Mutation = DeleteTaskSchedule_Mutation_Mutation;

export type UpdateProfile_Mutation_updateProfile_User_organizations_UsersInOrganizations_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type UpdateProfile_Mutation_updateProfile_User_organizations_UsersInOrganizations = { __typename?: 'UsersInOrganizations', id: string, createdAt: Date, updatedAt: Date, isAdmin: boolean, userId: string, organizationId: string, organization: UpdateProfile_Mutation_updateProfile_User_organizations_UsersInOrganizations_organization_Organization };

export type UpdateProfile_Mutation_updateProfile_User_subscription_UserSubscriptionDetails = { __typename?: 'UserSubscriptionDetails', id: string, createdAt: Date, updatedAt: Date, userEmail: string, stripeCustomerId: string, stripeSubscriptionId?: string | null | undefined, stripeEntitlements?: Array<string> | null | undefined };

export type UpdateProfile_Mutation_updateProfile_User_approverForTasks_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type UpdateProfile_Mutation_updateProfile_User_approverForTasks_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type UpdateProfile_Mutation_updateProfile_User_approverForTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, unit: UpdateProfile_Mutation_updateProfile_User_approverForTasks_Task_unit_PropertyUnit, createdByTaskSchedule?: UpdateProfile_Mutation_updateProfile_User_approverForTasks_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type UpdateProfile_Mutation_updateProfile_User_approverForTaskSchedules_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type UpdateProfile_Mutation_updateProfile_User_approverForTaskSchedules_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type UpdateProfile_Mutation_updateProfile_User_approverForTaskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type UpdateProfile_Mutation_updateProfile_User_approverForTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<UpdateProfile_Mutation_updateProfile_User_approverForTaskSchedules_TaskSchedule_units_PropertyUnit> | null | undefined, organization: UpdateProfile_Mutation_updateProfile_User_approverForTaskSchedules_TaskSchedule_organization_Organization, tasks?: Array<UpdateProfile_Mutation_updateProfile_User_approverForTaskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type UpdateProfile_Mutation_updateProfile_User_createdTasks_Task_unit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type UpdateProfile_Mutation_updateProfile_User_createdTasks_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type UpdateProfile_Mutation_updateProfile_User_createdTasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, unit: UpdateProfile_Mutation_updateProfile_User_createdTasks_Task_unit_PropertyUnit, createdByTaskSchedule?: UpdateProfile_Mutation_updateProfile_User_createdTasks_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type UpdateProfile_Mutation_updateProfile_User_createdTaskSchedules_TaskSchedule_units_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string };

export type UpdateProfile_Mutation_updateProfile_User_createdTaskSchedules_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type UpdateProfile_Mutation_updateProfile_User_createdTaskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type UpdateProfile_Mutation_updateProfile_User_createdTaskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, units?: Array<UpdateProfile_Mutation_updateProfile_User_createdTaskSchedules_TaskSchedule_units_PropertyUnit> | null | undefined, organization: UpdateProfile_Mutation_updateProfile_User_createdTaskSchedules_TaskSchedule_organization_Organization, tasks?: Array<UpdateProfile_Mutation_updateProfile_User_createdTaskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type UpdateProfile_Mutation_updateProfile_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string, organizations?: Array<UpdateProfile_Mutation_updateProfile_User_organizations_UsersInOrganizations> | null | undefined, subscription?: UpdateProfile_Mutation_updateProfile_User_subscription_UserSubscriptionDetails | null | undefined, approverForTasks?: Array<UpdateProfile_Mutation_updateProfile_User_approverForTasks_Task> | null | undefined, approverForTaskSchedules?: Array<UpdateProfile_Mutation_updateProfile_User_approverForTaskSchedules_TaskSchedule> | null | undefined, createdTasks?: Array<UpdateProfile_Mutation_updateProfile_User_createdTasks_Task> | null | undefined, createdTaskSchedules?: Array<UpdateProfile_Mutation_updateProfile_User_createdTaskSchedules_TaskSchedule> | null | undefined };

export type UpdateProfile_Mutation_Mutation = { __typename?: 'Mutation', updateProfile: UpdateProfile_Mutation_updateProfile_User };


export type UpdateProfile_MutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type UpdateProfile_Mutation = UpdateProfile_Mutation_Mutation;

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_property_Property_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, organization: CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_property_Property_organization_Organization };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tenants_Tenant_messageCodes_MessageCode = { __typename?: 'MessageCode', token: string, createdAt: Date, expiresAt: Date, tenantId: string };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string, messageCodes?: Array<CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tenants_Tenant_messageCodes_MessageCode> | null | undefined };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tasks_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tasks_Task_createdBy_User, approver?: CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tasks_Task_approver_User | null | undefined, createdByTaskSchedule?: CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tasks_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, organization: CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_organization_Organization, createdBy: CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_createdBy_User, approver?: CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_approver_User | null | undefined, tasks?: Array<CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_property_Property, tenants?: Array<CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_tasks_Task> | null | undefined, taskSchedules?: Array<CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type CreatePropertyUnit_Mutation_Mutation = { __typename?: 'Mutation', createPropertyUnit: CreatePropertyUnit_Mutation_createPropertyUnit_PropertyUnit };


export type CreatePropertyUnit_MutationVariables = Exact<{
  data: PropertyUnitCreateInput;
}>;


export type CreatePropertyUnit_Mutation = CreatePropertyUnit_Mutation_Mutation;

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_property_Property_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, organization: AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_property_Property_organization_Organization };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tenants_Tenant_messageCodes_MessageCode = { __typename?: 'MessageCode', token: string, createdAt: Date, expiresAt: Date, tenantId: string };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string, messageCodes?: Array<AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tenants_Tenant_messageCodes_MessageCode> | null | undefined };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tasks_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tasks_Task_createdBy_User, approver?: AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tasks_Task_approver_User | null | undefined, createdByTaskSchedule?: AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tasks_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_taskSchedules_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_taskSchedules_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_taskSchedules_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_taskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, organization: AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_taskSchedules_TaskSchedule_organization_Organization, createdBy: AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_taskSchedules_TaskSchedule_createdBy_User, approver?: AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_taskSchedules_TaskSchedule_approver_User | null | undefined, tasks?: Array<AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_taskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_property_Property, tenants?: Array<AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_tasks_Task> | null | undefined, taskSchedules?: Array<AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type AddPropertyUnitTenant_Mutation_Mutation = { __typename?: 'Mutation', addPropertyUnitTenant: AddPropertyUnitTenant_Mutation_addPropertyUnitTenant_PropertyUnit };


export type AddPropertyUnitTenant_MutationVariables = Exact<{
  data: TenantCreateInput;
}>;


export type AddPropertyUnitTenant_Mutation = AddPropertyUnitTenant_Mutation_Mutation;

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_property_Property_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_property_Property = { __typename?: 'Property', id: string, createdAt: Date, updatedAt: Date, address: string, organizationId: string, organization: UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_property_Property_organization_Organization };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tenants_Tenant_messageCodes_MessageCode = { __typename?: 'MessageCode', token: string, createdAt: Date, expiresAt: Date, tenantId: string };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tenants_Tenant = { __typename?: 'Tenant', id: string, createdAt: Date, updatedAt: Date, name: string, email?: string | null | undefined, phone?: string | null | undefined, propertyUnitId: string, messageCodes?: Array<UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tenants_Tenant_messageCodes_MessageCode> | null | undefined };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tasks_Task_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tasks_Task_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tasks_Task_createdByTaskSchedule_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined, createdBy: UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tasks_Task_createdBy_User, approver?: UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tasks_Task_approver_User | null | undefined, createdByTaskSchedule?: UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tasks_Task_createdByTaskSchedule_TaskSchedule | null | undefined };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_organization_Organization = { __typename?: 'Organization', id: string, createdAt: Date, updatedAt: Date, name: string };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_createdBy_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_approver_User = { __typename?: 'User', id: string, createdAt: Date, updatedAt: Date, email: string, name: string };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_tasks_Task = { __typename?: 'Task', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, status: TaskStatus, assignee: TaskAssignee, dueAt?: Date | null | undefined, completedAt?: Date | null | undefined, createdById: string, approverId?: string | null | undefined, approvedAt?: Date | null | undefined, evidence?: string | null | undefined, unitId: string, createdByTaskScheduleId?: string | null | undefined };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_taskSchedules_TaskSchedule = { __typename?: 'TaskSchedule', id: string, createdAt: Date, updatedAt: Date, title: string, categories?: Array<string> | null | undefined, description: string, details?: any | null | undefined, resources?: any | null | undefined, assignee: TaskAssignee, enabled: boolean, rrule: string, dueIn?: string | null | undefined, organizationId: string, createdById: string, approverId?: string | null | undefined, organization: UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_organization_Organization, createdBy: UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_createdBy_User, approver?: UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_approver_User | null | undefined, tasks?: Array<UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_taskSchedules_TaskSchedule_tasks_Task> | null | undefined };

export type UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit = { __typename?: 'PropertyUnit', id: string, createdAt: Date, updatedAt: Date, name: string, active: boolean, propertyId: string, property: UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_property_Property, tenants?: Array<UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tenants_Tenant> | null | undefined, tasks?: Array<UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_tasks_Task> | null | undefined, taskSchedules?: Array<UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit_taskSchedules_TaskSchedule> | null | undefined };

export type UpdatePropertyUnit_Mutation_Mutation = { __typename?: 'Mutation', updatePropertyUnit: UpdatePropertyUnit_Mutation_updatePropertyUnit_PropertyUnit };


export type UpdatePropertyUnit_MutationVariables = Exact<{
  data: PropertyUnitUpdateInput;
  where: PropertyUnitWhereUniqueInput;
}>;


export type UpdatePropertyUnit_Mutation = UpdatePropertyUnit_Mutation_Mutation;

export const ListOrganizations_QueryDocument = gql`
    query listOrganizations_query {
  listOrganizations {
    id
    createdAt
    updatedAt
    name
    members {
      id
      createdAt
      updatedAt
      isAdmin
      userId
      organizationId
      user {
        id
        createdAt
        updatedAt
        email
        name
      }
    }
    properties {
      id
      createdAt
      updatedAt
      address
      organizationId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
    }
    taskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListOrganizations_QueryGQL extends Apollo.Query<ListOrganizations_Query, ListOrganizations_QueryVariables> {
    override document = ListOrganizations_QueryDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPropertyById_QueryDocument = gql`
    query getPropertyById_query($id: ID!) {
  getPropertyById(id: $id) {
    id
    createdAt
    updatedAt
    address
    organizationId
    units {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    organization {
      id
      createdAt
      updatedAt
      name
      members {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPropertyById_QueryGQL extends Apollo.Query<GetPropertyById_Query, GetPropertyById_QueryVariables> {
    override document = GetPropertyById_QueryDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListProperties_QueryDocument = gql`
    query listProperties_query {
  listProperties {
    id
    createdAt
    updatedAt
    address
    organizationId
    units {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    organization {
      id
      createdAt
      updatedAt
      name
      members {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListProperties_QueryGQL extends Apollo.Query<ListProperties_Query, ListProperties_QueryVariables> {
    override document = ListProperties_QueryDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTaskById_QueryDocument = gql`
    query getTaskById_query($id: ID!) {
  getTaskById(id: $id) {
    id
    createdAt
    updatedAt
    title
    categories
    description
    details
    resources
    status
    assignee
    dueAt
    completedAt
    createdById
    approverId
    approvedAt
    evidence
    unitId
    createdByTaskScheduleId
    createdBy {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
      createdTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    approver {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
      createdTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    unit {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      property {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    createdByTaskSchedule {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      organization {
        id
        createdAt
        updatedAt
        name
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTaskById_QueryGQL extends Apollo.Query<GetTaskById_Query, GetTaskById_QueryVariables> {
    override document = GetTaskById_QueryDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindManyTasks_QueryDocument = gql`
    query findManyTasks_query($where: TaskWhereInput, $orderBy: [TaskOrderByWithRelationInput!], $cursor: TaskWhereUniqueInput, $take: Int, $skip: Int, $distinct: [TaskScalarFieldEnum!]) {
  findManyTasks(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    createdAt
    updatedAt
    title
    categories
    description
    details
    resources
    status
    assignee
    dueAt
    completedAt
    createdById
    approverId
    approvedAt
    evidence
    unitId
    createdByTaskScheduleId
    createdBy {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
      createdTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    approver {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
      createdTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    unit {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      property {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    createdByTaskSchedule {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      organization {
        id
        createdAt
        updatedAt
        name
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindManyTasks_QueryGQL extends Apollo.Query<FindManyTasks_Query, FindManyTasks_QueryVariables> {
    override document = FindManyTasks_QueryDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AggregateTasks_QueryDocument = gql`
    query aggregateTasks_query($where: TaskWhereInput, $orderBy: [TaskOrderByWithRelationInput!], $cursor: TaskWhereUniqueInput, $take: Int, $skip: Int, $_count: TaskCountAggregateInput, $_min: TaskMinAggregateInput, $_max: TaskMaxAggregateInput) {
  aggregateTasks(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    _count: $_count
    _min: $_min
    _max: $_max
  ) {
    _count {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      _all
    }
    _min {
      id
      createdAt
      updatedAt
      title
      description
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
    }
    _max {
      id
      createdAt
      updatedAt
      title
      description
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AggregateTasks_QueryGQL extends Apollo.Query<AggregateTasks_Query, AggregateTasks_QueryVariables> {
    override document = AggregateTasks_QueryDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindManyTaskSchedules_QueryDocument = gql`
    query findManyTaskSchedules_query($where: TaskScheduleWhereInput, $orderBy: [TaskScheduleOrderByWithRelationInput!], $cursor: TaskScheduleWhereUniqueInput, $take: Int, $skip: Int, $distinct: [TaskScheduleScalarFieldEnum!]) {
  findManyTaskSchedules(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    createdAt
    updatedAt
    title
    categories
    description
    details
    resources
    assignee
    enabled
    rrule
    dueIn
    organizationId
    createdById
    approverId
    units {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      property {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    organization {
      id
      createdAt
      updatedAt
      name
      members {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      properties {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
    }
    createdBy {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      createdTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    approver {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      createdTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    tasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      unit {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindManyTaskSchedules_QueryGQL extends Apollo.Query<FindManyTaskSchedules_Query, FindManyTaskSchedules_QueryVariables> {
    override document = FindManyTaskSchedules_QueryDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindUniqueTaskSchedule_QueryDocument = gql`
    query findUniqueTaskSchedule_query($where: TaskScheduleWhereUniqueInput!) {
  findUniqueTaskSchedule(where: $where) {
    id
    createdAt
    updatedAt
    title
    categories
    description
    details
    resources
    assignee
    enabled
    rrule
    dueIn
    organizationId
    createdById
    approverId
    units {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      property {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    organization {
      id
      createdAt
      updatedAt
      name
      members {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      properties {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
    }
    createdBy {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      createdTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    approver {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      createdTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    tasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      unit {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindUniqueTaskSchedule_QueryGQL extends Apollo.Query<FindUniqueTaskSchedule_Query, FindUniqueTaskSchedule_QueryVariables> {
    override document = FindUniqueTaskSchedule_QueryDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProfile_QueryDocument = gql`
    query getProfile_query {
  getProfile {
    id
    createdAt
    updatedAt
    email
    name
    organizations {
      id
      createdAt
      updatedAt
      isAdmin
      userId
      organizationId
      organization {
        id
        createdAt
        updatedAt
        name
      }
    }
    subscription {
      id
      createdAt
      updatedAt
      userEmail
      stripeCustomerId
      stripeSubscriptionId
      stripeEntitlements
    }
    approverForTasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      unit {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      createdByTaskSchedule {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    approverForTaskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      organization {
        id
        createdAt
        updatedAt
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    createdTasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      unit {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      createdByTaskSchedule {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    createdTaskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      organization {
        id
        createdAt
        updatedAt
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProfile_QueryGQL extends Apollo.Query<GetProfile_Query, GetProfile_QueryVariables> {
    override document = GetProfile_QueryDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPropertyUnitById_QueryDocument = gql`
    query getPropertyUnitById_query($id: ID!) {
  getPropertyUnitById(id: $id) {
    id
    createdAt
    updatedAt
    name
    active
    propertyId
    property {
      id
      createdAt
      updatedAt
      address
      organizationId
      organization {
        id
        createdAt
        updatedAt
        name
      }
    }
    tenants {
      id
      createdAt
      updatedAt
      name
      email
      phone
      propertyUnitId
      messageCodes {
        token
        createdAt
        expiresAt
        tenantId
      }
    }
    tasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      createdByTaskSchedule {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    taskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      organization {
        id
        createdAt
        updatedAt
        name
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPropertyUnitById_QueryGQL extends Apollo.Query<GetPropertyUnitById_Query, GetPropertyUnitById_QueryVariables> {
    override document = GetPropertyUnitById_QueryDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListPropertyUnit_QueryDocument = gql`
    query listPropertyUnit_query($where: PropertyUnitWhereInput, $orderBy: [PropertyUnitOrderByWithRelationInput!], $cursor: PropertyUnitWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PropertyUnitScalarFieldEnum!]) {
  listPropertyUnit(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    createdAt
    updatedAt
    name
    active
    propertyId
    property {
      id
      createdAt
      updatedAt
      address
      organizationId
      organization {
        id
        createdAt
        updatedAt
        name
      }
    }
    tenants {
      id
      createdAt
      updatedAt
      name
      email
      phone
      propertyUnitId
      messageCodes {
        token
        createdAt
        expiresAt
        tenantId
      }
    }
    tasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      createdByTaskSchedule {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    taskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      organization {
        id
        createdAt
        updatedAt
        name
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListPropertyUnit_QueryGQL extends Apollo.Query<ListPropertyUnit_Query, ListPropertyUnit_QueryVariables> {
    override document = ListPropertyUnit_QueryDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrganization_MutationDocument = gql`
    mutation createOrganization_mutation($name: String!) {
  createOrganization(name: $name) {
    id
    createdAt
    updatedAt
    name
    members {
      id
      createdAt
      updatedAt
      isAdmin
      userId
      organizationId
      user {
        id
        createdAt
        updatedAt
        email
        name
      }
    }
    properties {
      id
      createdAt
      updatedAt
      address
      organizationId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
    }
    taskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrganization_MutationGQL extends Apollo.Mutation<CreateOrganization_Mutation, CreateOrganization_MutationVariables> {
    override document = CreateOrganization_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrganization_MutationDocument = gql`
    mutation updateOrganization_mutation($id: String!, $data: OrganizationUpdateInput!) {
  updateOrganization(id: $id, data: $data) {
    id
    createdAt
    updatedAt
    name
    members {
      id
      createdAt
      updatedAt
      isAdmin
      userId
      organizationId
      user {
        id
        createdAt
        updatedAt
        email
        name
      }
    }
    properties {
      id
      createdAt
      updatedAt
      address
      organizationId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
    }
    taskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganization_MutationGQL extends Apollo.Mutation<UpdateOrganization_Mutation, UpdateOrganization_MutationVariables> {
    override document = UpdateOrganization_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProperty_MutationDocument = gql`
    mutation createProperty_mutation($data: PropertyCreateInput!) {
  createProperty(data: $data) {
    id
    createdAt
    updatedAt
    address
    organizationId
    units {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    organization {
      id
      createdAt
      updatedAt
      name
      members {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProperty_MutationGQL extends Apollo.Mutation<CreateProperty_Mutation, CreateProperty_MutationVariables> {
    override document = CreateProperty_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProperty_MutationDocument = gql`
    mutation updateProperty_mutation($data: PropertyUpdateInput!, $where: PropertyWhereUniqueInput!) {
  updateProperty(data: $data, where: $where) {
    id
    createdAt
    updatedAt
    address
    organizationId
    units {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    organization {
      id
      createdAt
      updatedAt
      name
      members {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProperty_MutationGQL extends Apollo.Mutation<UpdateProperty_Mutation, UpdateProperty_MutationVariables> {
    override document = UpdateProperty_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProperty_MutationDocument = gql`
    mutation deleteProperty_mutation($id: String!) {
  deleteProperty(id: $id) {
    id
    createdAt
    updatedAt
    address
    organizationId
    units {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    organization {
      id
      createdAt
      updatedAt
      name
      members {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProperty_MutationGQL extends Apollo.Mutation<DeleteProperty_Mutation, DeleteProperty_MutationVariables> {
    override document = DeleteProperty_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTasks_MutationDocument = gql`
    mutation createTasks_mutation($data: [TaskCreateManyInput!]!, $skipDuplicates: Boolean) {
  createTasks(data: $data, skipDuplicates: $skipDuplicates) {
    id
    createdAt
    updatedAt
    title
    categories
    description
    details
    resources
    status
    assignee
    dueAt
    completedAt
    createdById
    approverId
    approvedAt
    evidence
    unitId
    createdByTaskScheduleId
    createdBy {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
      createdTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    approver {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
      createdTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    unit {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      property {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    createdByTaskSchedule {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      organization {
        id
        createdAt
        updatedAt
        name
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTasks_MutationGQL extends Apollo.Mutation<CreateTasks_Mutation, CreateTasks_MutationVariables> {
    override document = CreateTasks_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTask_MutationDocument = gql`
    mutation updateTask_mutation($data: TaskUpdateInput!, $where: TaskWhereUniqueInput!) {
  updateTask(data: $data, where: $where) {
    id
    createdAt
    updatedAt
    title
    categories
    description
    details
    resources
    status
    assignee
    dueAt
    completedAt
    createdById
    approverId
    approvedAt
    evidence
    unitId
    createdByTaskScheduleId
    createdBy {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
      createdTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    approver {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
      createdTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    unit {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      property {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    createdByTaskSchedule {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      organization {
        id
        createdAt
        updatedAt
        name
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTask_MutationGQL extends Apollo.Mutation<UpdateTask_Mutation, UpdateTask_MutationVariables> {
    override document = UpdateTask_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTask_MutationDocument = gql`
    mutation deleteTask_mutation($where: TaskWhereUniqueInput!) {
  deleteTask(where: $where) {
    id
    createdAt
    updatedAt
    title
    categories
    description
    details
    resources
    status
    assignee
    dueAt
    completedAt
    createdById
    approverId
    approvedAt
    evidence
    unitId
    createdByTaskScheduleId
    createdBy {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
      createdTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    approver {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
      createdTaskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    unit {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      property {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      taskSchedules {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    createdByTaskSchedule {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      organization {
        id
        createdAt
        updatedAt
        name
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTask_MutationGQL extends Apollo.Mutation<DeleteTask_Mutation, DeleteTask_MutationVariables> {
    override document = DeleteTask_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTaskSchedule_MutationDocument = gql`
    mutation createTaskSchedule_mutation($data: TaskScheduleCreateInput!) {
  createTaskSchedule(data: $data) {
    id
    createdAt
    updatedAt
    title
    categories
    description
    details
    resources
    assignee
    enabled
    rrule
    dueIn
    organizationId
    createdById
    approverId
    units {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      property {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    organization {
      id
      createdAt
      updatedAt
      name
      members {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      properties {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
    }
    createdBy {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      createdTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    approver {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      createdTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    tasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      unit {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTaskSchedule_MutationGQL extends Apollo.Mutation<CreateTaskSchedule_Mutation, CreateTaskSchedule_MutationVariables> {
    override document = CreateTaskSchedule_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaskSchedule_MutationDocument = gql`
    mutation updateTaskSchedule_mutation($data: TaskScheduleUpdateInput!, $where: TaskScheduleWhereUniqueInput!) {
  updateTaskSchedule(data: $data, where: $where) {
    id
    createdAt
    updatedAt
    title
    categories
    description
    details
    resources
    assignee
    enabled
    rrule
    dueIn
    organizationId
    createdById
    approverId
    units {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      property {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    organization {
      id
      createdAt
      updatedAt
      name
      members {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      properties {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
    }
    createdBy {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      createdTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    approver {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      createdTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    tasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      unit {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaskSchedule_MutationGQL extends Apollo.Mutation<UpdateTaskSchedule_Mutation, UpdateTaskSchedule_MutationVariables> {
    override document = UpdateTaskSchedule_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTaskSchedule_MutationDocument = gql`
    mutation deleteTaskSchedule_mutation($where: TaskScheduleWhereUniqueInput!) {
  deleteTaskSchedule(where: $where) {
    id
    createdAt
    updatedAt
    title
    categories
    description
    details
    resources
    assignee
    enabled
    rrule
    dueIn
    organizationId
    createdById
    approverId
    units {
      id
      createdAt
      updatedAt
      name
      active
      propertyId
      property {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
      tenants {
        id
        createdAt
        updatedAt
        name
        email
        phone
        propertyUnitId
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    organization {
      id
      createdAt
      updatedAt
      name
      members {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      properties {
        id
        createdAt
        updatedAt
        address
        organizationId
      }
    }
    createdBy {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      createdTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    approver {
      id
      createdAt
      updatedAt
      email
      name
      organizations {
        id
        createdAt
        updatedAt
        isAdmin
        userId
        organizationId
      }
      subscription {
        id
        createdAt
        updatedAt
        userEmail
        stripeCustomerId
        stripeSubscriptionId
        stripeEntitlements
      }
      approverForTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
      createdTasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    tasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      unit {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTaskSchedule_MutationGQL extends Apollo.Mutation<DeleteTaskSchedule_Mutation, DeleteTaskSchedule_MutationVariables> {
    override document = DeleteTaskSchedule_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProfile_MutationDocument = gql`
    mutation updateProfile_mutation($name: String!) {
  updateProfile(name: $name) {
    id
    createdAt
    updatedAt
    email
    name
    organizations {
      id
      createdAt
      updatedAt
      isAdmin
      userId
      organizationId
      organization {
        id
        createdAt
        updatedAt
        name
      }
    }
    subscription {
      id
      createdAt
      updatedAt
      userEmail
      stripeCustomerId
      stripeSubscriptionId
      stripeEntitlements
    }
    approverForTasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      unit {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      createdByTaskSchedule {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    approverForTaskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      organization {
        id
        createdAt
        updatedAt
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
    createdTasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      unit {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      createdByTaskSchedule {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    createdTaskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      units {
        id
        createdAt
        updatedAt
        name
        active
        propertyId
      }
      organization {
        id
        createdAt
        updatedAt
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProfile_MutationGQL extends Apollo.Mutation<UpdateProfile_Mutation, UpdateProfile_MutationVariables> {
    override document = UpdateProfile_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePropertyUnit_MutationDocument = gql`
    mutation createPropertyUnit_mutation($data: PropertyUnitCreateInput!) {
  createPropertyUnit(data: $data) {
    id
    createdAt
    updatedAt
    name
    active
    propertyId
    property {
      id
      createdAt
      updatedAt
      address
      organizationId
      organization {
        id
        createdAt
        updatedAt
        name
      }
    }
    tenants {
      id
      createdAt
      updatedAt
      name
      email
      phone
      propertyUnitId
      messageCodes {
        token
        createdAt
        expiresAt
        tenantId
      }
    }
    tasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      createdByTaskSchedule {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    taskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      organization {
        id
        createdAt
        updatedAt
        name
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePropertyUnit_MutationGQL extends Apollo.Mutation<CreatePropertyUnit_Mutation, CreatePropertyUnit_MutationVariables> {
    override document = CreatePropertyUnit_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddPropertyUnitTenant_MutationDocument = gql`
    mutation addPropertyUnitTenant_mutation($data: TenantCreateInput!) {
  addPropertyUnitTenant(data: $data) {
    id
    createdAt
    updatedAt
    name
    active
    propertyId
    property {
      id
      createdAt
      updatedAt
      address
      organizationId
      organization {
        id
        createdAt
        updatedAt
        name
      }
    }
    tenants {
      id
      createdAt
      updatedAt
      name
      email
      phone
      propertyUnitId
      messageCodes {
        token
        createdAt
        expiresAt
        tenantId
      }
    }
    tasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      createdByTaskSchedule {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    taskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      organization {
        id
        createdAt
        updatedAt
        name
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddPropertyUnitTenant_MutationGQL extends Apollo.Mutation<AddPropertyUnitTenant_Mutation, AddPropertyUnitTenant_MutationVariables> {
    override document = AddPropertyUnitTenant_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyUnit_MutationDocument = gql`
    mutation updatePropertyUnit_mutation($data: PropertyUnitUpdateInput!, $where: PropertyUnitWhereUniqueInput!) {
  updatePropertyUnit(data: $data, where: $where) {
    id
    createdAt
    updatedAt
    name
    active
    propertyId
    property {
      id
      createdAt
      updatedAt
      address
      organizationId
      organization {
        id
        createdAt
        updatedAt
        name
      }
    }
    tenants {
      id
      createdAt
      updatedAt
      name
      email
      phone
      propertyUnitId
      messageCodes {
        token
        createdAt
        expiresAt
        tenantId
      }
    }
    tasks {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      status
      assignee
      dueAt
      completedAt
      createdById
      approverId
      approvedAt
      evidence
      unitId
      createdByTaskScheduleId
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      createdByTaskSchedule {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        assignee
        enabled
        rrule
        dueIn
        organizationId
        createdById
        approverId
      }
    }
    taskSchedules {
      id
      createdAt
      updatedAt
      title
      categories
      description
      details
      resources
      assignee
      enabled
      rrule
      dueIn
      organizationId
      createdById
      approverId
      organization {
        id
        createdAt
        updatedAt
        name
      }
      createdBy {
        id
        createdAt
        updatedAt
        email
        name
      }
      approver {
        id
        createdAt
        updatedAt
        email
        name
      }
      tasks {
        id
        createdAt
        updatedAt
        title
        categories
        description
        details
        resources
        status
        assignee
        dueAt
        completedAt
        createdById
        approverId
        approvedAt
        evidence
        unitId
        createdByTaskScheduleId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyUnit_MutationGQL extends Apollo.Mutation<UpdatePropertyUnit_Mutation, UpdatePropertyUnit_MutationVariables> {
    override document = UpdatePropertyUnit_MutationDocument;
    override client = 'RcApiApolloClient';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

  interface WatchQueryOptionsAlone<V extends ApolloCore.OperationVariables> extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

  interface QueryOptionsAlone<V> extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

  interface MutationOptionsAlone<T, V> extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> {}

  @Injectable({ providedIn: 'root' })
  export class RcApiSdk {
    constructor(
      private listOrganizationsQueryGql: ListOrganizations_QueryGQL,
      private getPropertyByIdQueryGql: GetPropertyById_QueryGQL,
      private listPropertiesQueryGql: ListProperties_QueryGQL,
      private getTaskByIdQueryGql: GetTaskById_QueryGQL,
      private findManyTasksQueryGql: FindManyTasks_QueryGQL,
      private aggregateTasksQueryGql: AggregateTasks_QueryGQL,
      private findManyTaskSchedulesQueryGql: FindManyTaskSchedules_QueryGQL,
      private findUniqueTaskScheduleQueryGql: FindUniqueTaskSchedule_QueryGQL,
      private getProfileQueryGql: GetProfile_QueryGQL,
      private getPropertyUnitByIdQueryGql: GetPropertyUnitById_QueryGQL,
      private listPropertyUnitQueryGql: ListPropertyUnit_QueryGQL,
      private createOrganizationMutationGql: CreateOrganization_MutationGQL,
      private updateOrganizationMutationGql: UpdateOrganization_MutationGQL,
      private createPropertyMutationGql: CreateProperty_MutationGQL,
      private updatePropertyMutationGql: UpdateProperty_MutationGQL,
      private deletePropertyMutationGql: DeleteProperty_MutationGQL,
      private createTasksMutationGql: CreateTasks_MutationGQL,
      private updateTaskMutationGql: UpdateTask_MutationGQL,
      private deleteTaskMutationGql: DeleteTask_MutationGQL,
      private createTaskScheduleMutationGql: CreateTaskSchedule_MutationGQL,
      private updateTaskScheduleMutationGql: UpdateTaskSchedule_MutationGQL,
      private deleteTaskScheduleMutationGql: DeleteTaskSchedule_MutationGQL,
      private updateProfileMutationGql: UpdateProfile_MutationGQL,
      private createPropertyUnitMutationGql: CreatePropertyUnit_MutationGQL,
      private addPropertyUnitTenantMutationGql: AddPropertyUnitTenant_MutationGQL,
      private updatePropertyUnitMutationGql: UpdatePropertyUnit_MutationGQL
    ) {}
      
    listOrganizationsQuery(variables?: ListOrganizations_QueryVariables, options?: QueryOptionsAlone<ListOrganizations_QueryVariables>) {
      return this.listOrganizationsQueryGql.fetch(variables, options)
    }
    
    listOrganizationsQueryWatch(variables?: ListOrganizations_QueryVariables, options?: WatchQueryOptionsAlone<ListOrganizations_QueryVariables>) {
      return this.listOrganizationsQueryGql.watch(variables, options)
    }
    
    getPropertyByIdQuery(variables: GetPropertyById_QueryVariables, options?: QueryOptionsAlone<GetPropertyById_QueryVariables>) {
      return this.getPropertyByIdQueryGql.fetch(variables, options)
    }
    
    getPropertyByIdQueryWatch(variables: GetPropertyById_QueryVariables, options?: WatchQueryOptionsAlone<GetPropertyById_QueryVariables>) {
      return this.getPropertyByIdQueryGql.watch(variables, options)
    }
    
    listPropertiesQuery(variables?: ListProperties_QueryVariables, options?: QueryOptionsAlone<ListProperties_QueryVariables>) {
      return this.listPropertiesQueryGql.fetch(variables, options)
    }
    
    listPropertiesQueryWatch(variables?: ListProperties_QueryVariables, options?: WatchQueryOptionsAlone<ListProperties_QueryVariables>) {
      return this.listPropertiesQueryGql.watch(variables, options)
    }
    
    getTaskByIdQuery(variables: GetTaskById_QueryVariables, options?: QueryOptionsAlone<GetTaskById_QueryVariables>) {
      return this.getTaskByIdQueryGql.fetch(variables, options)
    }
    
    getTaskByIdQueryWatch(variables: GetTaskById_QueryVariables, options?: WatchQueryOptionsAlone<GetTaskById_QueryVariables>) {
      return this.getTaskByIdQueryGql.watch(variables, options)
    }
    
    findManyTasksQuery(variables?: FindManyTasks_QueryVariables, options?: QueryOptionsAlone<FindManyTasks_QueryVariables>) {
      return this.findManyTasksQueryGql.fetch(variables, options)
    }
    
    findManyTasksQueryWatch(variables?: FindManyTasks_QueryVariables, options?: WatchQueryOptionsAlone<FindManyTasks_QueryVariables>) {
      return this.findManyTasksQueryGql.watch(variables, options)
    }
    
    aggregateTasksQuery(variables?: AggregateTasks_QueryVariables, options?: QueryOptionsAlone<AggregateTasks_QueryVariables>) {
      return this.aggregateTasksQueryGql.fetch(variables, options)
    }
    
    aggregateTasksQueryWatch(variables?: AggregateTasks_QueryVariables, options?: WatchQueryOptionsAlone<AggregateTasks_QueryVariables>) {
      return this.aggregateTasksQueryGql.watch(variables, options)
    }
    
    findManyTaskSchedulesQuery(variables?: FindManyTaskSchedules_QueryVariables, options?: QueryOptionsAlone<FindManyTaskSchedules_QueryVariables>) {
      return this.findManyTaskSchedulesQueryGql.fetch(variables, options)
    }
    
    findManyTaskSchedulesQueryWatch(variables?: FindManyTaskSchedules_QueryVariables, options?: WatchQueryOptionsAlone<FindManyTaskSchedules_QueryVariables>) {
      return this.findManyTaskSchedulesQueryGql.watch(variables, options)
    }
    
    findUniqueTaskScheduleQuery(variables: FindUniqueTaskSchedule_QueryVariables, options?: QueryOptionsAlone<FindUniqueTaskSchedule_QueryVariables>) {
      return this.findUniqueTaskScheduleQueryGql.fetch(variables, options)
    }
    
    findUniqueTaskScheduleQueryWatch(variables: FindUniqueTaskSchedule_QueryVariables, options?: WatchQueryOptionsAlone<FindUniqueTaskSchedule_QueryVariables>) {
      return this.findUniqueTaskScheduleQueryGql.watch(variables, options)
    }
    
    getProfileQuery(variables?: GetProfile_QueryVariables, options?: QueryOptionsAlone<GetProfile_QueryVariables>) {
      return this.getProfileQueryGql.fetch(variables, options)
    }
    
    getProfileQueryWatch(variables?: GetProfile_QueryVariables, options?: WatchQueryOptionsAlone<GetProfile_QueryVariables>) {
      return this.getProfileQueryGql.watch(variables, options)
    }
    
    getPropertyUnitByIdQuery(variables: GetPropertyUnitById_QueryVariables, options?: QueryOptionsAlone<GetPropertyUnitById_QueryVariables>) {
      return this.getPropertyUnitByIdQueryGql.fetch(variables, options)
    }
    
    getPropertyUnitByIdQueryWatch(variables: GetPropertyUnitById_QueryVariables, options?: WatchQueryOptionsAlone<GetPropertyUnitById_QueryVariables>) {
      return this.getPropertyUnitByIdQueryGql.watch(variables, options)
    }
    
    listPropertyUnitQuery(variables?: ListPropertyUnit_QueryVariables, options?: QueryOptionsAlone<ListPropertyUnit_QueryVariables>) {
      return this.listPropertyUnitQueryGql.fetch(variables, options)
    }
    
    listPropertyUnitQueryWatch(variables?: ListPropertyUnit_QueryVariables, options?: WatchQueryOptionsAlone<ListPropertyUnit_QueryVariables>) {
      return this.listPropertyUnitQueryGql.watch(variables, options)
    }
    
    createOrganizationMutation(variables: CreateOrganization_MutationVariables, options?: MutationOptionsAlone<CreateOrganization_Mutation, CreateOrganization_MutationVariables>) {
      return this.createOrganizationMutationGql.mutate(variables, options)
    }
    
    updateOrganizationMutation(variables: UpdateOrganization_MutationVariables, options?: MutationOptionsAlone<UpdateOrganization_Mutation, UpdateOrganization_MutationVariables>) {
      return this.updateOrganizationMutationGql.mutate(variables, options)
    }
    
    createPropertyMutation(variables: CreateProperty_MutationVariables, options?: MutationOptionsAlone<CreateProperty_Mutation, CreateProperty_MutationVariables>) {
      return this.createPropertyMutationGql.mutate(variables, options)
    }
    
    updatePropertyMutation(variables: UpdateProperty_MutationVariables, options?: MutationOptionsAlone<UpdateProperty_Mutation, UpdateProperty_MutationVariables>) {
      return this.updatePropertyMutationGql.mutate(variables, options)
    }
    
    deletePropertyMutation(variables: DeleteProperty_MutationVariables, options?: MutationOptionsAlone<DeleteProperty_Mutation, DeleteProperty_MutationVariables>) {
      return this.deletePropertyMutationGql.mutate(variables, options)
    }
    
    createTasksMutation(variables: CreateTasks_MutationVariables, options?: MutationOptionsAlone<CreateTasks_Mutation, CreateTasks_MutationVariables>) {
      return this.createTasksMutationGql.mutate(variables, options)
    }
    
    updateTaskMutation(variables: UpdateTask_MutationVariables, options?: MutationOptionsAlone<UpdateTask_Mutation, UpdateTask_MutationVariables>) {
      return this.updateTaskMutationGql.mutate(variables, options)
    }
    
    deleteTaskMutation(variables: DeleteTask_MutationVariables, options?: MutationOptionsAlone<DeleteTask_Mutation, DeleteTask_MutationVariables>) {
      return this.deleteTaskMutationGql.mutate(variables, options)
    }
    
    createTaskScheduleMutation(variables: CreateTaskSchedule_MutationVariables, options?: MutationOptionsAlone<CreateTaskSchedule_Mutation, CreateTaskSchedule_MutationVariables>) {
      return this.createTaskScheduleMutationGql.mutate(variables, options)
    }
    
    updateTaskScheduleMutation(variables: UpdateTaskSchedule_MutationVariables, options?: MutationOptionsAlone<UpdateTaskSchedule_Mutation, UpdateTaskSchedule_MutationVariables>) {
      return this.updateTaskScheduleMutationGql.mutate(variables, options)
    }
    
    deleteTaskScheduleMutation(variables: DeleteTaskSchedule_MutationVariables, options?: MutationOptionsAlone<DeleteTaskSchedule_Mutation, DeleteTaskSchedule_MutationVariables>) {
      return this.deleteTaskScheduleMutationGql.mutate(variables, options)
    }
    
    updateProfileMutation(variables: UpdateProfile_MutationVariables, options?: MutationOptionsAlone<UpdateProfile_Mutation, UpdateProfile_MutationVariables>) {
      return this.updateProfileMutationGql.mutate(variables, options)
    }
    
    createPropertyUnitMutation(variables: CreatePropertyUnit_MutationVariables, options?: MutationOptionsAlone<CreatePropertyUnit_Mutation, CreatePropertyUnit_MutationVariables>) {
      return this.createPropertyUnitMutationGql.mutate(variables, options)
    }
    
    addPropertyUnitTenantMutation(variables: AddPropertyUnitTenant_MutationVariables, options?: MutationOptionsAlone<AddPropertyUnitTenant_Mutation, AddPropertyUnitTenant_MutationVariables>) {
      return this.addPropertyUnitTenantMutationGql.mutate(variables, options)
    }
    
    updatePropertyUnitMutation(variables: UpdatePropertyUnit_MutationVariables, options?: MutationOptionsAlone<UpdatePropertyUnit_Mutation, UpdatePropertyUnit_MutationVariables>) {
      return this.updatePropertyUnitMutationGql.mutate(variables, options)
    }
  }